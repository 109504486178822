import { PersonalInfoIcon } from '../ui/assets';
import capitalize from 'lodash/capitalize';

import {
  CoursesIconExperiment,
  CustomIconExperiment,
  EducationIcon,
  EmploymentIcon,
  ExtraCurricularIconExperiment,
  HobbyIconExperiment,
  InternshipsIconExperiment,
  LanguagesIconExperiment,
  ReferencesIconExperiment,
  SkillsIcon,
  SocialLinksIcon,
  VehicleIconExperiment,
  PublicationIconExperiment,
  PersonalInfoIconExperiment,
  SummaryIcon,
} from '/imports/generator/ui/assets';
import DatepickerAutosave from '/imports/core/ui/atoms/DatepickerAutosave';
import DateRangeAutosave from '/imports/core/ui/atoms/DateRangeAutosave';
import { displayDate, displayDateRange, displayTitle, getSkillsLevelSlug } from '/imports/generator/api/helpers';
import DropdownAutosave from '/imports/core/ui/atoms/DropdownAutosave';
import DropdownCustomAutosave from '/imports/core/ui/atoms/DropdownCustomAutosave';
import { ERROR_MESSAGES } from '/imports/core/api/constants';
import GeneratorInput from '/imports/generator/ui/atoms/GeneratorInputAutosave';
import InputAutosave from '/imports/core/ui/atoms/InputAutosave';
import InputGoogleAutocompleteAutosave from '/imports/core/ui/atoms/InputGoogleAutocompleteAutosave';
import SimpleYearPickerAutosave from '/imports/core/ui/atoms/SimpleYearPickerAutosave';
import SkillLevelAutosave from '/imports/core/ui/atoms/SkillLevelAutosave';
import SwitchAutosave from '/imports/core/ui/atoms/SwitchAutosave';
import TextareaAutosave from '/imports/core/ui/atoms/TextareaAutosave';
import TextareaEditorAutosave from '/imports/core/ui/atoms/TextareaEditorAutosave';
import TextareaEditorWithData from '/imports/generator/ui/atoms/TextareaEditorWithDataState';
import JobTitleAutocompleteExperiment from '/imports/core/ui/atoms/JobTitleAutocompleteExperiment';
import SkillAutocompleteExperiment from 'imports/core/ui/atoms/SkillAutocompleteExperiment';
import UploadPhoto from '/imports/generator/ui/components/UploadPhoto';
import { WIZARD_HELP_PHRASES, LANGUAGE_LEVEL_OPTIONS, degreeOptions } from '/imports/generator/api/constants';
import { validateEmail, emailValidatorPattern } from '/imports/core/api/validators';
import { getExpSkillsTagVars, expTagsTemplates } from 'lib/helpers';
import { searchJobTitle, searchSkills } from '/imports/generator/api/api';
import PlacekitInputAutoSave from 'imports/core/ui/atoms/PlacekitInputAutoSave';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

const signatureFonts = ['Adeline', 'Fengardom', 'Salt Lake', 'Stone Soul', 'Stronger'].map((v) => ({
  title: v,
  value: v,
}));

const expGoogle = marvelEmitter.getActiveVariant('exp_placekit_autocomplete') === 'control';

export const EXPOSED_DETAILS_MAP = [
  {
    label: 'Job title',
    translationSlug: 'generator.form.jobtitle',
    name: 'title',
    component: JobTitleAutocompleteExperiment,
    props: {
      placeholder: 'e.g. Marketing Manager',
      placeholderSlug: 'generator_job_title_placeholder',
      data: [],
      type: 'text',
      errorMessages: [ERROR_MESSAGES.requiredJob],
      instantValidate: false,
      //props used for getting options from API
      options: [],
      isOptionsDynamic: true,
      getOptions: searchJobTitle,
    },
    xsFullWidth: true,
    increasedZindex: true,
  },
  {
    name: 'userPic',
    component: UploadPhoto,
    props: {
      isGenerator: true,
    },
    xsFullWidth: true,
  },
  {
    label: 'First name',
    translationSlug: 'generator.heading.first_name',
    name: 'firstName',
    component: GeneratorInput,
    props: {
      placeholder: 'Name',
      placeholderSlug: 'peter',
    },
  },
  {
    label: 'Last name',
    translationSlug: 'generator.heading.last_name',
    name: 'lastName',
    component: GeneratorInput,
    props: {
      placeholder: 'LastName',
      placeholderSlug: 'placeholder_lastname',
    },
  },
  {
    label: 'Email',
    translationSlug: 'generator.heading.email',
    name: 'email',
    component: GeneratorInput,
    props: {
      type: 'email',
      placeholder: 'e.g. mail@example.com',
      placeholderSlug: 'email_placeholder',
      validators: {
        required: ERROR_MESSAGES.requiredEmail,
        pattern: { value: emailValidatorPattern, message: ERROR_MESSAGES.email },
      },
      errorMessages: [ERROR_MESSAGES.requiredEmail, ERROR_MESSAGES.email],
      instantValidate: false,
    },
    xsFullWidth: true,
  },
  {
    label: 'Phone',
    name: 'phone',
    translationSlug: 'generator.heading.phone',
    component: GeneratorInput,
    xsFullWidth: true,
    props: {
      placeholder: '305-123-44444',
    },
  },
  {
    label: 'Address',
    name: 'address',
    translationSlug: 'generator.heading.address',
    component: expGoogle ? InputGoogleAutocompleteAutosave : PlacekitInputAutoSave,
    fullWidth: true,
  },
  {
    label: 'City',
    name: 'city',
    translationSlug: 'generator.heading.city',
    component: GeneratorInput,
    props: {
      placeholder: 'San Francisco',
      placeholderSlug: 'san_francisco',
      isCity: true,
    },
  },
  {
    label: 'Postal Code',
    name: 'postalCode',
    translationSlug: 'generator.heading.postal_code',
    component: GeneratorInput,
    props: {
      placeholder: '94120',
    },
  },
  {
    label: 'Country',
    name: 'country',
    translationSlug: 'generator.form.country',
    component: GeneratorInput,
    xsFullWidth: true,
    props: {
      placeholder: 'United Kingdom',
      placeholderSlug: 'united_kingdom',
    },
  },
];

export const START_FORM_MAP = [
  {
    label: 'First name',
    translationSlug: 'generator.heading.first_name',
    name: 'firstName',
    component: GeneratorInput,
    props: {
      placeholder: 'Name',
      placeholderSlug: 'peter',
      name: 'firstName',
    },
  },
  {
    label: 'Last name',
    translationSlug: 'generator.heading.last_name',
    name: 'lastName',
    component: GeneratorInput,
    props: {
      placeholder: 'Johnson',
      placeholderSlug: 'placeholder_lastname',
    },
  },
  {
    label: 'Address',
    name: 'address',
    translationSlug: 'generator.heading.address',
    component: expGoogle ? InputGoogleAutocompleteAutosave : PlacekitInputAutoSave,
    fullWidth: true,
  },
  {
    label: 'City',
    name: 'city',
    translationSlug: 'generator.heading.city',
    component: GeneratorInput,
    props: {
      placeholder: 'San Francisco',
      placeholderSlug: 'san_francisco',
      isCity: true,
    },
  },
  {
    label: 'Postal Code',
    name: 'postalCode',
    translationSlug: 'generator.heading.postal_code',
    component: GeneratorInput,
    props: {
      placeholder: '94120',
    },
  },
  {
    label: 'Phone',
    name: 'phone',
    translationSlug: 'generator.heading.phone',
    component: GeneratorInput,
    props: {
      placeholder: '305-123-44444',
    },
    xsFullWidth: true,
  },
  {
    label: 'Email',
    name: 'email',
    translationSlug: 'generator.heading.email',
    component: GeneratorInput,
    props: {
      suggestion: true,
      type: 'email',
      placeholder: 'e.g. mail@example.com',
      placeholderSlug: 'email_placeholder',
      validators: {
        required: ERROR_MESSAGES.requiredEmail,
        pattern: { value: emailValidatorPattern, message: ERROR_MESSAGES.email },
      },
      errorMessages: [
        ERROR_MESSAGES.requiredEmail,
        ERROR_MESSAGES.email,
        ERROR_MESSAGES.isEmailDuplicate,
        ERROR_MESSAGES.emailNotValid,
      ],
      instantValidate: false,
    },
    xsFullWidth: true,
  },
];

export const BLOCKS_MAP = {
  CUSTOM: {
    getItemTitle(item, t, language) {
      const { title, city, startDate, endDate, current, hideDates, hideStartDate } = item.fields || {};
      let res = displayTitle(title, city, ', ', t);
      if (!hideDates) {
        res += `, ${displayDateRange(startDate, endDate, current, hideStartDate, t, language)}`;
      }
      return res;
    },
    icon: CustomIconExperiment,
    iconV2: '📌',
    items: [
      {
        label: 'Activity name, job title, book title etc.',
        name: 'title',
        translationSlug: 'generator.custom_title',
        component: GeneratorInput,
        fullWidthMobile: true,
      },
      {
        label: 'City',
        name: 'city',
        translationSlug: 'generator.form.city',
        component: GeneratorInput,
      },
      {
        name: ['startDate', 'endDate', 'current', 'hideDates', 'hideStartDate'],
        component: DateRangeAutosave,
        isRange: true,
        props: {
          showCheckbox: true,
          hideDatesCheckbox: true,
          hideStartDateCheckbox: true,
        },
        needUpdate: true,
      },
      {
        label: 'Description',
        name: 'description',
        translationSlug: 'generator.form.description',
        component: TextareaEditorAutosave,
        props: {
          rows: 5,
          help: WIZARD_HELP_PHRASES.custom,
          simpleSearch: true,
          hideSearch: true,
        },
        fullWidth: true,
      },
    ],
  },
  LANGUAGES: {
    getItemTitle(item, t) {
      const { language, languageLevel } = item.fields || {};
      let res = `${language || t('generator.form.not_specified')}`;
      if (languageLevel) {
        res += `, ${t(
          LANGUAGE_LEVEL_OPTIONS.find((l) => {
            // alt values stand for dismissed
            // language levels - #backwardsCompatibility
            const vals = [l.value, l.alt];
            return vals.includes(languageLevel);
          }).titleSlug,
        )}`;
      }
      return res;
    },
    icon: LanguagesIconExperiment,
    iconV2: '🌎',
    items: [
      {
        label: 'Language',
        name: 'language',
        translationSlug: 'languages',
        component: GeneratorInput,
      },
      {
        label: 'Level',
        translationSlug: 'generator.skills.level',
        name: 'languageLevel',
        component: DropdownAutosave,
        props: {
          options: LANGUAGE_LEVEL_OPTIONS,
        },
      },
    ],
    additionalFlags: [
      {
        name: 'hideLevel',
        label: 'Hide language level',
        translationSlugAdditionalFlags: 'generator.form.hide_language_level',
      },
    ],
  },
  COURSES: {
    getItemTitle(item, t, language) {
      const { course, institution, startDate, endDate, current, hideStartDate } = item.fields || {};
      let res = displayTitle(course, institution, ` ${t('at')} `, t, language);
      res += `, ${displayDateRange(startDate, endDate, current, hideStartDate, t, language)}`;
      return res;
    },
    icon: CoursesIconExperiment,
    iconV2: '🎓',
    items: [
      {
        label: 'Course',
        name: 'course',
        translationSlug: 'course',
        component: GeneratorInput,
      },
      {
        label: 'Institution',
        name: 'institution',
        translationSlug: 'generator.form.Institution',
        component: GeneratorInput,
      },
      {
        name: ['startDate', 'endDate', 'current', 'hideDates', 'hideStartDate'],
        component: DateRangeAutosave,
        isRange: true,
        props: {
          showCheckbox: true,
          hideStartDateCheckbox: true,
        },
        needUpdate: true,
      },
    ],
  },
  REFERENCES: {
    getItemTitle(item, t) {
      const { fullName, company } = item.fields || {};
      return displayTitle(fullName, company, ` ${t('from')} `, t);
    },
    icon: ReferencesIconExperiment,
    iconV2: '👍',
    items: [
      {
        label: "Referent's Full Name",
        translationSlug: 'generator.references_fullname_label',
        name: 'fullName',
        component: GeneratorInput,
      },
      {
        label: 'Company',
        name: 'company',
        translationSlug: 'generator.company',
        component: GeneratorInput,
      },
      {
        label: 'Phone',
        name: 'phone',
        translationSlug: 'generator.heading.phone',
        component: GeneratorInput,
      },
      {
        label: 'Email',
        name: 'email',
        translationSlug: 'email',
        component: GeneratorInput,
      },
    ],
    additionalFlags: [
      {
        name: 'hideReferences',
        label: 'Make references available only upon request',
        translationSlugAdditionalFlags: 'generator.hide_references',
      },
    ],
  },
  INTERNSHIPS: {
    getItemTitle(item, t, language) {
      const { title, employer, startDate, endDate, current } = item.fields || {};
      let res = displayTitle(title, employer, ` ${t('at')} `, t);
      res += `, ${displayDateRange(startDate, endDate, current, false, t, language)}`;
      return res;
    },
    icon: InternshipsIconExperiment,
    iconV2: '🏫',
    items: [
      {
        label: 'Job title',
        translationSlug: 'generator.form.jobtitle',
        name: 'title',
        component: GeneratorInput,
      },
      {
        label: 'Employer',
        translationSlug: 'generator.form.employer',
        name: 'employer',
        validators: {
          required: ERROR_MESSAGES.employerRequired,
        },
        component: GeneratorInput,
      },
      {
        name: ['startDate', 'endDate', 'current'],
        component: DateRangeAutosave,
        isRange: true,
        props: {
          showCheckbox: true,
        },
        needUpdate: true,
      },
      {
        label: 'City',
        translationSlug: 'generator.form.city',
        name: 'city',
        component: GeneratorInput,
      },
      {
        label: 'Description',
        translationSlug: 'generator.form.description',
        name: 'description',
        component: TextareaEditorWithData,
        props: {
          rows: 5,
          searchType: 'experience',
        },
        fullWidth: true,
      },
    ],
  },
  EXTRA_CURRICULAR: {
    getItemTitle(item, t, language) {
      const { title, employer, startDate, endDate, current, hideStartDate } = item.fields || {};
      let res = displayTitle(title, employer, ` ${t('at')} `, t);
      res += `, ${displayDateRange(startDate, endDate, current, hideStartDate, t, language)}`;
      return res;
    },
    icon: ExtraCurricularIconExperiment,
    iconV2: '🏆',
    items: [
      {
        label: 'Function Title',
        translationSlug: 'generator.function_title',
        name: 'title',
        component: GeneratorInput,
      },
      {
        label: 'Employer',
        translationSlug: 'generator.form.employer',
        name: 'employer',
        component: GeneratorInput,
      },
      {
        name: ['startDate', 'endDate', 'current', 'hideDates', 'hideStartDate'],
        component: DateRangeAutosave,
        isRange: true,
        props: {
          showCheckbox: true,
          hideStartDateCheckbox: true,
        },
        needUpdate: true,
      },
      {
        label: 'City',
        translationSlug: 'generator.heading.city',
        name: 'city',
        component: GeneratorInput,
      },
      {
        label: 'Description',
        name: 'description',
        translationSlug: 'generator.form.description',
        component: TextareaEditorWithData,
        props: {
          rows: 5,
          searchType: 'experience',
        },
        fullWidth: true,
      },
    ],
  },
  HOBBIES: {
    icon: HobbyIconExperiment,
    iconV2: '🎨',
    fields: [
      {
        label: 'What are your hobbies or things you like to do?',
        translationSlug: 'generator.hobbies_description',
        name: 'description',
        component: TextareaAutosave,
        props: {
          rows: 3,
          placeholder: 'Photography, Hiking, Swimming',
          placeholderSlug: 'generator.hobbies_placeholder',
          help: WIZARD_HELP_PHRASES.hobbies,
          styled: true,
          fullWidth: true,
          isHobbies: true,
        },
      },
    ],
  },
  EMPLOYMENT: {
    getItemTitle(item, t, language) {
      const { title, employer, startDate, endDate, current } = item.fields || {};
      let res = displayTitle(title, employer, ` ${t('from')} `, t);
      res += `, ${displayDateRange(startDate, endDate, current, false, t, language)}`;
      return res;
    },
    icon: EmploymentIcon,
    iconV2: '💼',
    afterText:
      'In this section, list related employment experience in your last 10 years along with the dates. Mention the most recent employment first.',
    slugAfterText: 'generator.experience.info',
    items: [
      {
        label: 'Job title',
        translationSlug: 'generator.form.jobtitle',
        name: 'title',
        component: JobTitleAutocompleteExperiment,
        props: {
          placeholder: 'CEO',
          placeholderSlug: 'ceo',
          errorMessages: [ERROR_MESSAGES.jobRequired],
          withRequiredValidator: true,
          type: 'jobs',
          inputType: 'text',
          name: 'title',
          //props used for getting options from API
          options: [],
          isOptionsDynamic: true,
          getOptions: searchJobTitle,
        },
        parentRef: true,
        needUpdate: true,
        increasedZindex: true,
      },
      {
        label: 'Employer',
        translationSlug: 'generator.form.employer',
        name: 'employer',
        component: GeneratorInput,
        props: {
          placeholder: 'Google',
          placeholderSlug: 'employer',
          validators: {
            required: ERROR_MESSAGES.employerRequired,
          },
          errorMessages: [ERROR_MESSAGES.employerRequired],
          withRequiredValidator: true,
        },
      },
      {
        name: ['startDate', 'endDate', 'current'],
        component: DateRangeAutosave,
        isRange: true,
        props: {
          showCheckbox: true,
        },
        needUpdate: true,
      },
      {
        label: 'City',
        name: 'city',
        translationSlug: 'city',
        component: GeneratorInput,
        props: {
          placeholder: 'San Francisco',
          placeholderSlug: 'san_francisco',
        },
      },
      {
        label: 'Description',
        name: 'description',
        translationSlug: 'generator.form.description',
        component: TextareaEditorWithData,
        props: {
          rows: 5,
          placeholder: 'Write your work experience',
          placeholderSlug: 'generator.employment_description_placeholder',
          searchType: 'experience',
        },
        fullWidth: true,
        childRef: true,
      },
    ],
  },
  SKILLS: {
    getItemTitle(item, t) {
      const { skill, skillLevel } = item.fields || {};
      let res = t(getSkillsLevelSlug(capitalize(skillLevel)));
      if (skill) {
        res = `${skill}, ${res}`;
      }
      return res;
    },
    icon: SkillsIcon,
    iconV2: '💪',
    items: [
      {
        label: 'Skill',
        name: 'skill',
        translationSlug: 'generator.skill',
        component: SkillAutocompleteExperiment,
        props: {
          searchType: 'skill',
          placeholder: 'Type your skill here',
          placeholderSlug: 'generator.skills_skill_placeholder',
          //props used for getting options from API
          options: [],
          isOptionsDynamic: true,
          getOptions: searchSkills,
        },
      },
      {
        name: 'skillLevel',
        component: SkillLevelAutosave,
      },
    ],
    additionalFlags: [
      {
        name: 'showTagUI',
        label: 'View skills as tags',
        translationSlugAdditionalFlags: 'generator.skills.view_as_tags',
        condition: () =>
          getExpSkillsTagVars() === 'with_tags_default_bar' || getExpSkillsTagVars() === 'with_tags_default_tags',
        template: expTagsTemplates,
      },
      {
        name: 'hideLevel',
        label: 'Hide experience level',
        translationSlugAdditionalFlags: 'generator.skills.hide_experience',
        condition: (block, resume) => {
          if (!expTagsTemplates?.includes(resume.settings.template) || getExpSkillsTagVars() == 'skills_tag_control') {
            return true;
          }
          if (block) {
            return !(block.showTagUI == true);
          }
        },
      },
    ],
  },
  SOFT_SKILLS: {
    getItemTitle(item, t) {
      const { skill, skillLevel } = item.fields || {};
      let res = t(getSkillsLevelSlug(capitalize(skillLevel)));
      if (skill) {
        res = `${skill}, ${res}`;
      }
      return res;
    },
    icon: SkillsIcon,
    iconV2: '💪',
    items: [
      {
        label: 'Soft Skill',
        name: 'skill',
        translationSlug: 'generator.skill',
        component: GeneratorInput,
        props: {
          searchType: 'skill',
          placeholder: 'Type your skill here',
          placeholderSlug: 'generator.skills_skill_placeholder',
        },
      },
      {
        name: 'skillLevel',
        component: SkillLevelAutosave,
      },
    ],
    additionalFlags: [
      {
        name: 'hideLevel',
        label: 'Hide experience level',
        translationSlugAdditionalFlags: 'generator.skills.hide_experience',
      },
    ],
  },
  HARD_SKILLS: {
    getItemTitle(item, t) {
      const { skill, skillLevel } = item.fields || {};
      let res = t(getSkillsLevelSlug(capitalize(skillLevel)));
      if (skill) {
        res = `${skill}, ${res}`;
      }
      return res;
    },
    icon: SkillsIcon,
    iconV2: '💪',
    items: [
      {
        label: 'Hard Skill',
        name: 'skill',
        translationSlug: 'generator.skill',
        component: GeneratorInput,
        props: {
          searchType: 'skill',
          placeholder: 'Type your skill here',
          placeholderSlug: 'generator.skills_skill_placeholder',
        },
      },
      {
        name: 'skillLevel',
        component: SkillLevelAutosave,
      },
    ],
    additionalFlags: [
      {
        name: 'hideLevel',
        label: 'Hide experience level',
        translationSlugAdditionalFlags: 'generator.skills.hide_experience',
      },
    ],
  },
  CUSTOM_SKILLS_CATEGORY: {
    getItemTitle(item, t) {
      const { skill, skillLevel } = item.fields || {};
      let res = t(getSkillsLevelSlug(capitalize(skillLevel)));
      if (skill) {
        res = `${skill}, ${res}`;
      }
      return res;
    },
    icon: SkillsIcon,
    iconV2: '💪',
    items: [
      {
        label: 'Custom Skill',
        name: 'skill',
        translationSlug: 'generator.skill',
        component: GeneratorInput,
        props: {
          searchType: 'skill',
          placeholder: 'Type your skill here',
          placeholderSlug: 'generator.skills_skill_placeholder',
        },
      },
      {
        name: 'skillLevel',
        component: SkillLevelAutosave,
      },
    ],
    additionalFlags: [
      {
        name: 'hideLevel',
        label: 'Hide experience level',
        translationSlugAdditionalFlags: 'generator.skills.hide_experience',
      },
    ],
  },
  EDUCATION: {
    getItemTitle(item, t, language) {
      const { school, degree, endDate } = item.fields || {};
      const { titleSlug } = degreeOptions.find((dg) => dg.title == degree) || {};
      let res = displayTitle(t(titleSlug), school, ` ${t('at')} `, t);
      res += `, ${displayDate(endDate, 'YYYY', null, t, language)}`;
      return res;
    },
    icon: EducationIcon,
    iconV2: '🎓',
    afterText:
      'In this section, list your level education; include any degrees and educational achievements, if relevant. Include the dates of your achievements.',
    slugAfterText: 'generator.education.info',
    items: [
      {
        label: 'School',
        name: 'school',
        translationSlug: 'generator.form.school',
        component: GeneratorInput,
        props: {
          placeholder: 'Harvard',
          placeholderSlug: 'harvard',
          errorMessages: [ERROR_MESSAGES.required],
          types: ['establishment'],
          instantValidate: false,
        },
      },
      {
        label: 'Degree',
        name: 'degree',
        translationSlug: 'generator.form.degree',
        component: DropdownCustomAutosave,
        props: {
          placeholder: 'Select degree',
          placeholderSlug: 'generator.form.select_degree',
          options: degreeOptions,
          errorMessages: [ERROR_MESSAGES.required],
          customLabel: '+ Enter your own degree',
          customLabelSlug: 'generator.enter_own_degree',
          withRequiredValidator: true,
        },
        needUpdate: true,
      },
      {
        label: 'Graduation date',
        name: 'endDate',
        translationSlug: 'generator.form.graduation_date',
        component: SimpleYearPickerAutosave,
        props: {
          placeholder: 'Select date',
          placeholderSlug: 'generator.form.select_date',
          scrollOption: true,
        },
      },
      {
        label: 'City',
        name: 'city',
        translationSlug: 'generator.form.city',
        component: GeneratorInput,
        props: {
          placeholder: 'San Francisco',
          placeholderSlug: 'san_francisco',
        },
      },
      {
        label: 'Description',
        name: 'description',
        translationSlug: 'generator.form.description',
        component: TextareaEditorWithData,
        props: {
          rows: 5,
          placeholder: 'Write your educational experience',
          placeholderSlug: 'generator_education_description',
          searchType: 'education',
          hideSearchBar: true,
        },
        fullWidth: true,
      },
    ],
  },
  SOCIAL_LINKS: {
    getItemTitle(item, t) {
      const fields = item.fields || {};
      return fields.label || `(${t('generator.form.not_specified')})`;
    },
    icon: SocialLinksIcon,
    iconV2: '🔗',
    afterText:
      'In this section, add links to your personal website, portfolio, and/or Linkedin profile that you would like prospective employers to see.',
    slugAfterText: 'generator.final.websites.info',
    items: [
      {
        label: 'Label',
        translationSlug: 'generator.final.websites.label',
        name: 'label',
        type: 'text',
        component: GeneratorInput,
      },
      {
        label: 'Link',
        translationSlug: 'generator.final.websites.link',
        name: 'url',
        props: {
          type: 'url',
        },
        component: GeneratorInput,
      },
    ],
  },
  DRIVING_LICENSE: {
    icon: VehicleIconExperiment,
    iconV2: '🚘',
    fields: [
      {
        label: 'Driving license category',
        translationSlug: 'generator.driving_license_category',
        name: 'drivingLicense',
        component: GeneratorInput,
        props: {
          placeholder: 'Full',
          placeholderSlug: 'generator.full',
          validated: false,
          instantValidate: false,
        },
      },
    ],
  },
  PUBLICATIONS: {
    getItemTitle(item, t) {
      const { publicationArticleTitle, publicationTitle } = item.fields || {};
      const res = displayTitle(publicationArticleTitle, publicationTitle, ' - ', t);

      return res;
    },
    icon: PublicationIconExperiment,
    iconV2: '🖋',
    items: [
      {
        label: 'Title of article',
        translationSlug: 'generator.publications_title_article',
        name: 'publicationArticleTitle',
        component: GeneratorInput,
        props: {
          placeholder: 'Article title here',
          placeholderSlug: 'generator.article_here',
          validated: false,
          validators: { required: ERROR_MESSAGES.required },
          errorMessages: [ERROR_MESSAGES.required],
          instantValidate: false,
        },
      },
      {
        label: 'Title of publication',
        translationSlug: 'generator.publications_title_publication',
        name: 'publicationTitle',
        component: GeneratorInput,
        props: {
          placeholder: 'Publication title here',
          placeholderSlug: 'generator.publication_here',
          validated: false,
          validators: { required: ERROR_MESSAGES.required },
          errorMessages: [ERROR_MESSAGES.required],
          instantValidate: false,
        },
      },
      {
        name: 'publicationDate',
        component: DatepickerAutosave,
        props: {
          placeholder: 'Select date',
          placeholderSlug: 'generator.form.select_date',
          showDay: true,
          label: 'Publication Date',
          labelSlug: 'generator.publication_date',
        },
      },
    ],
  },
  PERSONAL_DETAILS: {
    icon: PersonalInfoIconExperiment,
    iconV2: '🔍',
    fields: [
      {
        label: 'Nationality',
        translationSlug: 'nationality',
        name: 'nationality',
        component: GeneratorInput,
        props: {
          placeholder: 'French',
          placeholderSlug: 'generator.nationality.placeholder',
          validated: false,
          instantValidate: false,
        },
      },
      {
        label: 'Date of birth',
        translationSlug: 'generator.date_birth',
        name: 'dateOfBirth',
        component: GeneratorInput,
        props: {
          placeholder: 'Date of birth',
          placeholderSlug: 'generator.date_birth_new',
          instantValidate: false,
          labelSlug: 'generator.date_birth',
          showCheckbox: true,
          name: 'isDOBFormatted',
          label: 'Show date in number format',
          translationSlugAdditionalFlags: 'generator.show_date_checkbox_text',
        },
      },
      {
        label: 'Visa status',
        translationSlug: 'generator.visa_status',
        name: 'visaStatus',
        component: GeneratorInput,
        props: {
          placeholder: 'Approved',
          placeholderSlug: 'generator.approved',
          validated: false,
          instantValidate: false,
        },
      },
      {
        label: 'Marital status',
        translationSlug: 'marital_status',
        name: 'maritalStatus',
        component: GeneratorInput,
        props: {
          placeholder: 'Single',
          placeholderSlug: 'generator.single',
          validated: false,
          instantValidate: false,
        },
      },
    ],
  },
  PROFESSIONAL_SUMMARY: {
    icon: SummaryIcon,
    iconV2: '🖋',
    subTitle: 'Include up to 3 sentences that describe your general experience',
    fields: [
      {
        label: 'Summary',
        translationSlug: 'generator.wizard.summary',
        name: 'description',
        component: TextareaEditorWithData,
        props: {
          rows: 5,
          placeholder:
            'e.g. Accomplished Marketing Manager with expertise in developing campaigns, copywriting and advertising...',
          placeholderSlug: 'generator_professional_summary_placeholder',
          searchType: 'summary',
          suggestionBlockType: 'paragraph',
          fullWidth: true,
        },
      },
    ],
  },
};

export const COVER_LETTER_MAP = [
  {
    title: 'Personal details',
    titleSlug: 'generator.personal_details',
    items: [
      {
        name: 'userPic',
        component: UploadPhoto,
        fullWidth: true,
        sizeSmall: true,
        props: {
          isGenerator: true,
        },
      },
      {
        label: 'First name',
        translationSlug: 'generator.heading.first_name',
        name: 'firstName',
        component: GeneratorInput,
        props: {
          placeholder: 'Name',
          placeholderSlug: 'peter',
        },
      },
      {
        label: 'Last name',
        translationSlug: 'generator.heading.last_name',
        name: 'lastName',
        component: GeneratorInput,
        props: {
          placeholder: 'Johnson',
          placeholderSlug: 'placeholder_lastname',
        },
      },
      {
        label: 'Job title',
        name: 'jobTitle',
        translationSlug: 'generator.form.jobtitle',
        component: GeneratorInput,
        props: {
          placeholder: 'e.g. Marketing Manager',
          placeholderSlug: 'generator_job_title_placeholder',
          data: [],
          type: 'text',
          validators: { required: ERROR_MESSAGES.required },
          errorMessages: [ERROR_MESSAGES.requiredJob],
          instantValidate: false,
        },
        fullWidth: true,
      },
      {
        label: 'Email',
        translationSlug: 'email',
        name: 'email',
        component: GeneratorInput,
        props: {
          type: 'email',
          placeholder: 'e.g. mail@example.com',
          placeholderSlug: 'email_placeholder',
          validators: validateEmail,
          errorMessages: [ERROR_MESSAGES.requiredEmail, ERROR_MESSAGES.email],
          instantValidate: false,
        },
      },
      {
        label: 'Phone Number',
        translationSlug: 'phone_number',
        name: 'phone',
        component: GeneratorInput,
      },
      {
        label: 'Address',
        translationSlug: 'generator.heading.address',
        name: 'address',
        component: GeneratorInput,
        fullWidth: true,
      },
      {
        label: 'City',
        translationSlug: 'generator.heading.city',
        name: 'city',
        component: GeneratorInput,
        props: {
          placeholder: 'San Francisco',
          placeholderSlug: 'san_francisco',
          isCity: true,
        },
      },
      {
        label: 'Zip Code',
        translationSlug: 'generator.form.zip',
        name: 'postalCode',
        component: GeneratorInput,
        props: {
          placeholder: '94120',
        },
      },
      {
        label: 'Country',
        translationSlug: 'generator.form.country',
        name: 'country',
        component: GeneratorInput,
      },
    ],
  },
  {
    title: 'Employer details',
    titleSlug: 'generator.employer_details',
    items: [
      {
        label: 'Company Name',
        translationSlug: 'generator.company_name',
        name: 'companyName',
        component: GeneratorInput,
        props: {
          placeholder: 'Apple',
          validators: { required: ERROR_MESSAGES.required },
          errorMessages: [ERROR_MESSAGES.jobRequired],
          withRequiredValidator: true,
          inputType: 'text',
        },
        parentRef: true,
        needUpdate: true,
        fullWidth: true,
      },

      {
        label: 'Company Address',
        translationSlug: 'generator.form.company_address',
        name: 'companyAddress',
        component: GeneratorInput,
        fullWidth: true,
        props: {
          isCompany: true,
        },
      },
      {
        label: 'City',
        translationSlug: 'generator.heading.city',
        name: 'companyCity',
        component: GeneratorInput,
        props: {
          placeholder: 'San Francisco',
          placeholderSlug: 'san_francisco',
          isCity: true,
        },
      },
      {
        label: 'Zip Code',
        translationSlug: 'generator.form.zip',
        name: 'companyPostalCode',
        component: GeneratorInput,
        props: {
          placeholder: '94120',
        },
      },
      {
        label: 'Country',
        translationSlug: 'generator.form.country',
        name: 'companyCountry',
        component: GeneratorInput,
        fullWidth: true,
      },

      {
        label: 'Hiring Manager Name',
        translationSlug: 'generator.hiring_manager_name',
        name: 'companyManagerName',
        component: GeneratorInput,
        props: {
          placeholder: 'Frank Doyle',
          placeholderSlug: 'frank_doyle',
        },
      },
      {
        label: 'Hiring Manager Email Address',
        translationSlug: 'generator.form.hiring_manager_email',
        name: 'companyManagerEmail',
        component: GeneratorInput,
        props: {
          type: 'email',
          placeholder: 'e.g. mail@example.com',
          placeholderSlug: 'email_placeholder',
          validators: validateEmail,
          errorMessages: [ERROR_MESSAGES.email],
          instantValidate: false,
        },
      },
      {
        label: 'Hiring Manager Position',
        translationSlug: 'generator.form.hiring_manager_position',
        name: 'hiringManagerPosition',
        component: GeneratorInput,
      },
      {
        label: 'Gender',
        translationSlug: 'generator.form.gender',
        name: 'gender',
        component: DropdownAutosave,
        props: {
          placeholder: 'Select Employer gender',
          placeholderSlug: 'preview.select.gender',
          options: [
            { slug: 'preview.gender.male', value: 'male' },
            { slug: 'preview.gender.female', value: 'female' },
            { slug: 'preview.gender.unknown', value: 'Unknown' },
          ].map((v) => ({
            titleSlug: v.slug,
            title: v.value,
            value: v.value,
          })),
        },
      },
    ],
  },
  {
    type: 'socialLinks',
  },
  {
    title: 'Profile Summary',
    titleSlug: 'preview.profile_summary',
    items: [
      {
        label: 'Include 2-3 clear sentences about your overall experience',
        translationSlug: 'generator.cover_letter_profile_detail',
        name: 'profileSummary',
        component: TextareaEditorAutosave,
        props: {
          rows: 5,
          hideSearch: true,
        },
        fullWidth: true,
      },
    ],
  },
  {
    title: 'Letter details',
    titleSlug: 'generator.letter_details',
    items: [
      {
        label: 'Describe your self why are you’re the best Candidate for the job post',
        translationSlug: 'generator.cover_letter_profile_summary',
        name: 'letter',
        component: TextareaEditorAutosave,
        props: {
          rows: 5,
          hideSearch: true,
        },
        fullWidth: true,
      },
    ],
  },
  {
    title: 'Signature',
    titleSlug: 'generator.signature',
    type: 'signature',
    items: [
      {
        name: 'showSignature',
        label: 'Show Signature',
        translationSlug: 'generator.show_signature',
        component: SwitchAutosave,
        fullWidth: true,
        variables: {
          needUpdate: true,
        },
      },
      {
        label: 'Signature',
        name: 'signature',
        translationSlug: 'generator.signature',
        component: GeneratorInput,
        props: {
          placeholder: 'e.g. S.J Williams',
          placeholderSlug: 'generator.signature.placeholder',
        },
      },
      {
        label: 'Select Signature Font',
        translationSlug: 'generator.select_signature_font',
        name: 'signatureFont',
        component: DropdownAutosave,
        props: {
          placeholder: 'Select font',
          placeholderSlug: 'generator.select_font',
          options: signatureFonts,
        },
        needUpdate: true,
      },
    ],
  },
];
