import React from 'react';
import styled from 'styled-components';
import useIntl from 'imports/core/api/useIntl';

import BasicContainer from '/imports/homepageV4/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV4/ui/atoms/BasicSectionWrapper';
import GroupIcon from '/imports/homepageV4/ui/atoms/svg/GroupIcon';
import GuideIcon from '/imports/homepageV4/ui/atoms/svg/GuideIcon';
import StyledH2 from '/imports/homepageV4/ui/atoms/StyledH2';
import StyledH3 from '/imports/homepageV4/ui/atoms/StyledH3';
import WritingIcon from '/imports/homepageV4/ui/atoms/svg/WritingIcon';

const Section2 = () => {
  const { t } = useIntl();
  return (
    <SectionWrapper>
      <BasicContainer>
        <HowItsWorkWrapper>
          <HiwH2>{t('theresumeranger_landing_sec2_h2')}</HiwH2>
          <HiwBlueBar />
          <HiwBlocksWrapper>
            <HiwBlock>
              <HiwH3>01</HiwH3>
              <GroupIcon />
              <HiwBlockText>{t('theresumeranger_landing_sec2_hiw1_text')}</HiwBlockText>
            </HiwBlock>
            <HiwBlock>
              <HiwH3>02</HiwH3>
              <WritingIcon />
              <HiwBlockText>{t('theresumeranger_landing_sec2_hiw2_text')}</HiwBlockText>
            </HiwBlock>
            <HiwBlock>
              <HiwH3>03</HiwH3>
              <GuideIcon />
              <HiwBlockText>{t('theresumeranger_landing_sec2_hiw3_text')}</HiwBlockText>
            </HiwBlock>
          </HiwBlocksWrapper>
        </HowItsWorkWrapper>
      </BasicContainer>
    </SectionWrapper>
  );
};
const SectionWrapper = styled(BasicSectionWrapper)`
  padding-top: 0;
  padding-bottom: 0;
`;
const HowItsWorkWrapper = styled.div`
  position: relative;
  padding-right: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  @media (max-width: 479px) {
    padding-right: 0px;
    padding-left: 0px;
  }
`;
const HiwH2 = styled(StyledH2)`
  margin-top: 0px;
  margin-bottom: 32px;
  text-align: center;
`;
const HiwBlueBar = styled.div`
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 45%;
  border-radius: 16px;
  background-color: #eff5ff;
  @media (max-width: 767px) {
    height: 65%;
  }
  @media (max-width: 479px) {
    height: 75%;
  }
`;
const HiwBlocksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    justify-content: space-around;
    flex-wrap: wrap;
    > div:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;
const HiwBlock = styled.div`
  position: relative;
  display: flex;
  width: 30%;
  padding: 48px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-row-gap: 32px;
  border: 1px solid #e3e3e4;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 4px 4px 0 0 #eff5ff;
  text-align: center;
  @media (max-width: 767px) {
    width: 48%;
    grid-row-gap: 0;
    > div {
      margin-top: 32px;
    }
  }
  @media (max-width: 479px) {
    width: 90%;
  }
`;
const HiwH3 = styled(StyledH3)`
  position: absolute;
  left: 16px;
  top: 8px;
  right: auto;
  bottom: auto;
  margin-top: 0px;
  color: #ececed;
  font-size: 32px;
  line-height: 44px;
`;
const HiwBlockText = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`;
export default Section2;
