import styled from 'styled-components';

const StyledH4 = styled.h4`
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  font-weight: bold;
  font-family: Gilroy;
  text-align: center;
`;
export default StyledH4;
