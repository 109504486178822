import { isArabic } from 'styles/pdf-fonts/helpers';
import styled, { css } from 'styled-components';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Heading = styled(TextAtom)`
  ${({
    theme: { headingFont, convertPx, color, setGenderStyle, arabicHeadingFont },
    blocktitle,
    light,
    children,
  }) => css`
    font-family: ${isArabic(children) ? arabicHeadingFont() : headingFont()};
    font-size: ${convertPx(16)};
    color: #808183;
    text-transform: uppercase;
    margin-bottom: ${convertPx(18)};
    word-break: break-word;
    page-break-inside: avoid;
    break-inside: avoid;
    ${blocktitle &&
    css`
      color: #414142;
      ${color &&
      color === 'black' &&
      setGenderStyle('female')`
            color: #2c3a4b;
          `}
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: ${convertPx(-4)};
        height: ${convertPx(1)};
        background-color: #414142;
        ${color &&
        color === 'black' &&
        setGenderStyle('female')`
              background-color: #bcbec0;
            `}
      }
    `}
    ${light &&
    css`
      color: #e6e7e8;
      ${color === 'black' &&
      setGenderStyle('female')`
            color:#a28848;
          `}
      &:after {
        background-color: transparent;
      }
    `}
  `}
`;

export default Heading;
