import styled from 'styled-components';

const StyledLink = styled.div`
  background-color: transparent;
  max-width: 100%;
  display: inline-block;
  &:active {
    outline: 0;
  }
  &:hover {
    outline: 0;
  }
`;
export default StyledLink;
