import React from 'react';
import useIntl from 'imports/core/api/useIntl';
import styled, { css } from 'styled-components';

import { ACCPTED_CARDS_LOGO } from '/imports/core/api/constants';
import BasicContainer from '/imports/homepageV4/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV4/ui/atoms/BasicSectionWrapper';
import Flex from '/imports/core/ui/atoms/Flex';
import { getCompanyNameByHost, getLandingAddress, staticFile } from '/lib/helpers';
import Link from '/components/Link';
import StyledH4 from '/imports/homepageV4/ui/atoms/StyledH4';
import StyledLink from '/imports/homepageV4/ui/atoms/StyledLink';
import { useResponsive } from '/imports/core/api/responsiveContext';

const Footer = () => {
  const { t } = useIntl();
  const { host } = useResponsive();
  return (
    <FooterWrapper>
      <AcceptedPaymentWrapper>
        <AcceptedPayment $justifyContent="center" $alignItems="center">
          <StyledH4>{t('footer_payment_options')}</StyledH4>
          <CardWrapper>
            {ACCPTED_CARDS_LOGO.map((k, i) => (
              <PaymentLogo $image={k} key={i} />
            ))}
          </CardWrapper>
        </AcceptedPayment>
      </AcceptedPaymentWrapper>
      <FooterSectionWrapper>
        <BasicContainer>
          <FooterLinks>
            <Link href="/terms-and-conditions" passHref>
              <FooterLink>
                <div>{t('footer.terms_conditions')}</div>
              </FooterLink>
            </Link>
            <Link href="/privacy-policy" passHref>
              <FooterLink>
                <div>{t('footer.privacy_policy')}</div>
              </FooterLink>
            </Link>
            <Link href="/contact-us" passHref>
              <FooterLink>
                <div>{t('header.contact_us')}</div>
              </FooterLink>
            </Link>
            <Link href="/checkout" passHref>
              <FooterLink>
                <div>{t('home_pricing')}</div>
              </FooterLink>
            </Link>
            <Link href="/cancel-subscription" passHref>
              <FooterLink>
                <div>{t('contact_cancel_subscription_title')}</div>
              </FooterLink>
            </Link>
            <Link href="/login" passHref>
              <FooterLink>
                <div>{t('landing.login')}</div>
              </FooterLink>
            </Link>
            <Link href="/legal-notice" passHref>
              <FooterLink>
                <div>{t('footer.legal_notice')}</div>
              </FooterLink>
            </Link>
          </FooterLinks>
          <FooterLink $companyName>{getCompanyNameByHost(host)}</FooterLink>
          <FooterAddress>Address: {getLandingAddress(host)}</FooterAddress>
        </BasicContainer>
      </FooterSectionWrapper>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  display: block;
  margin-top: 24px;
  @media (max-width: 479px) {
    margin-top: 12px;
  }
`;
const AcceptedPaymentWrapper = styled.div`
  padding: 0 32px 24px;
  @media (max-width: 479px) {
    padding: 32px 8px 12px;
  }
`;
const AcceptedPayment = styled(Flex)`
  > div {
    margin-top: 10px;
  }
  flex-direction: column;
  padding-bottom: 16px;
  @media (min-width: 768px) {
    flex-direction: row;
    padding-bottom: 0px;
  }
  @media (max-width: 479px) {
    > div {
      margin-top: 16px;
    }
    padding-bottom: 16px;
  }
`;
const PaymentLogo = styled.div`
  height: 28px;
  width: 60px;
  ${({ $image }) =>
    $image &&
    css`
      background-image: url(${staticFile(`img/${$image}`)});
    `}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
const CardWrapper = styled(Flex)`
  gap: 10px;
`;
const FooterSectionWrapper = styled(BasicSectionWrapper)`
  padding-top: 32px;
  padding-bottom: 32px;
  border-top: 1px solid #e3e3e4;
`;
const FooterLinks = styled.div`
  display: flex;
  min-width: 200px;
  margin-right: auto;
  margin-bottom: 16px;
  margin-left: auto;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  @media (max-width: 1007px) {
    flex-direction: column;
    align-items: center;
    grid-row-gap: 24px;
  }
  @media (max-width: 479px) {
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: 24px;
  }
`;
const FooterAddress = styled.div`
  color: #595964;
  text-align: center;
  @media (max-width: 479px) {
    text-align: left;
  }
`;
const FooterLink = styled(StyledLink)`
  text-align: center;
  color: #3a3a43;
  line-height: 22px;
  text-decoration: none;
  max-width: 100%;
  display: inline-block;
  cursor: pointer;
  padding: 4px 0;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #1688fe;
  }
  ${({ $companyName }) =>
    $companyName &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;
export default Footer;
