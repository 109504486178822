import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import Head from 'next/head';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { useMutation } from 'react-apollo';
import styled, { createGlobalStyle, css } from 'styled-components';
import { inputStyle, overrideInputStyleMixin } from '/imports/core/ui/mixins';
import { usePlaceKit } from '@placekit/autocomplete-react';
import { UPDATE_AUTOSUGGEST } from 'imports/generator/api/apollo/client/mutations';
import { updateOptimisticAutoSuggest } from 'imports/generator/api/apollo/client/helpers';
import Icon from '/imports/core/ui/atoms/ValidatedInputIcon';
import { isJobTrackEnable } from '/lib/helpers';
import useIntl from '/imports/core/api/useIntl';
import { getCountry } from '/imports/checkout/api/utils';
import Input from '/imports/core/ui/atoms/Input';
import ct from 'countries-and-timezones';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import env from '/env';
import CountrySelector from '/imports/core/ui/atoms/CountrySelector';

const PlacekitInputAutoSuggestion = (props) => {
  const {
    formMethods: { reset, setValue },
    lang,
    details,
    resume,
  } = props;
  const country = details.countryCode ? details.countryCode : getCountry();
  const { t } = useIntl();
  const withCountryDropdown = marvelEmitter.getActiveVariant('exp_placekit_autocomplete') === 'placekit_with_dropdown';
  const [countryCode, setCountryCode] = useState(country);
  const countries = ct.getAllCountries();
  const excludedCountryCodes = ['AX', 'BL', 'BQ', 'MF', 'SX', 'UM'];
  const countryCodes = Object.keys(countries).filter((code) => !excludedCountryCodes.includes(code));

  const { target, client } = usePlaceKit(
    env.PLACEKIT_API_KEY,
    withCountryDropdown ? { countries: [countryCode], language: lang } : { language: lang },
  );
  const router = useRouter();
  const { updateImmue, view, defaultvalue, BlockGoogleMapCountry = [] } = props;
  const { resumeId } = router.query;
  const [values, setValues] = useState({
    address: '',
    city: '',
    zipcode: '',
    country: '',
    countryCode: '',
  });
  const [textInput, setTextInput] = useState('');
  const [hideIcon, setHideIcon] = useState(false);
  const [results, setResults] = useState([]);
  const [blockCountry, setBlockCountry] = useState([]);
  const [updateAutosuggest] = useMutation(UPDATE_AUTOSUGGEST);
  const placeholder = t('generator_address_placeholder');

  useEffect(() => {
    client?.on('pick', (value, item) => handlePick(value, item));
    client?.on('results', (_, results) => setResults(results));
  }, [client, countryCode]);

  useEffect(() => {
    if (defaultvalue) {
      setValues((prevValues) => ({ ...prevValues, address: defaultvalue }));
      if (textInput !== defaultvalue) setTextInput(defaultvalue);
    }
  }, [defaultvalue]);

  const handlePick = useCallback(
    (value, item) => {
      const newValues = {
        address: value,
        city: item?.city,
        zipcode: item?.zipcode && item?.zipcode[0],
        country: item?.country,
        countryCode: item?.countrycode,
      };
      setValues(newValues);
      const otherUpdatedFields = {
        postalCode: newValues?.zipcode ? newValues?.zipcode.toString() : '',
        city: newValues?.city ?? '',
        country: item?.country,
        countryCode: item?.countrycode,
      };
      reset((prev) => ({ ...prev, ...otherUpdatedFields }), {
        keepValues: false,
        keepDefaultValues: true,
        keepErrors: true,
      });
      debouncedUpdateAutosuggest(newValues);
      if (props.onChange) props.onChange({ target: { value } });
    },
    [debouncedUpdateAutosuggest, props, reset],
  );

  const handleChange = useCallback(
    (e) => {
      setTextInput(e.target.value);
      if (props.onChange) {
        props.onChange({ target: { value: e.target.value } });
      }
    },
    [props],
  );

  const handleUpdateAutosuggest = useCallback(
    async (updatedValues) => {
      try {
        const suggestVariables = {
          resumeId,
          zip: updatedValues?.zipcode ?? null,
          country: updatedValues?.country ?? null,
          countryCode: updatedValues?.countryCode ?? null,
          city: updatedValues?.city ?? null,
          address: updatedValues.address,
        };
        updateOptimisticAutoSuggest(updateImmue, suggestVariables);
        return updateAutosuggest({
          variables: suggestVariables,
        });
      } catch (error) {
        console.error('Error updating autosuggest:', error);
      }
    },
    [resumeId, updateAutosuggest, updateImmue],
  );

  const handleFocus = useCallback(() => setHideIcon(true), []);
  const handleBlur = useCallback(() => setHideIcon(false), []);

  const debouncedUpdateAutosuggest = useMemo(() => debounce(handleUpdateAutosuggest, 500), [handleUpdateAutosuggest]);

  useEffect(() => {
    const blockCountry = localStorage.getItem('resume_data');
    if (blockCountry) {
      const parsedData = JSON.parse(blockCountry);
      if (parsedData.BlockGoogleMapCountry) {
        setBlockCountry(parsedData.BlockGoogleMapCountry);
      }
    }
  }, []);
  const isEmpty = !(values.address || defaultvalue);

  if (!blockCountry.includes(country)) {
    return (
      <Fragment>
        <Head>
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/@placekit/autocomplete-js@latest/dist/placekit-autocomplete.min.css"
          />
          <script src="https://cdn.jsdelivr.net/npm/@placekit/autocomplete-js@latest"></script>
        </Head>
        <GlobalStyle results={results} />
        <Wrap>
          {withCountryDropdown && (
            <CountrySelector countryCode={countryCode.toLowerCase()} setCountryCode={setCountryCode} docId={resumeId} />
          )}
          <StyledInput
            ref={target}
            type="text"
            name="address"
            placeholder={placeholder}
            defaultValue={defaultvalue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            $withCountryDropdown={withCountryDropdown}
          />
          {props.value && (
            <Icon view={view} inputValid={!isEmpty} empty={isEmpty} hide={hideIcon} isJobTrack={isJobTrackEnable()} />
          )}
        </Wrap>
      </Fragment>
    );
  } else return <Input {...props} placeholder={placeholder} />;
};

export default PlacekitInputAutoSuggestion;

const Wrap = styled.div`
  position: relative;
  border-radius: ${(p) => p.theme.general.borderRadius};
  margin-bottom: 5px;
`;

const InputStyle = css`
  ${inputStyle}
  ${overrideInputStyleMixin}
  color: #484870;
  box-shadow: none;
  border: 2px solid #e6e6ff;
  border-width: 2px;
  border-style: solid;
  padding: 13px 16px 9px;
  box-shadow: none;
  border-radius: 3px;
  border: 2px solid #e6e6ff;
  ${({ theme }) =>
    theme.designV2 &&
    css`
      height: 48px;
      border-radius: 8px;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding: 13px 16px 13px;
      border: solid 1px #e3e3e4;
      background-color: #ffffff;
      flex-direction: row;
    `}

  :focus {
    border-color: ${({ theme }) => (theme.designV2 ? '#428eff' : '#1688fe')};

    & ~ div {
      visibility: visible;
      transform: rotateY(0);
    }
    ${({ theme }) => css`
      & ~ div {
        border-color: ${theme.colors.primary};
      }
    `};
  }

  &:focus {
    border-color: ${({ theme }) => (theme.designV2 ? '#428eff' : '#1688fe')};
  }

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
  ${({ $withCountryDropdown, theme: { isRTL } }) =>
    $withCountryDropdown &&
    css`
      padding-left: ${isRTL ? '16px' : '67px'};
      padding-right: ${isRTL ? '67px' : '16px'};
    `}
`;

const StyledInput = styled.input`
  width: 100% !important;
  ${InputStyle}

  &::placeholder {
    color: ${(p) => p.theme.colors.gray.regular};
  }
`;

const GlobalStyle = createGlobalStyle`

${({ theme: { isRTL } }) =>
  isRTL &&
  css`
    .pka-panel-suggestion-label {
      text-align: right;
    }
  `}
 
  .pka-input {
    width: 100% !important;
    padding-left: 0;
  }
  .pka-panel-suggestion-label-name mark {
    text-decoration:unset;
    font-family: TTCommons;
    font-weight: 700;
    color:#000;
    font-size: 15px;
  }
  .pka-panel-suggestion-label-name, .pka-panel-suggestion-label-sub {
    display:unset;
  }
  .pka-panel-footer {
    display: none !important;
  }
  .pka-panel-suggestion-action {
    display: none !important;
  }
  .pka-input-clear {
    display: none;
  }
  .pka-panel-suggestions {
    width: 100%;
  }
  .pka-icon {
    display: none !important;
  }
  .pka-panel-suggestion {
    border-bottom: 1px solid #e6e6e6;
  }
  .pka-panel-suggestions > div:hover{
    background-color: #fafafa;
  }
  .pka-panel-suggestion:last-child {
    border-bottom: none;
  }
  .pka-panel-suggestion.pka-active .pka-panel-suggestion-label-name{
    color: rgb(157,157,157);
  }
  .pka-panel-suggestion-label-sub {
    font-size: 13px;
    font-family: TTCommons;
    font-weight: 400;
    line-height: 18px;
    color: rgb(157,157,157);
  }
  .pka-panel-suggestion-label-name {
    font-weight: 400;
    color: unset;
    font-size: 13px;
  }
  .pka-panel-suggestion.pka-active{
    background:unset;
  }
  
  ${({ results }) =>
    results.length === 0 &&
    css`
      .pka-panel {
        display: none !important;
      }
    `}
   
`;
