import React, { PureComponent } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import styled from 'styled-components';

import PDFViewer from '/imports/pdf/core/ui/components/PDFViewer';
import PreviewHeader from '/imports/generator/ui/components/PreviewHeader';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withTracking } from '/imports/core/hooks/useTracking';
import { CLICK_TO_EDIT_EXP_NEW_VERSION } from '/imports/generator/api/helpers';

@withResponsiveContext
@withTracking
class PreviewWrapper extends PureComponent {
  state = {
    width: 0,
  };

  componentDidMount = () => {
    const { trackEvent, isMobile } = this.props;
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
    let obj = {};
    const editExpVariant = marvelEmitter.getActiveVariant('click_to_edit_exp_new_v4');
    if (editExpVariant && !isMobile) {
      obj.click_to_edit_exp_new_v4 = editExpVariant;
      obj.click_to_edit_version = CLICK_TO_EDIT_EXP_NEW_VERSION;
    }
    trackEvent('preview_view', obj);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  componentDidUpdate(prevProps, _) {
    if (prevProps.loadingState && !this.props.loadingState) {
      this.updateWidth();
    }
  }

  updateWidth = () => {
    if (!this.preview) return;
    const width = this.preview.offsetWidth;
    this.setState({ width });
  };

  getPreviewRef = (r) => {
    this.preview = r;
  };

  getPreview = () => {
    const { width } = this.state;
    const { setPageState, getResume, updateImmue } = this.props;
    return (
      <Preview ref={this.getPreviewRef}>
        <PDFViewer
          source={getResume}
          updateCount={getResume.updatesCount}
          setPageState={setPageState}
          width={width}
          noDebounce
          updateImmue={updateImmue}
        />
      </Preview>
    );
  };

  render() {
    const { loading, getResume, updateImmue } = this.props;

    return (
      <Page>
        <PreviewHeader source={getResume} loading={loading} updateImmue={updateImmue} />
        <PreviewContent>{this.getPreview()}</PreviewContent>
      </Page>
    );
  }
}

const Page = styled.section`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  pointer-events: auto;
  transform: translateY(0px);
  flex-flow: column nowrap;
`;

const PreviewContent = styled.div`
  background-color: #e5e4ea;
  height: calc(100vh - 70px);
  flex: 1 0 0px;
  padding: 20px;
  overflow-y: auto;

  ${({ theme }) => theme.max('sm')`
    padding: 0px 20px;
  `}

  &::-webkit-scrollbar {
    width: 14px;
    height: 292px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    scroll-padding-top: 10px;
    background-color: #131518;
  }
`;

const Preview = styled.div`
  max-width: 928px;
  overflow: hidden;
  height: ${({ height }) => height}px;
  transform: translateY(0px);
  opacity: 1;
  margin: 60px auto;
  background: white;
  border-radius: ${({ theme }) => theme.general.borderRadius};
  ${({ theme }) => theme.max('md')`
    margin: 44px auto;
  `}
  ${({ theme }) => theme.max('sm')`
    margin: 20px auto;
  `}
`;

export default PreviewWrapper;
