import React from 'react';

const PlanetIcon = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0002 30.7996C8.01579 30.7996 2.3335 25.1173 2.3335 18.133C2.3335 11.1486 8.01579 5.46631 15.0002 5.46631C21.9845 5.46631 27.6668 11.1486 27.6668 18.133C27.6668 25.1173 21.9845 30.7996 15.0002 30.7996Z"
        fill="#0050C7"
      />
      <path
        d="M8.31283 23.383C9.01469 23.383 9.58366 22.814 9.58366 22.1121C9.58366 21.4103 9.01469 20.8413 8.31283 20.8413C7.61096 20.8413 7.04199 21.4103 7.04199 22.1121C7.04199 22.814 7.61096 23.383 8.31283 23.383Z"
        fill="#83F8C7"
      />
      <path
        d="M20.3338 20.8C21.0702 20.8 21.6672 20.203 21.6672 19.4666C21.6672 18.7303 21.0702 18.1333 20.3338 18.1333C19.5974 18.1333 19.0005 18.7303 19.0005 19.4666C19.0005 20.203 19.5974 20.8 20.3338 20.8Z"
        fill="#83F8C7"
      />
      <path
        d="M15.0005 14.7998C13.8976 14.7998 13.0005 13.9027 13.0005 12.7998C13.0005 11.6969 13.8976 10.7998 15.0005 10.7998C16.1034 10.7998 17.0005 11.6969 17.0005 12.7998C17.0005 13.9027 16.1034 14.7998 15.0005 14.7998Z"
        fill="#83F8C7"
      />
      <path
        d="M27.4944 20.2098C27.3928 20.8161 27.2425 21.4055 27.0573 21.9794C29.6656 26.1264 30.0761 28.781 29.2423 29.6152C28.4249 30.4326 26.2019 29.9876 23.296 28.4228C20.0779 26.6901 16.3686 23.7988 12.8516 20.2818C5.27189 12.7021 1.84936 5.56017 3.51831 3.89122C4.3525 3.05793 7.00732 3.46829 11.1539 6.0762C11.7279 5.8909 12.3175 5.74067 12.924 5.63907C7.98156 2.29373 4.2152 1.30858 2.5756 2.94851C1.26212 4.26199 1.59448 6.86258 3.53654 10.4697C5.3295 13.7988 8.30281 17.6184 11.9089 21.2246C15.515 24.8307 19.3347 27.804 22.6638 29.5969C24.8253 30.7607 26.6251 31.3463 28.0037 31.3463C28.9252 31.3463 29.6586 31.0842 30.185 30.5579C31.8254 28.9174 30.84 25.1525 27.4944 20.2098Z"
        fill="#5DDFA9"
      />
    </svg>
  );
};
export default PlanetIcon;
