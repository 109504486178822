import { Fragment, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { darken } from 'polished';
import styled from 'styled-components';

import BlockItemMobileControls from '/imports/generator/ui/components/BlockItemMobileControls';
import ButtonAtom from '/imports/core/ui/atoms/Button';
import { Chevron, OutlinedDeleteIcon, UpArrow } from '/imports/generator/ui/assets';
import DragDropIcon from 'imports/coaching/ui/assets/DragDropIcon';
import intlHook from 'imports/core/api/useIntl';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

const BlockItemControls = (props) => {
  const {
    item: { order, id },
    items,
    isCollapsed,
    isMobile,
    onAfterAction,
    isDragging,
    type,
    showTooltip,
  } = props;

  const iconRef = useRef();
  const tooltipRef = useRef();
  const { t } = intlHook();
  const [tooltipStyle, setTooltypeStyle] = useState({});

  useEffect(() => {
    if (!isMobile) {
      updateTooltipPosition();
    }
  }, [showTooltip]);

  const updateTooltipPosition = () => {
    if (tooltipRef.current && iconRef.current) {
      const iconRect = iconRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const left = Math.ceil(iconRect.left + iconRect.width / 2 - tooltipRect.width / 2) - 5;
      const top = iconRect.top;
      setTooltypeStyle({
        left: `${left}px`,
        top: `${top}px`,
      });
    }
  };

  const moveUp = (e) => {
    e.preventDefault();
    const { moveUp, onAfterAction } = props;
    moveUp();
    if (onAfterAction) onAfterAction();
  };

  const moveDown = (e) => {
    e.preventDefault();
    const { moveDown, onAfterAction } = props;
    moveDown();
    if (onAfterAction) onAfterAction();
  };

  const expand = (e) => {
    e.preventDefault();
    const { expand, onAfterAction } = props;
    expand();

    if (onAfterAction) onAfterAction();
  };

  const remove = (e) => {
    e.preventDefault();
    const { removeBlock, onAfterAction } = props;
    removeBlock();
    if (onAfterAction) onAfterAction();
  };

  const chevron = (() => {
    if (isCollapsed) return <Chevron.Down />;
    return <Chevron.Up />;
  })();

  const BLOCK_WITH_DRAG = ['SOCIAL_LINKS', 'LANGUAGES', 'REFERENCES'];

  if (isMobile)
    return (
      <BlockItemMobileControls
        t={t}
        isCollapsed={isCollapsed}
        expand={expand}
        moveUp={moveUp}
        moveDown={moveDown}
        remove={remove}
        onAfterAction={onAfterAction}
        order={order}
        items={items}
        isDragging={isDragging}
        type={type}
        isNewDragAndDrop={marvelEmitter.getActiveVariant('exp_other_section_DD_V2') === 'with_drag'}
      />
    );
  return (
    <Fragment>
      <Fragment>
        {order > 0 && type !== 'SKILLS' && !BLOCK_WITH_DRAG.includes(type) && (
          <Button onClick={moveUp}>
            <UpArrow />
          </Button>
        )}
        {order + 1 < items.length && type !== 'SKILLS' && !BLOCK_WITH_DRAG.includes(type) && (
          <Button onClick={moveDown}>
            <DownArrowCont>
              <UpArrow />
            </DownArrowCont>
          </Button>
        )}
      </Fragment>

      {items.length > 1 && (type === 'SKILLS' || BLOCK_WITH_DRAG.includes(type)) && (
        <TooltipWrapper style={{ cursor: isDragging ? 'move' : 'pointer' }}>
          {showTooltip === id && !isDragging && (
            <Fragment>
              <Tooltip ref={tooltipRef} style={{ ...tooltipStyle }}>
                <ToastText>{t('tooltip_drag_drop')}</ToastText>
                <PointArrow />
              </Tooltip>
              <ButtonDrag
                style={{
                  color: isDragging && '#1688fe',
                  display: isDragging && 'unset',
                  cursor: isDragging ? 'move' : 'pointer',
                }}
              >
                <DragDropIcon ref={iconRef} />
              </ButtonDrag>
            </Fragment>
          )}
        </TooltipWrapper>
      )}

      {showTooltip === id && !isDragging && (
        <ButtonDrag
          onClick={remove}
          style={{
            color: isDragging && '#1688fe',
            display: isDragging && 'unset',
            cursor: isDragging ? 'hand' : 'pointer',
          }}
        >
          <OutlinedDeleteIcon />
        </ButtonDrag>
      )}

      {props?.block?.type != 'SKILLS' && !BLOCK_WITH_DRAG.includes(type) && (
        <Button onClick={remove}>
          <OutlinedDeleteIcon />
        </Button>
      )}
      <ButtonExpand onClick={expand} style={{ color: isDragging && '#1688fe' }}>
        {chevron}
      </ButtonExpand>
    </Fragment>
  );
};

BlockItemControls.propTypes = {
  moveUp: PropTypes.func,
  moveDown: PropTypes.func,
  remove: PropTypes.func,
  expand: PropTypes.func,
  onAfterAction: PropTypes.func,
  block: PropTypes.object,
  resume: PropTypes.object,
  item: PropTypes.object,
  items: PropTypes.array,
  isMobile: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  showTooltip: PropTypes.string,
  type: PropTypes.string,
  removeBlock: PropTypes.func,
  isDragging: PropTypes.string,
};

export const ButtonDrag = styled.div`
  color: #7171a6;
  margin-right: 10px;
`;

export const Button = styled((p) => <ButtonAtom unstyled {...p} />)`
  color: #7171a6;
  margin-right: 10px;
  display: none;
  &:hover {
    color: ${(p) => darken(0.1, p.theme.colors.primary)} !important;
  }
`;

const ButtonExpand = styled((p) => <Button {...p} />)`
  display: inline-block !important;
  svg {
    stoke-width: 0;
  }
`;

export const DownArrowCont = styled.span`
  svg {
    transform: scale(-1);
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
`;

const ToastText = styled.div`
  color: var(--light-values-white);
  font-size: 14px;
  line-height: 14px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  text-wrap: nowrap;
`;

const PointArrow = styled.div`
  position: absolute;
  bottom: -5px;
  left: 50%;
  right: 0;
  margin-right: auto;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--grayscale-n800);
`;

const Tooltip = styled.div`
  display: inline-block;
  position: fixed;
  align-items: center;
  padding: 12px;
  min-height: auto;
  border-radius: 4px;
  user-select: none;
  z-index: 9999;
  background-color: var(--grayscale-n800);
  margin-top: -45px;
`;

export default BlockItemControls;
