import PropTypes from 'prop-types';
import { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import RelatedSkillsModal from '/imports/generator/ui/components/RelatedSkillsModal';
import RelatedSkillsCloud from '/imports/generator/ui/components/RelatedSkillsCloud';
import { getRelatedSkills } from '/imports/generator/api/api.js';
import { useRouter } from 'next/router';

const RelatedSkills = ({ resume, updateImmue, variant }) => {
  const [showSkillsModal, setShowSkillsModal] = useState(true);
  const [relatedSkills, setRelatedSkills] = useState([]);
  const [stillAdding, setStillAdding] = useState(false);
  const [jobTitle, setJobTitle] = useState(resume.details?.title);
  const [titlesList, setTitlesList] = useState([]); // only for checking if the titles has been changed

  const skillsBlock = resume.blocks.find((block) => block.type === 'SKILLS');
  const experienceBlock = resume.blocks.find((block) => block.type === 'EMPLOYMENT');
  const hasSkills = skillsBlock && skillsBlock.items && skillsBlock.items.length > 0;
  const { query } = useRouter();
  const currentView = query.step;

  const handleSkillsModalClose = () => {
    setShowSkillsModal(false);
    setStillAdding(false);
  };

  const fetchSkills = async () => {
    let title = resume.details?.title;
    let fetchedSkills = [];
    try {
      fetchedSkills = title ? await getRelatedSkills(title) : [];
      if (fetchedSkills.length === 0) {
        // fetch skills from experience
        let i = 0;
        while (fetchedSkills.length === 0 && experienceBlock && i < experienceBlock?.items?.length) {
          const experience = experienceBlock.items[i];
          title = experience.fields.title;
          fetchedSkills = await getRelatedSkills(title);
          i++;
        }
      }
      setRelatedSkills(fetchedSkills);
      if (fetchedSkills.length > 0) setJobTitle(title);
    } catch (e) {
      setRelatedSkills([]);
    }
  };

  useEffect(() => {
    fetchSkills();
  }, [titlesList]);

  const updateTitles = () => {
    const title = resume.details?.title;
    let titles = [];
    if (title) titles.push(title);
    if (experienceBlock) {
      experienceBlock.items.forEach((item) => {
        titles.push(item.fields.title);
      });
    }
    setTitlesList(titles);
  };

  useEffect(() => {
    /*
      Listen to job title change event to update the related skills
      Instead of checking the resume change in the useEffect, we can listen to the event
      As the event will be triggered only when the job title is changed
    */
    document.addEventListener('jobtitlechange', updateTitles);

    return () => document.removeEventListener('jobtitlechange', updateTitles);
  }, []);

  useEffect(() => {
    if (variant === 'with_cloud_design' && !hasSkills) {
      setStillAdding(true);
    }
  }, [hasSkills]);

  const showRelatedSkills = () => {
    let show = jobTitle && relatedSkills.length > 0 && !hasSkills;
    return show || (stillAdding && relatedSkills.length > 0);
  };

  const renderExpAutoFetchRelatedSkills = () => {
    switch (variant) {
      case 'with_big_screen_popup':
        return (
          <Fragment>
            {showSkillsModal && (
              <RelatedSkillsModal
                onClose={handleSkillsModalClose}
                resume={resume}
                updateImmue={updateImmue}
                relatedSkills={relatedSkills}
                jobTitleToShow={jobTitle}
              />
            )}
          </Fragment>
        );
      case 'with_cloud_design':
        return (
          <RelatedSkillsCloud
            resume={resume}
            updateImmue={updateImmue}
            relatedSkills={relatedSkills}
            currentView={currentView}
          />
        );
      default:
        return <h1></h1>;
    }
  };
  return <Fragment>{showRelatedSkills() && renderExpAutoFetchRelatedSkills()}</Fragment>;
};

export default RelatedSkills;

RelatedSkills.propTypes = {
  resume: PropTypes.object,
  updateImmue: PropTypes.func,
  variant: PropTypes.string,
};

const SidePopupWrapper = styled.div`
  position: relative;
`;
