import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import GlassDoor from '/imports/extensionOnboarding/assets/GlassDoor';
import IndeedLogo from '/imports/extensionOnboarding/assets/IndeedLogo';
import LinkedInLogo from '/imports/extensionOnboarding/assets/LinkedInLogo';
import NaukriLogo from '/imports/extensionOnboarding/assets/NaukriLogo';
import { staticFile } from '/lib/helpers';

const SvgWrapper = styled(Flex)`
  flex-shrink: 0;
  svg {
    height: 24px;
    width: 24px;
  }
  ${({ indeed }) =>
    indeed &&
    css`
      svg {
        fill: rgb(5, 98, 249);
      }
    `}
  ${({ naukari }) =>
    naukari &&
    css`
      svg > g {
        fill: rgb(13, 102, 173);
      }
    `}

${({ bg }) =>
    bg &&
    css`
      background-image: url(${staticFile(`${bg}`)});
      background-position: center;
      background-color: var(--light-values-white);
      height: 24px;
      width: 24px;
      background-size: contain;
    `}

  ${({ jobIcon }) =>
    jobIcon &&
    css`
      background-size: cover;
      height: 26px;
      width: 26px;
      border-radius: 4px;
    `}
`;

export const INDIAN_COMPANYS = [
  {
    name: 'Linkedin',
    url: 'https://in.linkedin.com/jobs/search/?position=1&pageNum=0',
    logo: (
      <SvgWrapper>
        <LinkedInLogo />
      </SvgWrapper>
    ),
  },
  {
    name: 'Indeed',
    url: 'https://in.indeed.com/jobs?q=remote&l=Remote',
    logo: (
      <SvgWrapper indeed>
        <IndeedLogo />
      </SvgWrapper>
    ),
  },
  {
    name: 'Naukri',
    url: 'https://www.naukri.com/remote-jobs-in-remote?k=remote&l=remote',
    logo: (
      <SvgWrapper naukri>
        <NaukriLogo />
      </SvgWrapper>
    ),
  },
  {
    name: 'Glass Door',
    url: 'https://www.glassdoor.co.in/Job/index.htm',
    logo: (
      <SvgWrapper>
        <GlassDoor />
      </SvgWrapper>
    ),
  },
  {
    name: 'Jooble',
    url: 'https://in.jooble.org/SearchResult?date=3&rgns=remote&ukw=remote',
    logo: <SvgWrapper bg="img/JoobleLogo.png" />,
  },
];

export const FRANCE_COMPANY = [
  {
    name: 'Linkedin',
    url: 'https://fr.linkedin.com/jobs/search/?position=1&pageNum=0',
    logo: (
      <SvgWrapper>
        <LinkedInLogo />
      </SvgWrapper>
    ),
  },
  {
    name: 'Indeed',
    url: 'https://fr.indeed.com/jobs?q=remote&l=Remote',
    logo: (
      <SvgWrapper indeed>
        <IndeedLogo />
      </SvgWrapper>
    ),
  },
  {
    name: 'le bon coin',
    url: 'https://www.leboncoin.fr/_emploi_/offres',
    logo: <SvgWrapper bg="img/jobIcon.png" jobIcon />,
  },
  {
    name: 'Pole Emploi',
    url: 'https://candidat.pole-emploi.fr/offres/recherche?motsCles=remote&offresPartenaires=true&rayon=10&tri=0',
    logo: <SvgWrapper bg="img/poleEmploiLogo.png" />,
  },
  {
    name: 'Jooble',
    url: 'https://fr.jooble.org/SearchResult?date=3&rgns=remote&ukw=remote',
    logo: <SvgWrapper bg="img/JoobleLogo.png" />,
  },
  {
    name: 'Glass Door',
    url: 'https://www.glassdoor.fr/Emploi/index.htm',
    logo: (
      <SvgWrapper>
        <GlassDoor />
      </SvgWrapper>
    ),
  },
];

export const SPANISH_COMPANY = [
  {
    name: 'Linkedin',
    url: 'https://es.linkedin.com/jobs/search/?position=1&pageNum=0',
    logo: (
      <SvgWrapper>
        <LinkedInLogo />
      </SvgWrapper>
    ),
  },
  {
    name: 'Indeed',
    url: 'https://es.indeed.com/jobs?q=remote&l=Remote',
    logo: (
      <SvgWrapper indeed>
        <IndeedLogo />
      </SvgWrapper>
    ),
  },
  {
    name: 'Glass Door',
    url: 'https://www.glassdoor.co.in/Job/index.htm',
    logo: (
      <SvgWrapper>
        <GlassDoor />
      </SvgWrapper>
    ),
  },
  {
    name: 'Jooble',
    url: 'https://es.jooble.org/SearchResult?date=3&rgns=remote&ukw=remote',
    logo: <SvgWrapper bg="img/JoobleLogo.png" />,
  },
];

export const UNITED_ARAB_EMIRATES_COMPANY = [
  {
    name: 'Linkedin',
    url: 'https://ae.linkedin.com/jobs/search/?position=1&pageNum=0',
    logo: (
      <SvgWrapper>
        <LinkedInLogo />
      </SvgWrapper>
    ),
  },
  {
    name: 'Indeed',
    url: 'https://ae.indeed.com/jobs?q=remote&l=Remote',
    logo: (
      <SvgWrapper indeed>
        <IndeedLogo />
      </SvgWrapper>
    ),
  },
  {
    name: 'Glass Door',
    url: 'https://www.glassdoor.com/Job/index.htm',
    logo: (
      <SvgWrapper>
        <GlassDoor />
      </SvgWrapper>
    ),
  },
  {
    name: 'Jooble',
    url: 'https://ae.jooble.org/SearchResult?date=3&rgns=remote&ukw=remote',
    logo: <SvgWrapper bg="img/JoobleLogo.png" />,
  },
];

export const GLOBAL_COMPANY = [
  {
    name: 'Linkedin',
    url: 'https://www.linkedin.com/jobs/search/?position=1&pageNum=0',
    logo: (
      <SvgWrapper>
        <LinkedInLogo />
      </SvgWrapper>
    ),
  },
  {
    name: 'Indeed',
    url: 'https://www.indeed.com/jobs?q=remote&l=Remote',
    logo: (
      <SvgWrapper indeed>
        <IndeedLogo />
      </SvgWrapper>
    ),
  },
  {
    name: 'Glass Door',
    url: 'https://www.glassdoor.com/Job/index.htm',
    logo: (
      <SvgWrapper>
        <GlassDoor />
      </SvgWrapper>
    ),
  },
  {
    name: 'Jooble',
    url: 'https://jooble.org/SearchResult?date=3&rgns=remote&ukw=remote',
    logo: <SvgWrapper bg="img/JoobleLogo.png" />,
  },
];
