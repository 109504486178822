import React, { useEffect } from 'react';
import Head from 'next/head';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import qs from 'qs';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import useIntl from '/imports/core/api/useIntl';
import { cookieParser, getLocaleFromPath, getRoute, isBlogDomain, getDefaultLanguage, staticFile } from '/lib/helpers';
import Footer from '/imports/homepageV4/ui/components/Footer';
import Header from '/imports/homepageV4/ui/components/Header';
import { LANGUAGES_CODES } from '/imports/generator/api/constants';
import { Push } from '/components/Link';
import Section1 from '/imports/homepageV4/ui/components/Section1';
import Section2 from '/imports/homepageV4/ui/components/Section2';
import Section3 from '/imports/homepageV4/ui/components/Section3';
import Section4 from '/imports/homepageV4/ui/components/Section4';
import Section5 from '/imports/homepageV4/ui/components/Section5';
import Section6 from '/imports/homepageV4/ui/components/Section6';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';

const DESCRIPTION = `Boost your career with Resume Ranger, the fastest and most user-friendly online resume builder. Create a professional resume fast and land your dream job. Try it now!`;
const Homepage = ({ ctaClick, userIdentified }) => {
  const router = useRouter();
  const { host } = useResponsive();
  const { locale = 'en' } = useIntl();
  const backHackTrigged = localStorage.getItem('back_hack_triggered');

  const { trackEvent, sessionStartHandler, setPeople } = useTracking();
  const renderHack = () => {
    if (!backHackTrigged) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        if (isBlogDomain(host)) {
          window.location.href = '/builder/onboard/start';
        } else {
          window.location.href = `/${locale}/onboard/start`;
        }
        localStorage.setItem('back_hack_triggered', 'true');
        trackEvent('back_hack_triggered');
        window.history.go(0);
      };
    }
  };

  const handleRedirect = (event) => {
    const { clientY, clientX } = event;
    const isRedirected = typeof localStorage !== 'undefined' && localStorage.getItem('redirected');
    if (!isRedirected && (clientX <= 0 || clientY <= 0)) {
      typeof localStorage !== 'undefined' && localStorage.setItem('redirected', 'true');
      trackEvent('exit_hack_trigger');
      if (isBlogDomain(host)) {
        window.location.href = '/builder/onboard/start';
      } else {
        window.location.href = `/${locale}/onboard/start`;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mouseout', handleRedirect);
    return () => {
      document.removeEventListener('mouseout', handleRedirect);
    };
  }, []);

  useEffect(() => {
    const { asPath } = router;
    sessionStartHandler();
    setPeople();
    let obj = {};
    if (marvelEmitter.getActiveVariant('exp_landing_upload_resume')) {
      obj = {
        ...obj,
        exp_landing_upload_resume: marvelEmitter.getActiveVariant('exp_landing_upload_resume'),
      };
    }
    trackEvent('homepage_view', obj);

    const destination = '/resumes';
    const { token } = cookieParser();
    renderHack();

    if (userIdentified || token) {
      const queryString = {
        from: asPath,
      };
      const locale = getLocaleFromPath(
        asPath.split('?')[0],
        getDefaultLanguage(host) === LANGUAGES_CODES.FRENCH,
        getDefaultLanguage(host),
      );
      const route = getRoute(`${destination}`, locale, false, host, isBlogDomain(host));
      const queryValue = `?${qs.stringify(queryString)}`;
      const redirectPage = `${route}${queryValue}`;
      Push(redirectPage, locale, destination);
      return;
    }
    try {
      document.getElementsByTagName('body')[0].classList.add('_container');
    } catch (error) {
      console.error("Can't find body tag");
    }
  }, []);

  const getMetaInfo = () => {
    return (
      <>
        <meta name="description" content={DESCRIPTION} />
      </>
    );
  };
  const onClickLandingCTA = () => ctaClick('Create my CV');
  return (
    <>
      <Head>{getMetaInfo()}</Head>
      <GlobalBodyStyle>
        <Header onClickLandingCTA={onClickLandingCTA} userIdentified={userIdentified} />
        <Main>
          <Section1 onClickLandingCTA={onClickLandingCTA} userIdentified={userIdentified} />
          <Section2 />
          <Section3 onClickLandingCTA={onClickLandingCTA} userIdentified={userIdentified} />
          <Section4 />
          <Section5 onClickLandingCTA={onClickLandingCTA} userIdentified={userIdentified} />
          <Section6 />
        </Main>
        <Footer />
      </GlobalBodyStyle>
    </>
  );
};

Homepage.propsType = {
  ctaClick: PropTypes.func,
  userIdentified: PropTypes.bool,
};

const GlobalBodyStyle = styled.div`
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
  margin: 0;
  min-height: 100%;
  background-color: #fff;
  font-family: Gilroy, sans-serif;
  color: #333;
  font-size: 16px;
  line-height: 24px;
  @font-face {
    font-family: 'Gilroy';
    src: url(${staticFile('fonts/Gilroy-Bold.ttf')}) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(${staticFile('fonts/Gilroy-Regular.ttf')}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(${staticFile('fonts/Gilroy-Medium.ttf')}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
`;
const Main = styled.main`
  display: block;
  display: flex;
  flex-direction: column;
  gap: 96px;
  @media (max-width: 767px) {
    gap: 64px;
  }
  @media (max-width: 479px) {
    gap: 0;
    > div:not(:last-child) {
      margin-bottom: 48px;
    }
  }
`;

export default Homepage;
