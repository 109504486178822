import React from 'react';
import styled from 'styled-components';
import useIntl from 'imports/core/api/useIntl';
import { staticFile } from 'lib/helpers';

import BasicContainer from '/imports/homepageV4/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV4/ui/atoms/BasicSectionWrapper';
import IconRight from '/imports/homepageV3/ui/atoms/svg/IconRight';
import PrimaryBtn from '/imports/homepageV4/ui/atoms/PrimaryBtn';
import StyledH2 from '/imports/homepageV4/ui/atoms/StyledH2';
import StyledImage from '/imports/homepageV4/ui/atoms/StyledImage';
import StyledParagraph from '/imports/homepageV4/ui/atoms/StyledParagraph';

const Section3 = ({ userIdentified, onClickLandingCTA }) => {
  const { t } = useIntl();
  const QUALITIES = [
    {
      image: 'fast.svg',
      headtext: t(`theresumeranger_landing_sec3_sub3_quality13_headtext`),
      paragraph: t(`theresumeranger_landing_sec3_sub3_quality13_paragraph`),
    },
    {
      image: 'written.svg',
      headtext: t(`theresumeranger_landing_sec3_sub3_quality14_headtext`),
      paragraph: t(`theresumeranger_landing_sec3_sub3_quality14_paragraph`),
    },
    {
      image: 'check.svg',
      headtext: t(`theresumeranger_landing_sec3_sub3_quality15_headtext`),
      paragraph: t(`theresumeranger_landing_sec3_sub3_quality15_paragraph`),
    },
    {
      image: 'export.svg',
      headtext: t(`theresumeranger_landing_sec3_sub3_quality16_headtext`),
      paragraph: t(`theresumeranger_landing_sec3_sub3_quality16_paragraph`),
    },
  ];
  return (
    <SectionWrapper>
      <HiwContainer>
        <HiwImageTextWrapper>
          <HiwImageTextSubWrapper>
            <HiwITH2>{t('theresumeranger_landing_sec3_sub1_h2')}</HiwITH2>
            <HiwITParagraph>{t('theresumeranger_landing_sec3_sub1_text')}</HiwITParagraph>
            <PrimaryBtn100 onClick={onClickLandingCTA}>
              <div>{!userIdentified ? t('landing.cta') : t('landing.my_resumes')}</div>
              <IconRight />
            </PrimaryBtn100>
          </HiwImageTextSubWrapper>
          <HiwImageWrapper>
            <StyledImage
              src={staticFile('img/landingv4/Resume-Template-1.svg')}
              loading="lazy"
              alt="Resume-Template-img"
            />
          </HiwImageWrapper>
        </HiwImageTextWrapper>
        <HiwImageTextWrapperRev>
          <HiwImageWrapper>
            <StyledImage
              src={staticFile('img/landingv4/Resume-Builder-1.svg')}
              loading="lazy"
              alt="Resume-builder-img"
            />
          </HiwImageWrapper>
          <HiwImageTextSubWrapper>
            <HiwITH2>{t('theresumeranger_landing_sec3_sub2_h2')}</HiwITH2>
            <HiwITParagraph>{t('theresumeranger_landing_sec3_sub2_text')}</HiwITParagraph>
            <PrimaryBtn100 onClick={onClickLandingCTA}>
              <div>{!userIdentified ? t('landing.cta') : t('landing.my_resumes')}</div>
              <IconRight />
            </PrimaryBtn100>
          </HiwImageTextSubWrapper>
        </HiwImageTextWrapperRev>
        <HiwImageTextWrapperUpAligned>
          <LastSubWrapper>
            <LastSubList>
              {QUALITIES.map((quality, index) => (
                <ItemBlock key={index}>
                  <StyledImage src={staticFile(`img/landingv4/${quality.image}`)} loading="lazy" alt="" />
                  <ItemTextWrapper>
                    <ItemHeadText>{quality.headtext}</ItemHeadText>
                    <ItemParagraph>{quality.paragraph}</ItemParagraph>
                  </ItemTextWrapper>
                </ItemBlock>
              ))}
            </LastSubList>
            <PrimaryBtn100 onClick={onClickLandingCTA}>
              <div>{!userIdentified ? t('landing.cta') : t('landing.my_resumes')}</div>
              <IconRight />
            </PrimaryBtn100>
          </LastSubWrapper>
          <HiwInfosWrapper>
            <StyledImage src={staticFile('img/landingv4/left-1-1.svg')} loading="lazy" alt="Infos-Diluc" />
          </HiwInfosWrapper>
        </HiwImageTextWrapperUpAligned>
      </HiwContainer>
    </SectionWrapper>
  );
};
const SectionWrapper = styled(BasicSectionWrapper)`
  padding-top: 0;
  padding-bottom: 0;
`;
const HiwContainer = styled(BasicContainer)`
  display: flex;
  flex-direction: column;
  gap: 96px;
  @media (max-width: 767px) {
    gap: 0;
    > div:not(:last-child) {
      margin-bottom: 64px;
    }
  }
  @media (max-width: 479px) {
    > div:not(:last-child) {
      margin-bottom: 48px;
    }
  }
`;
const HiwImageTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    > div:not(:last-child) {
      margin-bottom: 48px;
    }
  }
`;
const HiwImageTextWrapperRev = styled(HiwImageTextWrapper)`
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;
const HiwImageTextWrapperUpAligned = styled(HiwImageTextWrapper)`
  align-items: flex-start;
  @media (max-width: 1140px) {
    grid-column-gap: 48px;
  }
  @media (max-width: 991px) {
    grid-column-gap: 48px;
  }
  @media (max-width: 965px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 767px) {
    grid-row-gap: 0;
    > div:not(:last-child) {
      margin-bottom: 48px;
    }
  }
`;
const HiwImageTextSubWrapper = styled.div`
  width: 51.5%;
  @media (max-width: 767px) {
    width: auto;
  }
`;
const HiwITH2 = styled(StyledH2)`
  margin-bottom: 16px;
  color: #14141f;
`;
const HiwITParagraph = styled(StyledParagraph)`
  margin-bottom: 32px;
  color: #3a3a43;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  @media (max-width: 479px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
const PrimaryBtn100 = styled(PrimaryBtn)`
  cursor: pointer;
  @media (max-width: 479px) {
    width: 100%;
    max-width: none;
    justify-content: center;
  }
`;
const HiwImageWrapper = styled.div`
  width: 40.5%;
  @media (max-width: 767px) {
    width: auto;
  }
`;
const LastSubWrapper = styled.div`
  width: 49%;
  @media (max-width: 965px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: auto;
  }
`;
const LastSubList = styled.div`
  display: flex;
  margin-bottom: 32px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const ItemBlock = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 16px;
  justify-content: flex-start;
  align-items: flex-start;
  > img {
    margin-right: 24px;
  }
  border: 1px solid #e3e3e4;
  border-radius: 8px;
  box-shadow: 6px 6px 0 0 #edf4ff;
`;
const ItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;
const ItemHeadText = styled.div`
  color: #14141f;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`;
const ItemParagraph = styled(StyledParagraph)`
  margin-bottom: 0px;
  color: #3a3a43;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;
const HiwInfosWrapper = styled.div`
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 67, 166, 0.12);
  @media (max-width: 956px) {
    width: auto;
  }
  @media (max-width: 479px) {
    width: 100%;
  }
`;
export default Section3;
