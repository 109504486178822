import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import DropdownCustomAutosave from '/imports/core/ui/atoms/DropdownCustomAutosave';
import GeneratorInput from '/imports/generator/ui/atoms/GeneratorInputAutosave';
import useIntl from '/imports/core/api/useIntl';

const JobTitleAutocompleteExperiment = ({ ...props }) => {
  const { locale } = useIntl();
  const resumeLang = props.lang;
  if (locale !== 'en' || resumeLang !== 'en') {
    return <GeneratorInput {...props} />;
  }
  const activeVar = marvelEmitter.getActiveVariant('job_title_autocomplete_exp_en_new');
  if (activeVar === 'control') {
    return <GeneratorInput {...props} />;
  }
  const hideArrow = activeVar === 'with_autocomplete_no_arrow';
  //with_autocomplete
  return <DropdownCustomAutosave {...props} onSelectedEventName="job_title_selected" hideArrow={hideArrow} />;
};

export default JobTitleAutocompleteExperiment;
