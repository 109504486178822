import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { LANGUAGES_CODES } from 'imports/generator/api/constants';
import styled, { css } from 'styled-components';

import BrushIcon from '/imports/HomepageV5/ui/assets/BrushIcon';
import { Button } from '/imports/HomepageV5/ui/atoms/Button';
import { AiSelectWon, cdnFile, localAiSelect, staticFile } from '/lib/helpers';

import useIntl from '/imports/core/api/useIntl';
import React from 'react';

const MESSAGE_SLUG = [
  { slug: 'generator.new_message', text: 'New' },
  { slug: 'generator.most_selected_message', text: 'Most Selected' },
  { slug: 'onboarding.recommended', text: 'Recommended' },
];

const getSlug = (title) => {
  return MESSAGE_SLUG.find((el) => el.text === title).slug;
};

const OnboardingSLiderTemplateV1 = React.memo(
  ({
    name,
    id,
    image,
    width,
    height,
    featured,
    message,
    selected,
    isWebsite = false,
    noName = false,
    newLanding = false,
    lessMargin = false,
    isV5 = false,
    onSelect,
  }) => {
    const { locale, t, resumeLocale } = useIntl();

    const select = () => {
      if (selected) return onSelect(id, image);
    };

    const getVariants = () => {
      switch (locale) {
        case 'he':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_hebrew');
        case 'nl':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_dutch');
        case 'ja':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_japanese');
        case 'az':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_azerbaijani');
        case 'ca':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_catalan');
        case 'sv':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_swedish');
        case 'kk':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_kazakh');
        case 'ka':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_georgian');
        case 'el':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_greek');
        case 'tj':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_tajik');
        case 'pt':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_portuguese');
        case 'sr':
          return marvelEmitter.getActiveVariant('exp_template_english_vs_serbian');
        default:
          return null;
      }
    };
    const ctaClick = (e) => {
      e.stopPropagation();
      return onSelect(id, image);
    };

    const imageName = image || id;
    const allowExpDisplay = getVariants();
    const staticImageLocale = Object.values(LANGUAGES_CODES).filter(
      (lang) =>
        !['en', 'ka', 'el', 'kk', 'pt', 'sr', 'sv', 'tj', 'ca', 'az', 'ja', 'nl', 'he', 'hi', 'bn', 'uz'].includes(
          lang,
        ),
    );

    // const isOldBudapestImage =
    //   ['ar', 'ru', 'fr', 'es', 'cs', 'vi'].includes(locale) &&
    //   marvelEmitter.getActiveVariant('exp_template_budapest') === 'old_budapest' &&
    //   id === 'budapest';
    // const templateImageUrl = isOldBudapestImage
    //   ? staticFile(`img/resume/${resumeLocale}/${imageName}_old.png`)
    //   : (!!allowExpDisplay && allowExpDisplay !== 'default_english') || staticImageLocale.includes(resumeLocale)
    //   ? staticFile(`img/resume/${resumeLocale}/${imageName}.jpg`)
    //   : cdnFile(`resumes/${imageName}.jpg`);
    const isTemplateAI =
      localAiSelect.includes(locale) && marvelEmitter.getActiveVariant('exp_select_cv_ai') === 'ai_generated';
    const templateImageUrl =
      (isTemplateAI && localAiSelect.includes(resumeLocale)) || AiSelectWon.includes(resumeLocale)
        ? staticFile(`img/resumeai/${resumeLocale}/${imageName}.jpg`)
        : (!!allowExpDisplay && allowExpDisplay !== 'default_english') || staticImageLocale.includes(resumeLocale)
          ? staticFile(`img/resume/${resumeLocale}/${imageName}.jpg`)
          : cdnFile(`resumes/${imageName}.jpg`);
    const templateImageATSResume = staticFile(`img/ATSResumeWithProfile.png`);

    return (
      <Template
        width={width}
        selected={selected}
        onClick={select}
        isWebsite={isWebsite}
        lessMargin={lessMargin}
        isV5={isV5}
      >
        <TemplateImage
          selected={selected}
          url={name === 'ATSResume' ? templateImageATSResume : templateImageUrl}
          width={width}
          height={height}
          isWebsite={isWebsite}
          newLanding={newLanding}
        >
          {isV5 && (
            <StyledButton alignItems="center" justifyContent="center" onClick={ctaClick}>
              <Wrapper>
                <BrushIcon />
              </Wrapper>
              {t('ai_headline_landing_text2')}
            </StyledButton>
          )}
          {featured && (
            <Recommended message={message} isWebsite={isWebsite} selected={selected}>
              <span>{t(getSlug(message)) || t('onboarding.recommended')}</span>
            </Recommended>
          )}
          <img src={name === 'ATSResume' ? templateImageATSResume : templateImageUrl} alt="template" />
        </TemplateImage>
        {!noName && (
          <TemplateName isWebsite={isWebsite} selected={selected}>
            {name}
            {'  '}
            {id === 'budapest' && '👑'}
          </TemplateName>
        )}

        {!isV5 && (
          <OlButton selected={selected} isWebsite={isWebsite} newLanding={newLanding}>
            {newLanding ? 'Use This Template' : t('onboarding.select')}
          </OlButton>
        )}
        <DownloadFormatBlock selected={selected}>WORD〡PDF</DownloadFormatBlock>
      </Template>
    );
  },
);

const Wrapper = styled.div`
  display: none;
  @media (min-width: 1600px) {
    display: flex;
  }
`;

const OldDownloadFormatBlock = css`
  border-radius: 4px;
  background-color: rgba(105, 105, 105, 0.74);
  position: absolute;
  left: 8px;
  display: flex;
  height: 24px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  color: var(--light-values-white);
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 145.455% */
  z-index: 2;
  bottom: 10px;
`;
const DownloadFormatBlock = styled.div`
  ${OldDownloadFormatBlock};
  ${({ theme }) =>
    theme.max('sm')`
      font-size: 11px;
      padding: 4px 8px;
    `}
  ${({ selected }) => css`
    transition: all 0.3s;

    ${selected &&
    css`
      margin-bottom: 5px;
      ${({ theme }) => theme.max('md')`
            margin-bottom: 50px;
          `}
    `}
  `}
`;

// move `selected` behaviour to :hover?

const StyledButton = styled(Button)`
  display: none;
  position: absolute;
  z-index: 99999;
  padding: 16px 9px;
  gap: 8px;
`;
const Template = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render on the DOM
    !['isWebsite', 'selected', 'lessMargin', 'isV5'].includes(prop),
})`
  display: inline-block;
  width: 212px;
  height: 303px;
  position: relative;
  backface-visibility: hidden;
  transform: scale(1);
  cursor: pointer;
  outline: none;
  transition: transform 0.2s ease;
  ${({ isWebsite, theme }) =>
    isWebsite &&
    css`
      margin: 18px;
      ${theme.max('xs')`
        margin-bottom: 100px;
      `}
    `}
  ${({ selected, isWebsite }) =>
    selected &&
    css`
      transform: scale(1.2);
      margin-inline: 20px;
      ${isWebsite &&
      css`
        transform: scale(1.09);
      `}
    `}
  ${({ lessMargin }) =>
    lessMargin &&
    css`
      margin: 8px;
      margin-bottom: 0;
    `}
  ${({ isV5 }) =>
    isV5 &&
    css`
      &:hover {
        ${StyledButton} {
          display: flex;
        }
      }
    `}
`;

const TemplateName = styled.h2.withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render on the DOM
    !['isWebsite'].includes(prop),
})`
  text-align: left;
  color: #8c98aa;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 140% */
  ${({ isWebsite }) =>
    isWebsite &&
    css`
      margin-bottom: 19px;
      margin-top: 0;
      font-family: ${({ theme }) => theme.font.family.websiteMedium};
      letter-spacing: 0.4px;
      color: #000f3d;
      font-size: 18px;
      font-weight: 600;
    `}

  ${({ isWebsite }) =>
    !isWebsite &&
    css`
      position: relative;
      svg {
        position: absolute;
        top: -50%;
        right: 5px;
        height: 40px;
        width: 35px;
        ${({ theme }) =>
          theme.max('sm')`
          width: 30px;
        `}
      }
    `}
${({ selected }) =>
    selected &&
    css`
      color: #1d2c43;
      font-family: ${({ theme }) => theme.font.family.semibold};
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 141.176% */
    `}
`;

const TemplateImage = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render on the DOM
    !['selected', 'newLanding', 'isWebsite'].includes(prop),
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 212px;
  height: 303px;
  background-repeat: no-repeat;
  background-color: var(--light-values-white);
  background-size: contain;
  border-radius: 8px;
  box-shadow:
    0px 0px 2px 0px rgba(29, 44, 67, 0.08),
    0px 2px 8px 0px rgba(29, 44, 67, 0.08);
  position: relative;
  box-shadow: 0 5px 8px 0 rgba(41, 59, 117, 0.11);
  img {
    width: 96%;
    height: 97%;
    border-radius: 8px;
  }
  ${({ selected }) =>
    selected &&
    css`
      box-shadow: none;
      border: 1px solid #e5e9f2;
      border-color: ${({ theme }) => theme.colors.primary2};
    `}
  ${({ newLanding }) =>
    newLanding &&
    css`
      img {
        width: 100%;
        height: 100%;
      }
      border-radius: 10px;
      box-shadow: 0 5px 8px 0 rgba(41, 59, 117, 0.11);
      border: 2px solid rgba(255, 255, 255, 0.64) !important;
    `}
`;

const Recommended = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render on the DOM
    !['message', 'isWebsite'].includes(prop),
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  width: 152px;
  height: 30px;
  padding-bottom: 2px;
  background-image: url(${staticFile('img/onboarding/recommended-banner-old.svg')});
  background-size: contain;
  background-repeat: no-repeat;
  line-height: 15px;
  text-align: center;
  color: var(--light-values-white);
  transform: rotate(45deg);
  border: 0px;
  position: absolute;
  top: 27px;
  right: -35px;
  z-index: 1;

  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  text-transform: none;

  ${({ message, theme }) =>
    message === 'Most Selected' &&
    css`
      background-image: url(${staticFile('img/onboarding/most-selected-bannerV1.svg')});
    `}
  ${({ message, theme }) =>
    message === 'New' &&
    css`
      background-image: url(${staticFile('img/onboarding/new-bannerV1.svg')});
    `}
  ${({ message, theme }) =>
    message === 'Recommended' &&
    css`
      background-image: url(${staticFile('img/onboarding/recommended-bannerV1.svg')});
    `}  

    ${({ theme }) =>
    theme.isRTL &&
    css`
      right: unset;
      left: -35px;
      transform: translate(rotate(-45deg));
      transform: rotate(-45deg);
    `}
`;

const OldOlButton = css`
  padding: 6px 16px;
  border-radius: 22px;
  opacity: 0;
  border: 1px solid #0099da;
  box-shadow: 0 25px 50px 0 rgba(24, 23, 50, 0.5);
  line-height: 21px;
  text-transform: none;
  transition: all 0.5s;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  cursor: pointer;
  touch-action: manipulation;
  position: absolute;
  z-index: 3;
  width: 158px;
  height: 44px;
  border-color: #4c7cf7;
  background-color: #4c7cf7;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.9px;
  transform: translateX(-50%);
  margin-bottom: 0px;
`;
export const OlButton = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    // prevent these style props to render on the DOM
    !['selected', 'newLanding', 'isWebsite'].includes(prop),
})`
  ${OldOlButton};
  bottom: 15%;
  ${({ theme }) => theme.max('md')`
      line-height: 18px;
      padding: 11px 18px;
      font-size: 16px;
    `}
  ${({ selected }) =>
    selected &&
    css`
      opacity: 1 !important;
      pointer-events: auto;
    `}

  ${({ newLanding }) =>
    newLanding &&
    css`
      border-radius: 8px;
      background-color: var(--primary);
      border: none;
      padding: 16px 0;
      color: var(--light-values-white);
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;
      flex-shrink: 0;
      width: 132px;
      box-shadow: none;
      &:hover {
        background-color: #ff8b75;
      }
      @media (min-width: 1441px) {
        padding: 16px 36px;
        width: fit-content;
      }
    `}
`;
export default OnboardingSLiderTemplateV1;
