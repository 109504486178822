import { isArabic } from 'styles/pdf-fonts/helpers';
import styled, { css } from 'styled-components';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Text = styled(TextAtom)`
  ${({ theme: { contentFont, convertPx, arabicContentFont }, children }) => css`
    font-family: ${isArabic(children) ? arabicContentFont() : contentFont()};
    font-size: ${convertPx(11.5, true)};
    line-height: 1.33;
    color: #666766;
  `}
`;

export default Text;
