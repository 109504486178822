import styled from 'styled-components';

const StyledH1 = styled.h1`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 56px;
  line-height: 64px;
  font-weight: 500;
`;
export default StyledH1;
