import styled from 'styled-components';
import { isArabic } from 'styles/pdf-fonts/helpers';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Text = styled(TextAtom)`
  font-family: ${({ theme: { contentFont, arabicContentFont }, children }) =>
    isArabic(children) ? arabicContentFont() : contentFont()};
  font-size: ${({ theme }) => theme.convertPx(14, true)};
  line-height: 1.43;
  color: #77787b;
`;

export default Text;
