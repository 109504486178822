import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';

import { ALL_LANGUAGES } from '/imports/generator/api/constants';
import { blockDetailImmutableUpdate } from '/imports/generator/api/apollo/client/helpers';
import DropdownAutosave from '/imports/core/ui/atoms/DropdownAutosave';
import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import { UPDATE_COVER_LETTER_DETAIL, UPDATE_RESUME_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import useIntl from '/imports/core/api/useIntl';

const LanguageSelector = ({
  source: {
    id,
    settings: { language },
  },
  isCoverLetter,
  updateImmue,
}) => {
  const { locale, t } = useIntl();
  const label = isCoverLetter ? t('coverletter_language') : t('resume_language');

  const updatedLanguages = ALL_LANGUAGES.map((language) => {
    if (language.title === 'Deutsch' && locale === 'en') {
      return {
        ...language,
        title: 'German',
      };
    }
    return language;
  });

  const [resumeLanguage, setResumeLanguage] = useState(() => sortBy(updatedLanguages, 'title'));
  const [coverLetterLanguage, setCoverLetterLanguage] = useState(() =>
    sortBy(
      updatedLanguages.filter((language) => language.showOnCoverLetter),
      'title',
    ),
  );

  useEffect(() => {
    setCoverLetterLanguage((prevState) => prevState.filter((language) => language.showOnCoverLetter));
  }, []);

  return (
    <Wrap language={language}>
      <FieldLabel summary label={label} />
      <DropdownAutosave
        mutation={isCoverLetter ? UPDATE_COVER_LETTER_DETAIL : UPDATE_RESUME_DETAIL}
        variables={{
          docId: id,
          path: `settings.language`,
          needUpdate: true,
        }}
        name="language"
        docType={isCoverLetter ? 'coverLetter' : 'resume'}
        value={language || locale}
        options={isCoverLetter ? coverLetterLanguage : resumeLanguage}
        isCoverLetter={isCoverLetter}
        scrollOnKeyPress={true}
        optimisticResponse={blockDetailImmutableUpdate(updateImmue)(id, 'settings.language')}
      />
    </Wrap>
  );
};

const Wrap = styled.div`
  position: relative;
`;

LanguageSelector.propTypes = {
  source: PropTypes.object.isRequired,
  isCoverLetter: PropTypes.bool.isRequired,
  updateImmue: PropTypes.func.isRequired,
};

export default LanguageSelector;
