import React, { Fragment, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Chevron } from '/imports/generator/ui/assets';
import styled, { css } from 'styled-components';
import ct from 'countries-and-timezones';
import Flex from '/imports/core/ui/atoms/Flex';
import { useMutation } from 'react-apollo';
import { UPDATE_RESUME_DETAIL } from 'imports/generator/api/apollo/client/mutations';

const Flag = ({ countryCode }) => {
  return <FlagWrapper src={`https://flagcdn.com/w20/${countryCode}.png`} loading="lazy" alt={countryCode} />;
};

const CountrySelector = ({ countryCode, setCountryCode, docId }) => {
  const countries = useMemo(() => ct.getAllCountries(), []);
  const [updateResumeDetail] = useMutation(UPDATE_RESUME_DETAIL);
  const excludedCountryCodes = useMemo(() => ['AX', 'BL', 'BQ', 'MF', 'SX', 'UM'], []);

  const countriesOptions = useMemo(() => {
    return Object.keys(countries)
      .map((code) => ({
        code: code.toLowerCase(),
        name: countries[code].name,
      }))
      .filter((country) => !excludedCountryCodes.includes(country.code.toUpperCase()))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [countries, excludedCountryCodes]);

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  const inputRef = useRef(null);
  const searchTimeoutRef = useRef(null);

  const handleClickOutside = useCallback(
    (e) => {
      if (!dropdownRef.current?.contains(e.target) && !dropdownButtonRef.current?.contains(e.target) && open) {
        setOpen(false);
        setFocusedIndex(-1);
      }
    },
    [open],
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [open]);

  const handleSelect = useCallback(
    (country) => () => {
      setCountryCode(country.code);
      updateResumeDetail({
        variables: {
          docId,
          path: 'details.countryCode',
          value: country.code,
        },
      });
      setOpen(false);
      setFocusedIndex(-1);
    },
    [setCountryCode],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key.length === 1 && e.key.match(/[a-z]/i)) {
        const newSearchTerm = searchTerm + e.key.toLowerCase();
        setSearchTerm(newSearchTerm);

        if (searchTimeoutRef.current) {
          clearTimeout(searchTimeoutRef.current);
        }

        searchTimeoutRef.current = setTimeout(() => {
          setSearchTerm('');
        }, 500);

        const matchedCountryIndex = countriesOptions.findIndex((country) =>
          country.name.toLowerCase().startsWith(newSearchTerm),
        );
        if (matchedCountryIndex !== -1) {
          setFocusedIndex(matchedCountryIndex);
          const matchedElement = document.getElementById(countriesOptions[matchedCountryIndex].code);
          if (matchedElement) {
            matchedElement.scrollIntoView({ block: 'start' });
          }
        }
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setFocusedIndex((prevIndex) => {
          const newIndex = Math.min(prevIndex + 1, countriesOptions.length - 1);
          const nextElement = document.getElementById(countriesOptions[newIndex].code);
          if (nextElement) {
            nextElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
          }
          return newIndex;
        });
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setFocusedIndex((prevIndex) => {
          const newIndex = Math.max(prevIndex - 1, 0);
          const prevElement = document.getElementById(countriesOptions[newIndex].code);
          if (prevElement) {
            prevElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
          }
          return newIndex;
        });
      } else if (e.key === 'Enter') {
        e.preventDefault();
        if (focusedIndex !== -1) {
          const selectedCountry = countriesOptions[focusedIndex];
          setCountryCode(selectedCountry.code);
          updateResumeDetail({
            variables: {
              docId,
              path: 'details.countryCode',
              value: selectedCountry.code,
            },
          });
          setOpen(false);
          setFocusedIndex(-1);
        }
      }
    },
    [countriesOptions, searchTerm, focusedIndex, setCountryCode],
  );

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  return (
    <Fragment>
      <DropdownButton onClick={() => setOpen(!open)} ref={dropdownButtonRef}>
        <Flag countryCode={countryCode} />
        <ChevronIcon />
      </DropdownButton>
      {open && (
        <Fragment>
          <HiddenInput ref={inputRef} onKeyDown={handleKeyDown} />
          <Dropdown ref={dropdownRef} className="country-dropdown">
            {countriesOptions.map((country, index) => (
              <DropdownOption
                key={country.code}
                id={country.code}
                $active={countryCode === country.code}
                $focused={focusedIndex === index}
                onClick={handleSelect(country)}
                alignItems="center"
              >
                <Flag countryCode={country.code} />
                <CountryName>{country.name}</CountryName>
              </DropdownOption>
            ))}
          </Dropdown>
        </Fragment>
      )}
    </Fragment>
  );
};

CountrySelector.propTypes = {
  countryCode: PropTypes.string,
  setCountryCode: PropTypes.func,
  docId: PropTypes.string,
};

export default CountrySelector;

const HiddenInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
`;

const CountryName = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 14px;
`;

const DropdownOption = styled(Flex)`
  padding: 6px 16px;
  gap: 8px;
  cursor: pointer;
  color: #484870;
  font-size: 15px;
  line-height: 1.2;
  background-color: ${({ $focused }) => ($focused ? '#eaeaea' : 'transparent')};

  &:hover {
    background: #f5f5f5;
  }

  ${({ $active }) =>
    $active &&
    css`
      background: #f5f5f5;
    `};
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: ${(p) => p.theme.general.borderRadius};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 275px;
  height: 300px;
  overflow-y: auto;
  z-index: 101;
  margin-top: 2px;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.gray.regular};
    border: 1px solid white;
    border-radius: 6px;
    &:hover {
      background-color: ${(p) => p.theme.colors.primary};
    }
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 0;
      left: auto;
    `};
`;

const ChevronIcon = styled(Chevron)`
  stroke: #bfbfbf;
  margin-top: 4px;
  transition: all 0.1s;
`;

const FlagWrapper = styled.img`
  width: 18px;
`;

const DropdownButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 6px;
  padding-left: 16px;
  padding-right: 2px;
  cursor: pointer;
  border-radius: ${(p) => p.theme.general.borderRadius};
  &:hover {
    ${ChevronIcon} {
      stroke: #898989;
    }
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 0;
      left: auto;
      padding-left: 2px;
      padding-right: 16px;
    `};
`;
