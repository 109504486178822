import React, { createContext, useContext, useEffect, useState } from 'react';
import ct from 'countries-and-timezones';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import randomWeight from 'random-weight';

import AccountSkeleton from '/imports/dashboard/ui/skeleton/AccountSkeleton';
import CheckoutSkeleton from '/imports/checkout/ui/skeleton/CheckoutSkeleton';
import DashboardSkeleton from '/imports/dashboard/ui/skeleton/DashboardSkeleton';
import env from '/env';
import { getCountry } from '/imports/checkout/api/utils';
import { EXPLICITY_SET_ZAR_CURRENCY, getCurrency, getLocation, getRecordsByBase } from '/lib/helpers';
import { getPlans } from '/imports/checkout/api/helpers';
import intlHook from '/imports/core/api/useIntl';
import LayoutSkeleton from '/imports/job-tracking/ui/skeleton/layoutSkeleton';
import Loading from '/imports/core/ui/components/Loading';
import { useResponsive } from '/imports/core/api/responsiveContext';
import publicIp from 'public-ip';

const isProd = env.NODE_ENV === 'production';

const Loaders = {
  dashboard: <DashboardSkeleton />,
  account: <AccountSkeleton />,
  checkout: <CheckoutSkeleton />,
  layout: <LayoutSkeleton />,
  null: null,
};

const hideModal = 'Hide Modal';
const defaultDomain = 'Domain';
const cardsPaddle = 'Weight Paddle';
const cardsFastSpring = 'Weight Fast Spring';
const cardsSolid = 'Weight Solid';
const cardsStripe = 'Weight Stripe';
const cardsMollie = 'Weight Mollie';
const amexPaddle = 'Weight Amex Paddle';
const amexStripe = 'Weight Amex Stripe';
const amexFastSpring = 'Weight Amex Fast Spring';
const amexMollie = 'Weight Amex Mollie';
const amexSolid = 'Weight Amex Solid';
const mcPaddle = 'Weight MC Paddle';
const mcStripe = 'Weight MC Stripe';
const mcFastSpring = 'Weight MC Fast Spring';
const mcMollie = 'Weight MC Mollie';
const mcSolid = 'Weight MC Solid';
const visaPaddle = 'Weight Visa Paddle';
const visaStripe = 'Weight Visa Stripe';
const visaFastSpring = 'Weight Visa Fast Spring';
const visaMollie = 'Weight Visa Mollie';
const visaSolid = 'Weight Visa Solid';
const countryName = 'Country';
const providerIfHidden = 'Provider if modal hidden';
const showPaymentDisclaimer = 'Show Payment Disclaimer';
const showTermsCheckbox = 'Terms';
const hideOneYearCheckbox = 'Hide 1 year';
const showTrustPilotCheckbox = 'Show Trust Pilot';

const getRule = (allRules, domain) => {
  const { rules = [], defaultRules = [] } = allRules || {};
  const tz = ct.getTimezone(moment.tz.guess(true));
  const { id } = ct.getCountry(tz.countries?.[0]);
  const exception = rules.find((r) => r[countryName] === id && r[defaultDomain] === domain);
  const domainDefaultRules = defaultRules.find((r) => r[defaultDomain] === domain);
  if (exception) return { ...exception, [hideModal]: domainDefaultRules[hideModal] };
  return domainDefaultRules;
};

const getRandomWeight = (weightStripe, weightPaddle, weightFastSpring, weightMollie, weightSolid) => {
  if (!weightPaddle && !weightStripe && !weightFastSpring && !weightMollie && !weightSolid) return 6;
  return randomWeight(
    [
      {
        version: 1, // Stripe
        weight: weightStripe,
      },
      {
        version: 4, // Paddle
        weight: weightPaddle,
      },
      {
        version: 5, // Solid
        weight: weightSolid,
      },
      {
        version: 6, //fastSpring
        weight: weightFastSpring,
      },
      {
        version: 7, //Mollie
        weight: weightMollie,
      },
    ],
    (i) => i.weight,
  ).version;
};

const getBillingVersionByProviderName = (providerName) => {
  switch (providerName) {
    case 'stripe':
      return 1;
    case 'paddle':
      return 4;
    case 'solid':
      return 5;
    case 'mollie':
      return 7;
    case 'fastspring':
    default:
      return 6;
  }
};

const billingVersionByCard = (data, cardType) => {
  const cardsStripeWeight = data[cardsStripe] || 0;
  const cardsPaddleWeight = data[cardsPaddle] || 0;
  const cardsFSWeight = data[cardsFastSpring] || 0;
  const cardsMollieWeight = data[cardsMollie] || 0;
  const cardsSolidWeight = data[cardsSolid] || 0;
  switch (cardType) {
    case 'americanexpress':
      return getRandomWeight(
        data[amexStripe],
        data[amexPaddle],
        data[amexFastSpring],
        data[amexMollie],
        data[amexSolid],
      );
    case 'mastercard':
      return getRandomWeight(data[mcStripe], data[mcPaddle], data[mcFastSpring], data[mcMollie], data[mcSolid]);
    case 'visa':
      return getRandomWeight(
        data[visaStripe],
        data[visaPaddle],
        data[visaFastSpring],
        data[visaMollie],
        data[visaSolid],
      );
    default:
      return getRandomWeight(cardsStripeWeight, cardsPaddleWeight, cardsFSWeight, cardsMollieWeight, cardsSolidWeight);
  }
};

const getBillingVersion = (domain, allRules, cardType, localRule) => {
  // setup stripe for staging
  if (env.NODE_ENV !== 'production') {
    return 5;
  }
  const rule = localRule || getRule(allRules, domain);
  if (rule && rule[hideModal]) {
    const cardsStripeWeight = rule[cardsStripe] || 0;
    const cardsPaddleWeight = rule[cardsPaddle] || 0;
    const cardsFSWeight = rule[cardsFastSpring] || 0;
    const cardsMollieWeight = rule[cardsMollie] || 0;
    const cardsSolidWeight = rule[cardsSolid] || 0;
    if (
      cardsStripeWeight > 0 ||
      cardsPaddleWeight > 0 ||
      cardsFSWeight > 0 ||
      cardsMollieWeight > 0 ||
      cardsSolidWeight > 0
    ) {
      const version = billingVersionByCard(rule, '');
      if (version) return version;
    }
    return getBillingVersionByProviderName(rule[providerIfHidden]);
  }

  if (rule && !rule[hideModal]) {
    const version = billingVersionByCard(rule, cardType);
    if (version) return version;
  }

  return 6;
};
const BillingContext = createContext();

// Provider component that wraps your app and makes the apollo client
// available to any child component that calls useBilling().
export function BillingProvider({
  children,
  value,
  specialPlanId,
  withLoading = true,
  onlyForSpecial = false,
  loadingComponent = null,
  rules,
}) {
  const billing = useBillingProvider(value, specialPlanId, onlyForSpecial, rules);

  if (billing.isLoading) {
    if (withLoading) {
      return loadingComponent ? Loaders[loadingComponent] : <Loading />;
    }
    return null;
  }

  return <BillingContext.Provider value={billing}>{children}</BillingContext.Provider>;
}

function selectUrlBasedOnPercentage(urlsWithPercentages, trustPilotRulesException, host) {
  const country = getCountry();
  if (!urlsWithPercentages) return;
  if (trustPilotRulesException && Array.isArray(trustPilotRulesException)) {
    for (let exception of trustPilotRulesException) {
      if (exception.domain === host && exception.country.map((c) => c.toUpperCase()).includes(country.toUpperCase())) {
        return exception.URL;
      }
    }
  }
  let totalPercentage = urlsWithPercentages.reduce((acc, item) => acc + item.Percentage, 0);
  if (totalPercentage !== 1) {
    return 'https://www.trustpilot.com/review/cv-lite.com';
  }
  let random = Math.random();
  let cumulative = 0;
  for (let i = 0; i < urlsWithPercentages.length; i++) {
    cumulative += urlsWithPercentages[i].Percentage;
    if (random <= cumulative) {
      return urlsWithPercentages[i].URL;
    }
  }
  return urlsWithPercentages[urlsWithPercentages.length - 1].URL;
}

BillingProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
  specialPlanId: PropTypes.string,
  withLoading: PropTypes.bool,
  onlyForSpecial: PropTypes.bool,
  loadingComponent: PropTypes.string,
  rules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

// Provider hook that creates store object and handles state
function useBillingProvider(client, specialPlanId, onlyForSpecial, initRules) {
  const { host } = useResponsive();
  const { locale } = intlHook();
  const [currency, setCurrency] = useState('');
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [trustPilotUrl, setTrustPilotUrl] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(specialPlanId ? specialPlanId : null);
  const [card, setCard] = useState(null);
  const [rule, setRule] = useState(null);
  const [rules, setRules] = useState(initRules);
  const [hideModalPreview, setHideModalPreview] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [hideOneYear, setHideOneYear] = useState(false);
  const [showTrustPilot, setShowTrustPilot] = useState(false);
  const country = getCountry();

  const fetchData = async (IP) => {
    const countryCode = await getLocation(IP || '127.0.0.1');
    const currency = getCurrency(host, countryCode);
    setCurrency(currency);
    return currency;
  };

  useEffect(() => {
    const fetchClientIpAndData = async () => {
      try {
        let fetchedCurrency = getCurrency(host, '');
        setCurrency(fetchedCurrency);
        if (EXPLICITY_SET_ZAR_CURRENCY.includes(host)) {
          const IP = await publicIp.v4();
          fetchedCurrency = await fetchData(IP);
        }
        if (!onlyForSpecial) {
          setIsLoading(true);
          if (typeof window !== 'undefined') {
            let newRule = null;
            if (!trustPilotUrl) {
              const trust_pilot_url = localStorage ? localStorage.getItem('trust_pilot_url') : null;
              if (trust_pilot_url && !trustPilotUrl) setTrustPilotUrl(trust_pilot_url);
            }
            if (!rule) {
              newRule = localStorage ? localStorage.getItem('load_balancing_rule') : null;
              newRule = newRule && newRule !== 'undefined' ? JSON.parse(newRule) : null;
              setRule(newRule);
              if (newRule && (newRule.Domain !== host || newRule.Country !== country)) {
                localStorage.removeItem('load_balancing_rule');
                newRule = null;
              }
            }
            getPlans(fetchedCurrency)
              .then((plans) => {
                setPlans(plans);
                if (!newRule) {
                  getRecordsByBase('Website Default').then((defaultRules) => {
                    getRecordsByBase('Billing Load Balancer Exceptions', `{${'Domain'}} = '${host}'`).then((rules) => {
                      getRecordsByBase('Load Balancing Trust Pilot')
                        .then((trustPilotRules) => {
                          getRecordsByBase('Trust Pilot Balancer Exceptions').then((trustPilotRulesException) => {
                            const rule = getRule({ defaultRules, rules, trustPilotRules }, host);
                            setRules({ rules, trustPilotRules, defaultRules });
                            setRule(rule);
                            localStorage && localStorage.setItem('load_balancing_rule', JSON.stringify(rule));
                            const url = selectUrlBasedOnPercentage(trustPilotRules, trustPilotRulesException, host);
                            localStorage && localStorage.setItem('trust_pilot_url', url);
                            if (url) setTrustPilotUrl(url);
                            setIsLoading(false);
                          });
                        })
                        .catch((err) => {
                          console.log(err);
                          setIsLoading(false);
                        });
                    });
                  });
                } else {
                  setIsLoading(false);
                }
              })
              .catch((err) => {
                console.log(err);
                setIsLoading(false);
              });
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Failed to fetch IP or data', error);
        setIsLoading(false);
      }
    };

    fetchClientIpAndData();
  }, [host, specialPlanId, onlyForSpecial]);

  useEffect(() => {
    if (!!rule) {
      setHideModalPreview(rule ? !!rule[hideModal] : true);
      setShowDisclaimer(rule ? !!rule[showPaymentDisclaimer] : false);
      setShowTerms(rule ? !!rule[showTermsCheckbox] : false);
      setHideOneYear(rule ? !!rule[hideOneYearCheckbox] : false);

      setShowTrustPilot(rule ? isProd && !!rule[showTrustPilotCheckbox] : false);
      setIsLoading(false);
    }
  }, [rule]);

  const billingVersion = getBillingVersion(host, rules, card, rule);

  return {
    isLoading,
    client,
    currency,
    setCurrency,
    plans,
    currentPlan,
    setCurrentPlan,
    billingVersion,
    setSelectedCard: setCard,
    card: hideModalPreview || card,
    showDisclaimer,
    showTerms,
    hideOneYear,
    showTrustPilot,
    trustPilotUrl,
  };
}
// Decorator HOC for legacy class components
// that adds billing context as props.
export const withBilling = (Component) =>
  class Wrapper extends React.Component {
    render() {
      return <BillingContext.Consumer>{(state) => <Component {...this.props} {...state} />}</BillingContext.Consumer>;
    }
  };

// Hook for child components to get the context object
// and re-render when it changes.
const BillingContextComponent = () => useContext(BillingContext);
export default BillingContextComponent;
