import { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import Proptypes from 'prop-types';

import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';
import { CLICK_TO_EDIT_EXP_NEW_VERSION } from '/imports/generator/api/helpers.js';
import {
  redirectToItemField,
  isWithClickToEditVariant,
  triggerRedirectionToDraftEditor,
  redirecToEditView,
  isChildOutsideParentLeftEdge,
} from '/imports/pdf/core/api/helpers';

const ClickToEditComponent = ({
  children,
  blockItemId,
  targetField = '',
  parentWrapperId = '',
  isTextAreaDescription = false,
  hidden = false,
  wrapperProps,
  styledSectionProps,
  buttonProps,
}) => {
  const { isMobile } = useResponsive();
  const { trackEvent } = useTracking();
  const {
    query: { step, resumeId },
    asPath,
    events,
    route,
  } = useRouter();
  const { t, locale } = useIntl();
  const [top, setTop] = useState(0);
  const [disableFeature, setDisableFeature] = useState(false);
  const [isButtonLeft, setIsButtonLeft] = useState(false);
  const buttonRef = useRef(null);

  const isFinalizeView = step === 'finish';
  const isResumeView = route === '/resume';
  const withClickToEdit =
    isResumeView && isWithClickToEditVariant(step) && !isMobile && targetField && !hidden && !disableFeature;
  const isDescriptionDraftField = targetField === 'description' && !isTextAreaDescription;

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setDisableFeature(true);
    };
    const handleRouteChangeComplete = () => {
      setDisableFeature(false);
    };
    events.on('routeChangeStart', handleRouteChangeStart);
    events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      events.off('routeChangeStart', handleRouteChangeStart);
      events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  useEffect(() => {
    if (buttonRef?.current) {
      setTop(buttonRef?.current?.clientHeight);
      const previewWrapper = document.getElementById('resume-preview');
      const overlapLeft = isChildOutsideParentLeftEdge(previewWrapper, buttonRef?.current);
      if (overlapLeft) {
        setIsButtonLeft(overlapLeft);
      }
    }
  });
  const handleClickEditButton = () => {
    trackEvent('click_to_edit_cta', { view: step, click_to_edit_version: CLICK_TO_EDIT_EXP_NEW_VERSION });
    if (!isFinalizeView) {
      const queryMap = {
        resumeId,
        step: 'finish',
        from: asPath,
        language: locale,
        blockItemId,
        targetField,
        parentWrapperId,
        isDraft: isDescriptionDraftField,
      };
      return redirecToEditView(queryMap, locale);
    }
    redirectToItemField({ blockItemId, targetField, parentWrapperId, isDescriptionDraftField });
    if (isDescriptionDraftField) {
      setTimeout(() => {
        triggerRedirectionToDraftEditor(blockItemId);
      }, 0);
    }
  };

  if (!withClickToEdit)
    return (
      <Wrapper {...wrapperProps} className="click-to-edit-wrapper">
        {children}
      </Wrapper>
    );
  return (
    <Wrapper onClick={handleClickEditButton} {...wrapperProps} className="click-to-edit-wrapper">
      <StyledSection {...styledSectionProps} />
      <EditButton ref={buttonRef} $top={top} $isButtonLeft={isButtonLeft} {...buttonProps}>
        {t('click_to_edit_cta')}
      </EditButton>
      {children}
    </Wrapper>
  );
};

const EditRedirectionWrapper = (props) => {
  const { children, wrapperProps } = props;
  if (typeof window === 'undefined')
    return (
      <Wrapper {...wrapperProps} className="click-to-edit-wrapper">
        {children}
      </Wrapper>
    );
  return <ClickToEditComponent {...props} />;
};

const Wrapper = styled.div`
  ${({ $styles }) => $styles}
  position: relative;
  cursor: pointer;
  ${({ $notFullWidth }) =>
    !$notFullWidth &&
    css`
      width: 100%;
    `}
`;
const StyledSection = styled.div`
  z-index: ${({ $zIndex = 0 }) => $zIndex};
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  ${Wrapper}:hover & {
    border: 1px solid #1688fe;
    background: rgb(237, 244, 255, 0.5);
  }
`;

const EditButton = styled.button`
  min-width: 75px;
  display: inline-flex;
  visibility: hidden;
  position: absolute;
  z-index: 200;
  ${({ $isButtonLeft }) => ($isButtonLeft ? `left:0;right:unset;` : `right:0;left:unset;`)}
  ${({ $top, $isBottomDisplay }) =>
    $isBottomDisplay
      ? `bottom: -${$top}px; top: unset;border-radius: 0px 0px 2px 2px;`
      : `top: -${$top}px; bottom: unset;border-radius: 2px 2px 0px 0px;`}

  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border: none;
  background: #1688fe;

  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;

  cursor: pointer;
  ${Wrapper}:hover & {
    visibility: visible;
  }
`;

EditRedirectionWrapper.Proptypes = {
  children: Proptypes.element.isRequired,
  blockItemId: Proptypes.string.isRequired,
  targetField: Proptypes.string,
  parentWrapperId: Proptypes.string,
  isTextAreaDescription: Proptypes.bool,
  hidden: Proptypes.bool,
  wrapperProps: Proptypes.object,
  styledSectionProps: Proptypes.object,
  buttonProps: Proptypes.object,
};

ClickToEditComponent.Proptypes = {
  children: Proptypes.element.isRequired,
  blockItemId: Proptypes.string.isRequired,
  targetField: Proptypes.string,
  parentWrapperId: Proptypes.string,
  isTextAreaDescription: Proptypes.bool,
  hidden: Proptypes.bool,
  wrapperProps: Proptypes.object,
  styledSectionProps: Proptypes.object,
  buttonProps: Proptypes.object,
};
export default EditRedirectionWrapper;
