import React, { PureComponent } from 'react';
import PhotoEdit from './PhotoEdit';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from '/imports/core/ui/atoms/Button';
import { isJobTrackEnable } from '/lib/helpers';
import Modal from '/imports/core/ui/atoms/Modal';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';
import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withWindowSize } from '/imports/core/api/useWindowSize';

const modalStyles = {
  modalContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
  },
  modalBackdrop: {
    backgroundColor: 'rgba(52, 60, 73, 0.5)',
  },
};

@withWindowSize
@withResponsiveContext
@withIntl
class PhotoModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    loading: PropTypes.bool,
    cropSize: PropTypes.number,
    t: PropTypes.func,
    open: PropTypes.bool,
    onlyMobile: PropTypes.string,
    width: PropTypes.number,
  };
  render() {
    const { onClose, onSave, loading, cropSize, t, open, onlyMobile, width } = this.props;
    const isJobTrack = isJobTrackEnable();
    let style = {
      ...modalStyles,
      modalBody: {
        position: 'relative',
        margin: onlyMobile === 'xs' ? 'auto 0 0' : 'auto',
        flexGrow: '0',
        overflowY: 'hidden',
      },
    };
    if (isJobTrack)
      style = {
        ...style,
        modalBody: {
          ...style.modalBody,
          maxWidth: '712px',
          width: `${width <= 768 && width >= 450 ? '90%' : '100%'}`,
        },
      };
    return (
      <Modal
        styles={style}
        onClose={onClose}
        openStateBase
        open={open}
        animation={onlyMobile === 'xs' ? 'fromBottom' : 'empty'}
        timeout={onlyMobile === 'xs' ? 600 : 0}
      >
        <Content>
          <Close onClick={onClose}>
            <SvgIcon.Close />
          </Close>
          <ModalHeader>{t('generator.upload_title')}</ModalHeader>
          <ModalSubheader>{t('generator.upload_heading')}</ModalSubheader>
          <PhotoEdit onSave={onSave} loading={loading} cropSize={cropSize} />
        </Content>
      </Modal>
    );
  }
}

const Content = styled.div`
  width: 680px;
  min-width: 680px;
  background: #fff;
  position: relative;
  border-radius: 6px;
  padding: 36px;
  z-index: ${(p) => p.theme.zIndex.modal};
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      width: 100%;
      min-width: unset;
    `}
  ${({ theme }) => theme.max('xs')`
    overflow: scroll;
    min-width: 100vw;
    width: 100%;
    height: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 20px 20px 85px;
  `}
`;

const Close = styled(Button).attrs((props) => ({
  unstyled: true,
}))`
  position: absolute;
  top: 18px;
  right: 12px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 33px;
  border-radius: 50%;
  padding: 0;
  font-size: 22px;
  margin: 0 !important;
  z-index: 300;

  svg {
    top: 9px;
    left: 9px;
    position: absolute;
    color: #7171a6;
  }

  &:hover {
    svg {
      color: #1688fe;
    }
  }

  ${({ theme }) => theme.max('xs')`
    position: absolute;
  `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: 12px;
      right: auto;
    `}
`;

const ModalHeader = styled.div`
  margin-bottom: 5px;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  line-height: normal;
  color: #33334f;

  ${({ theme }) => theme.max('md')`
    text-align: center;
  `}

  ${({ theme }) => theme.max('xs')`
    max-width: 320px;
    text-align: left;
    font-size: 20px;
    margin-left: 0;
    margin-right: 0;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const ModalSubheader = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #7171a6;
  font-weight: normal;
  margin-bottom: 26px;

  ${({ theme }) => theme.max('md')`
    text-align: center;
  `}

  ${({ theme }) => theme.max('xs')`
    max-width: 320px;
    font-size: 16px;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export default PhotoModal;
