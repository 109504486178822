import React from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import styled from 'styled-components';
import useIntl from 'imports/core/api/useIntl';

import BasicContainer from '/imports/homepageV4/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV4/ui/atoms/BasicSectionWrapper';
import { DISABLE_UPLOAD_RESUME_EXP, staticFile } from '/lib/helpers';
import Flex from '/imports/core/ui/atoms/Flex';
import IconRight from '/imports/homepageV3/ui/atoms/svg/IconRight';
import PrimaryBtn from '/imports/homepageV4/ui/atoms/PrimaryBtn';
import { Push } from '/components/Link';
import StyledH1 from '/imports/homepageV4/ui/atoms/StyledH1';
import StyledImage from '/imports/homepageV4/ui/atoms/StyledImage';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';

const Section1 = ({ userIdentified, onClickLandingCTA }) => {
  const uploadResumeExp = marvelEmitter.getActiveVariant('exp_landing_upload_resume');
  const { t, locale } = useIntl();
  const { trackEvent } = useTracking();
  const { isMobile } = useResponsive();
  const handleRedirect = () => {
    trackEvent('cta_click', { isUploadResume: true });
    Push(`/upload-resume`, locale);
  };

  const RenderCTA = () => {
    if (uploadResumeExp === 'with_upload' || (DISABLE_UPLOAD_RESUME_EXP.includes(locale) && !isMobile)) {
      return (
        <UploadCTAWrapper>
          <ImportButton onClick={handleRedirect} $isUpload>
            <div>{t('landing.uploadcta')}</div>
            <IconRight />
          </ImportButton>
          <PrimaryBtn onClick={onClickLandingCTA}>
            <div>{!userIdentified ? t('landing.cta') : t('landing.my_resumes')}</div>
            <IconRight />
          </PrimaryBtn>
        </UploadCTAWrapper>
      );
    } else {
      return (
        <PrimaryBtn onClick={onClickLandingCTA}>
          <div>{!userIdentified ? t('landing.cta') : t('landing.my_resumes')}</div>
          <IconRight />
        </PrimaryBtn>
      );
    }
  };
  return (
    <SectionWrapper>
      <BasicContainer>
        <HomeHero>
          <HomeHeroHeader>
            <HomeHeroHeaderText>
              <HeroH1>{t('theresumeranger_landing_sec1_h1')}</HeroH1>
              <Text18PX>{t('theresumeranger_landing_sec1_text')}</Text18PX>
            </HomeHeroHeaderText>
            {RenderCTA()}
          </HomeHeroHeader>
          <HomeHeroImages>
            <HomeImageLeft src={staticFile('img/landingv4/6.png')} loading="lazy" alt="" />
            <HomeImageCenter
              onClick={onClickLandingCTA}
              src={staticFile('img/landingv4/3.png')}
              loading="lazy"
              alt=""
            />
            <HomeImageRight src={staticFile('img/landingv4/4.png')} loading="lazy" alt="" />
          </HomeHeroImages>
        </HomeHero>
      </BasicContainer>
    </SectionWrapper>
  );
};

const ImportButton = styled(PrimaryBtn)`
  background-color: #1688fe !important;
  &:hover {
    background-color: #0072e8 !important;
  }
`;
const UploadCTAWrapper = styled(Flex)`
  gap: 12px;
`;

const SectionWrapper = styled(BasicSectionWrapper)`
  padding-top: 72px;
  padding-bottom: 0px;
  background-color: #eff5ff;
  @media (max-width: 479px) {
    padding-top: 48px;
  }
`;
const HomeHero = styled.div`
  max-width: 970px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;
const HomeHeroHeader = styled.div`
  display: flex;
  margin-bottom: 156px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 991px) {
    margin-bottom: 148px;
  }
  @media (max-width: 767px) {
    margin-bottom: 137px;
  }
  @media (max-width: 755px) {
    margin-bottom: min(137px, 21%);
  }
  @media (max-width: 479px) {
    margin-bottom: min(88px, 21%);
  }
`;
const HomeHeroImages = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  grid-column-gap: 100px;
  @media (max-width: 991px) {
    grid-column-gap: 0px;
  }
`;
const HomeHeroHeaderText = styled.div`
  margin-bottom: 24px;
`;
const HeroH1 = styled(StyledH1)`
  margin-top: 0px;
  color: #14141f;
  @media (max-width: 479px) {
    font-size: 32px;
    line-height: 44px;
  }
`;
const Text18PX = styled.div`
  color: #14141f;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`;
const HomeImageLeft = styled(StyledImage)`
  width: 44%;
  @media (max-width: 991px) {
    margin-right: auto;
  }
`;
const HomeImageCenter = styled(StyledImage)`
  cursor: pointer;
  position: absolute;
  bottom: 0%;
  width: 50%;
  border-top: 8px solid rgba(196, 196, 199, 0.5);
  border-right: 8px solid rgba(196, 196, 199, 0.5);
  border-left: 8px solid rgba(196, 196, 199, 0.5);
  border-radius: 24px 24px 0px 0px;
  @media (max-width: 767px) {
    border-top-width: 4px;
    border-right-width: 4px;
    border-left-width: 4px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  @media (max-width: 479px) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;
const HomeImageRight = styled(StyledImage)`
  width: 44%;
  @media (max-width: 991px) {
    margin-left: auto;
  }
`;
export default Section1;
