import React, { PureComponent, Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { rgba } from 'polished';
import styled, { keyframes, css } from 'styled-components';

import Box from '/imports/core/ui/atoms/Box';
import Button from '/imports/core/ui/atoms/Button';
import FlexAtom from '/imports/core/ui/atoms/Flex';
import Image from '/imports/core/ui/atoms/Image';
import { isJobTrackEnable } from '/lib/helpers';
import PhotoModal from '/imports/core/ui/atoms/PhotoModal';
import { UPLOAD_IMAGE, UPLOAD_IMAGE_RESUME } from '/imports/generator/api/apollo/client/mutations';
import { UserDefaultIcon, UserIcon, LockIcon, OutlinedDeleteIcon } from '/imports/generator/ui/assets';
import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withTracking } from '/imports/core/hooks/useTracking';

@withTracking
@withIntl
@withResponsiveContext
@graphql(UPLOAD_IMAGE_RESUME, { name: 'uploadFile' })
@graphql(UPLOAD_IMAGE, { name: 'uploadFileAccount' })
class PhotoUpload extends PureComponent {
  static propTypes = {
    uploadFile: PropTypes.func,
    uploadFileAccount: PropTypes.func,
    disabled: PropTypes.bool,
    onUploaded: PropTypes.func,
    onRemovePhoto: PropTypes.func,
    photoLoaded: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    cropSize: PropTypes.number,
    isGenerator: PropTypes.bool,
    variables: PropTypes.oneOfType([PropTypes.object]),
    account: PropTypes.bool,
    trackEvent: PropTypes.func,
    isCoverLetter: PropTypes.bool,
    t: PropTypes.func,
    name: PropTypes.string,
  };

  state = {
    modalOpen: false,
    loading: false,
  };

  openModal = () => {
    if (this.props.disabled) return;
    this.setState({ modalOpen: true });
  };

  closeModal = () => this.setState({ modalOpen: false });

  onSave = async (image) => {
    const {
      uploadFile,
      onUploaded,
      trackEvent,
      variables: { docId } = {},
      account,
      uploadFileAccount,
      isCoverLetter,
    } = this.props;

    this.setState({ modalOpen: false, loading: true, errors: null });
    if (account || isCoverLetter) {
      const {
        data: { uploadImage },
      } = await uploadFileAccount({
        variables: { image },
      });
      if (uploadImage) {
        onUploaded(uploadImage);
        trackEvent('upload_picture_popup', { path: last(window.location.pathname.split('/')) });
      }
    } else {
      const {
        data: { uploadResumeImage },
      } = await uploadFile({
        variables: { image, resumeId: docId },
      });
      if (uploadResumeImage) {
        onUploaded(uploadResumeImage);
        trackEvent('upload_picture_popup', { path: last(window.location.pathname.split('/')) });
      }
    }
    this.setState({ loading: false });
  };

  renderOverlay = (disabled) => {
    const { loading } = this.state;
    const { value, account } = this.props;

    switch (true) {
      case disabled:
        return <LockIcon />;
      case loading:
        return <Spinner />;
      case !value:
        return account ? <UserDefaultIcon /> : <UserIcon width="25" height="25" />;
      default:
        return null;
    }
  };

  renderControls = (disabled) => {
    const { loading } = this.state;
    const { value, onRemovePhoto, account, t, isGenerator } = this.props;
    const isJobTrack = isJobTrackEnable();
    switch (true) {
      case disabled:
        return <UploadLabel gray>{t('photo_upload')}</UploadLabel>;
      case loading:
        return (
          <UploadLabel gray account={account}>
            {t('loading_photo')}
          </UploadLabel>
        );
      case !isEmpty(value):
        return (
          <DeleteButton link account={account} onClick={onRemovePhoto} type="button">
            <TrashIconContainer>
              <OutlinedDeleteIcon />
            </TrashIconContainer>
            {t('delete')}
          </DeleteButton>
        );
      default:
        if (isJobTrack && !isGenerator) return;
        return <UploadLabel account={account}>{account ? t('change_photo') : t('upload_photo')}</UploadLabel>;
    }
  };

  render() {
    const { modalOpen, loading } = this.state;
    const { disabled, photoLoaded, value, account, cropSize, isGenerator, name } = this.props;

    return (
      <Fragment>
        <Flex
          alignItems="center"
          wrap={account ? 'wrap' : undefined}
          onClick={this.openModal}
          disabled={disabled}
          photoLoaded={photoLoaded}
          loading={loading ? 1 : 0}
          account={account}
          isGenerator={isGenerator}
          {...(name && { name: `interactive-element-clickable-${name}` })}
        >
          <StyledBox>
            <PhotoCont loading={loading ? 1 : 0} disabled={disabled} gotValue={value} account={account}>
              {value && <UploadedImage src={value} account={account} />}
              {this.renderOverlay(disabled)}
            </PhotoCont>
          </StyledBox>
          <Box grow={1}>{this.renderControls(disabled)}</Box>
        </Flex>
        <PhotoModal
          onClose={this.closeModal}
          onSave={this.onSave}
          loading={loading}
          cropSize={cropSize}
          open={modalOpen}
        />
      </Fragment>
    );
  }
}

const StyledBox = styled(Box)`
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      @media (max-width: 767px) {
        justify-content: center;
      }
    `}
`;
const Flex = styled(FlexAtom)`
  margin-top: 12px;
  ${({ theme }) =>
    theme.max('xs')`
    margin-top: 0;
  `}
  ${({ account, theme: { isRTL } }) =>
    account &&
    css`
      width: 140px;
      margin-right: ${isRTL ? 0 : '60px'};
      margin-left: ${isRTL ? '60px' : 0};
      text-align: center;
    `}

  ${(p) =>
    !p.disabled &&
    !p.loading &&
    css`
      cursor: pointer;
      margin-top: 11px;
      &:hover {
        > div {
          > span {
            color: #0b78e8;
          }

          > div {
            background-color: ${rgba('#1688fe', 0.1)};
            color: #1688fe;
          }
        }
      }
    `}

  ${(p) =>
    !p.disabled &&
    !p.loading &&
    !p.photoLoaded &&
    !p.account &&
    css`
      &:hover {
        svg > g > circle,
        path {
          stroke: #1688fe;
        }
      }
    `}

  ${({ isGenerator }) =>
    isGenerator &&
    css`
      * {
        font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
        font-size: 14px;
        font-weight: normal;
      }
    `}
  ${({ theme, account }) =>
    theme.isJobTrack &&
    account &&
    css`
      margin-right: 0;
      width: 100%;
      gap: 10px;
      @media (min-width: 768px) {
        width: 144px;
      }
    `}
`;

const UploadedImage = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: ${(p) => p.theme.general.borderRadius};
  ${(p) =>
    p.account &&
    css`
      border-radius: 100%;
    `}
`;

const UploadLabel = styled.span`
  color: #4c4c55;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};

  text-transform: capitalize;
  ${({ gray, theme }) =>
    gray &&
    css`
      color: ${theme.colors.gray.regular};
    `}

  ${({ account }) =>
    account &&
    css`
      font-size: 13px;
      font-weight: 500;
      line-height: 1.77 !important;
      letter-spacing: 0.7px;
      text-align: center;
      font-style: italic;
    `}
`;

const PhotoCont = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  margin-right: ${({ theme: { isRTL } }) => (isRTL ? '0' : '16px')};
  text-align: center;
  line-height: 62px;
  font-size: 28px;
  border-radius: ${(p) => p.theme.general.borderRadius};
  background-color: #efeff7;
  color: ${(p) => p.theme.colors.gray.regular};
  ${(p) =>
    p.account &&
    css`
      width: 140px;
      height: 140px;
      margin: 0 0 15px;
      border-radius: 100%;
    `}
  ${(p) =>
    p.account &&
    p.theme.isJobTrack &&
    css`
      width: 144px;
      height: 144px;
      margin: 0;
    `}
  ${(p) =>
    p.loading &&
    css`
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        border: 1px solid ${(p) => p.theme.colors.gray.light};
        ${p.gotValue &&
        css`
          background: ${p.theme.colors.black};
          border-color: ${p.theme.colors.black};
        `}
      }
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 0;
      margin-left: 16px;
    `}
`;

const rotate = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const sweep = keyframes`
  0% {
  clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
}
  50% {
  clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
}
  100% {
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
}
`;

const Spinner = styled.div`
  border-radius: 50%;
  box-sizing: border-box;
  animation:
    ${sweep} 1s linear alternate infinite,
    ${rotate} 0.8s linear infinite;
  width: 16px;
  height: 16px;
  border-color: ${(p) => p.theme.colors.gray.light};
  border-style: solid;
  border-width: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TrashIconContainer = styled.div`
  padding-right: 6px;
  font-size: unset;
  color: #1688fe;

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      padding-right: 0;
      padding-left: 6px;
    `}
`;

const DeleteButton = styled(Button)`
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #1688fe;
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  font-size: 13px;
  font-weight: 600;
  ${({ account }) =>
    account &&
    css`
      margin: auto;
      font-size: 11px;
    `}

  &:hover {
    color: ${(p) => p.theme.colors.danger};
    > div {
      color: ${(p) => p.theme.colors.danger};
    }
  }
`;

export default PhotoUpload;
