import React from 'react';
import styled, { css } from 'styled-components';

const EmailIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 256 256"
      xmlSpace="preserve"
    >
      <defs></defs>
      <g
        style={{
          stroke: 'none',
          strokeWidth: 0,
          strokeDasharray: 'none',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1,
        }}
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
      >
        <circle
          cx={45}
          cy={45}
          r={45}
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform="  matrix(1 0 0 1 0 0) "
        />
        <polygon
          points="21.07,31.48 36.59,45.44 21.07,58.53 "
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform="  matrix(1 0 0 1 0 0) "
        />
        <polygon
          points="45,48.97 23.68,29.79 66.32,29.79 "
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform="  matrix(1 0 0 1 0 0) "
        />
        <path
          d="M 38.842 47.465 l 5.155 4.637 c 0.286 0.257 0.645 0.385 1.003 0.385 s 0.718 -0.128 1.003 -0.385 l 5.155 -4.637 l 15.1 12.743 H 23.741 L 38.842 47.465 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <polygon
          points="53.41,45.44 68.93,31.48 68.93,58.53 "
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform="  matrix(1 0 0 1 0 0) "
        />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
  width: ${({ theme }) => theme.convertPx(24)};
  height: ${({ theme }) => theme.convertPx(24)};
  ${({ theme: { convertPx, isRTL, colors, setGenderStyle, color }, isLocation, isPhone }) => css`
    margin-right: ${convertPx(isRTL ? 0 : 5)};
    margin-left: ${convertPx(isRTL ? 5 : 0)};
    ${color &&
    color !== 'black' &&
    css`
      circle{
        fill: ${colors[color]};
       }
      `}
    ${isRTL &&
    css`
      margin-left: ${convertPx(5)};
      margin-right: 0;
    `}
    height: ${convertPx(24)};
    width: ${convertPx(24)};
    path,
    polygon {
      fill: var(--light-values-white);
    }
    circle {
      fill: ${colors[color]};
    }
    ${color &&
    color === 'black' &&
    setGenderStyle('female')`
      background-color: transparent;
      path,polygon {
        fill: #a28848;
      }
      circle{
        fill: transparent;
      }
    `}
    justify-content:center
    align-items: center;
  `}
`;

export default EmailIcon;
