import React, { Component } from 'react';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';

import { getCountry } from '/imports/checkout/api/utils';
import Icon from '/imports/core/ui/atoms/ValidatedInputIcon';
import Input from '/imports/core/ui/atoms/Input';
import { inputStyle, overrideInputStyleMixin } from '/imports/core/ui/mixins';
import { isJobTrackEnable } from '/lib/helpers';
import { UPDATE_AUTOSUGGEST, UPDATE_COVER_LETTER_AUTOSUGGEST } from '/imports/generator/api/apollo/client/mutations';
import { updateOptimisticAutoSuggest } from '/imports/generator/api/apollo/client/helpers';
import withGeneralContext from '/imports/core/api/generalContext';
import { getInputName } from '/imports/generator/api/helpers';
import { withIntl } from '/imports/core/api/useIntl';
import withUseFormContext from '/imports/core/hooks/withUseFormContext';

const setExplicitValue = (targetElement, selector, value) => {
  const cont = Array(3)
    .fill()
    .reduce((res) => res.parentNode.parentNode, targetElement);
  const target = cont.querySelector(selector);

  if (!target) return;

  return (target.value = value);
};

@withUseFormContext
@withIntl
@withRouter
@withGeneralContext
@compose(
  graphql(UPDATE_AUTOSUGGEST, { name: 'updateAutosuggest' }),
  graphql(UPDATE_COVER_LETTER_AUTOSUGGEST, {
    name: 'updateCoverLetterAutosuggest',
  }),
)
class InputGoogleAutocomplete extends Component {
  static propTypes = {
    defaultvalue: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onPlaceSelected: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.string,
    types: PropTypes.array,
    isCompany: PropTypes.bool,
    view: PropTypes.string,
  };

  state = {
    hideIcon: false,
    value: this.props.value || this.props.defaultvalue,
    needSaving: false,
    showSuggestions: false,
    hasError: false,
  };

  //Sometimes we have error on Google maps api load (rare and random)
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  onChange = (value) => {
    const { onChange } = this.props;
    this.setState({
      value,
      needSaving: false,
      isValid: false,
    });
    if (onChange) onChange({ target: { value } });
  };

  handleFocus = () => {
    this.setState({ hideIcon: true, showSuggestions: true });
  };

  handleBlur = () => {
    this.setState({ hideIcon: false, needSaving: false, showSuggestions: false });

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  componentDidUpdate(prevProps) {
    const { value: propsValue } = this.props;
    const { value: prevValue } = prevProps;

    if (prevValue !== propsValue) {
      this.setState({ value: propsValue });
    }
  }
  // This method is debounced due to a race condition between it and the
  // `updateBlockItemField` mutation, especially on the EDUCATION section.
  //
  // If a user clicks a suggestion too fast, it's sent BEFORE the keyboard
  // input, and therefore overwritten by the keyboard input.
  onPlaceSelected = debounce(async (address, placeId) => {
    const {
      router: {
        query: { resumeId, coverLetterId },
      },
      updateCoverLetterAutosuggest,
      updateAutosuggest,
      isCompany,
      updateImmue,
      formMethods: { reset },
    } = this.props;
    const placeData = await geocodeByPlaceId(placeId);
    const place = placeData[0];
    const addressComponents = place.address_components || [];
    const streetNumber = (addressComponents.find((component) => component.types.includes('street_number')) || {})
      .long_name;
    const addressText = `${streetNumber ? streetNumber + ' ' : ''}${
      (addressComponents.find((component) => component.types.includes('route')) || {}).long_name
    }`;
    const zip = (addressComponents.find((component) => component.types.includes('postal_code')) || {}).long_name;
    const city = (
      addressComponents.find(
        (component) => component.types.includes('locality') || component.types.includes('postal_town'),
      ) || {}
    ).long_name;
    const country = (addressComponents.find((component) => component.types.includes('country')) || {}).long_name;
    if (this.props.onPlaceSelected) {
      this.setState({
        value: addressText,
      });

      return this.props.onPlaceSelected({
        address: addressText,
        postalCode: zip,
        country,
        city,
      });
    }

    if (isCompany) {
      return this.setState({ value: addressText }, () => {
        const otherCompanyUpdatedFields = {
          companyPostalCode: zip ? zip.toString() : '',
          companyCity: city ? city : '',
          companyCountry: country ? country : '',
        };
        reset((prev) => ({ ...prev, ...otherCompanyUpdatedFields }), {
          keepValues: false,
          keepDefaultValues: true,
          keepErrors: true,
        });
        return updateCoverLetterAutosuggest({
          variables: {
            id: coverLetterId,
            zip: zip ? zip : null,
            country: country ? country : null,
            city: city ? city : null,
            address: addressText,
            isCompany: isCompany,
          },
          context: {
            client: 'coverLetter',
          },
        });
      });
    }

    return this.setState({ value: addressText }, () => {
      const otherUpdatedFields = {
        postalCode: zip ? zip.toString() : '',
        city: city ? city : '',
        country: country ? country : '',
      };
      reset((prev) => ({ ...prev, ...otherUpdatedFields }), {
        keepValues: false,
        keepDefaultValues: true,
        keepErrors: true,
      });

      if (coverLetterId) {
        return updateCoverLetterAutosuggest({
          variables: {
            id: coverLetterId,
            zip: zip ? zip : null,
            country: country ? country : null,
            city: city ? city : null,
            address: addressText,
          },
          context: {
            client: 'coverLetter',
          },
        });
      }
      const suggestVariables = {
        resumeId,
        zip: zip ? zip : null,
        country: country ? country : null,
        countryCode: null,
        city: city ? city : null,
        address: addressText,
      };

      updateOptimisticAutoSuggest(updateImmue, suggestVariables);
      return updateAutosuggest({ variables: suggestVariables });
    });
  }, 250);

  getWrap = (node) => {
    this.inputWrapper = node;
  };

  errorCallback = (value) => {
    this.setState({ showTomTom: false, value }, () => {
      this.inputWrapper.focus();
    });
  };

  render() {
    const { isValid, showSuggestions, hasError } = this.state;
    const { defaultvalue, value, view, t, BlockGoogleMapCountry = [], name, variables } = this.props;
    const isEmpty = !(value || defaultvalue);
    const placeholder = t('generator_address_placeholder');
    const searchOptions = {
      types: ['address'],
    };
    if (hasError) {
      return <Input {...this.props} placeholder={placeholder} />;
    }
    const country = getCountry();
    if (BlockGoogleMapCountry.includes(country)) return <Input {...this.props} placeholder={placeholder} />;
    // if (env.DISABLE_GOOGLE_API && !env.DISABLE_PLACES_API) return <TomAutoComplete {...this.props} />;
    // if (env.DISABLE_PLACES_API) return <Input {...this.props} />;
    //eslint-disable-next-line
    if (typeof google === 'object' && typeof google.maps === 'object') {
      return (
        <PlacesAutocomplete
          value={this.state.value}
          onChange={this.onChange}
          onSelect={this.onPlaceSelected}
          debounce={500}
          searchOptions={searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <Wrap view={view}>
              <Autocomplete
                {...getInputProps()}
                ref={this.getWrap}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                view={view}
                empty={isEmpty ? 1 : 0}
                inputValid={!isEmpty}
                error={!isValid && this.state.needSaving ? 1 : 0}
                placeholder={placeholder}
                name={getInputName(name, variables)}
              />
              <Icon
                view={view}
                inputValid={!isEmpty}
                empty={isEmpty}
                hide={this.state.hideIcon}
                isJobTrack={isJobTrackEnable()}
              />
              {showSuggestions && suggestions.length > 0 && (
                <SuggestionBox>
                  {suggestions.map((suggestion) => {
                    {
                      if (suggestion.matchedSubstrings.length > 0 && suggestion.matchedSubstrings[0].offset === 0) {
                        let BoldString = suggestion.description.slice(0, suggestion.matchedSubstrings[0].length);
                        let LowerString = suggestion.description.slice(suggestion.matchedSubstrings[0].length);
                        return (
                          <SuggestionInner {...getSuggestionItemProps(suggestion, {})}>
                            <BoldText>{BoldString}</BoldText>
                            <LowerText>{LowerString}</LowerText>
                          </SuggestionInner>
                        );
                      } else {
                        return (
                          <SuggestionInner {...getSuggestionItemProps(suggestion, {})}>
                            <LowerText>{suggestion.description}</LowerText>
                          </SuggestionInner>
                        );
                      }
                    }
                  })}
                </SuggestionBox>
              )}
            </Wrap>
          )}
        </PlacesAutocomplete>
      );
    } else {
      return <Input {...this.props} placeholder={placeholder} />;
    }
  }
}

InputGoogleAutocomplete.displayName = 'InputGoogleAutocomplete';

const BoldText = styled.span`
  font-size: 15px;
  font-weight: bold;
`;
const LowerText = styled.span`
  font-size: 13px;
  font-weight: normal;
  color: #9d9d9d;
`;

const SuggestionInner = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #e6e6e6;
  &:last-child {
    border: none;
  }
  &:hover {
    background-color: #fafafa;
  }
`;

const SuggestionBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  z-index: 999;
  background: #fff;
  padding: 0 2px 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 24px -8px;
`;

const Wrap = styled.div`
  position: relative;
  border-radius: ${(p) => p.theme.general.borderRadius};
  margin-bottom: 5px;
`;

const InputStyle = css`
  ${inputStyle}
  ${overrideInputStyleMixin}
color: #484870;
  box-shadow: none;
  border: 2px solid #e6e6ff;
  border-width: 2px;
  border-style: solid;
  padding: 13px 16px 9px;
  box-shadow: none;
  border-radius: 3px;
  border: 2px solid #e6e6ff;

  ${({ theme }) =>
    theme.designV2 &&
    css`
      height: 48px;
      border-radius: 8px;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding: 13px 16px 13px;
      border: solid 1px #e3e3e4;
      background-color: #ffffff;
      flex-direction: row;
    `}

  :focus {
    border-color: ${({ theme }) => (theme.designV2 ? '#428eff' : '#1688fe')};

    & ~ div {
      visibility: visible;
      transform: rotateY(0);
    }
    ${({ theme }) => css`
      & ~ div {
        border-color: ${theme.colors.primary};
      }
    `};
  }

  &:focus {
    border-color: ${({ theme }) => (theme.designV2 ? '#428eff' : '#1688fe')};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: #e3e9ee !important;
    `};
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const Autocomplete = styled.input`
  ${InputStyle}
  &:focus {
    border-color: ${({ theme }) => (theme.designV2 ? '#428eff' : '#1688fe')};
  }
`;

export default InputGoogleAutocomplete;
