import { PureComponent } from 'react';
import compose from 'lodash.flowright';
import last from 'lodash/last';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { graphql } from 'react-apollo';
import { SPLIT_SKILLS_BLOCK, TEMPLATES_FONTS_ARABIK } from 'imports/pdf/core/api/constants';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';

import {
  ADD_BLOCK_ITEM,
  ADD_COVER_LETTER_BLOCK_ITEM,
  REMOVE_BLOCK,
  REORDER_BLOCK,
  UPDATE_BLOCK_FIELD,
} from '/imports/generator/api/apollo/client/mutations';
import AddBlockButton from '/imports/generator/ui/atoms/AddBlockButton';
import { BLOCK_ADD_ITEM_NAMES, SINGLE_ITEM_BLOCKS } from '/imports/generator/api/constants';
import BlockControls from '/imports/generator/ui/components/BlockControls';
import {
  blockImmutableUpdate,
  blockItemImmutableUpdateSwitch,
  blockRemoveImmutable,
  blockReorderOptimistic,
  updateBlocksAfterItemAdd,
  updateBlocksAfterReorder,
} from '/imports/generator/api/apollo/client/helpers';
import { BLOCKS_MAP } from '/imports/generator/api/form';
import BlockTitleAtom from '/imports/generator/ui/atoms/BlockTitle';
import { apolloClient as client } from '/lib/initApollo';
import ControlsMobileButton from '/imports/generator/ui/atoms/ControlsMobileButton';
import EditableTitle from '/imports/core/ui/atoms/EditableTitle';
import { focusElement, getBlockTranslationSlug } from '/imports/generator/api/helpers';
import { getActiveRepositingVar, getExpSkillsTagVars, getPreviewBlocksHeight } from '/lib/helpers';
import Label from '/imports/core/ui/atoms/Label';
import { PlusIcon } from '/imports/generator/ui/assets';
import { ResponsiveConsumer, withResponsiveContext } from '/imports/core/api/responsiveContext';
import SwitchAutosave from '/imports/core/ui/atoms/SwitchAutosave';
import { withBlockResposition } from '/imports/generator/context/blockresposition.context';
import { withConfirm } from '/imports/core/api/confirm';
import withGeneralContext from '/imports/core/api/generalContext';
import { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from '/imports/core/hooks/useTracking';
import Flex from 'imports/core/ui/atoms/Flex';
import withUseFormContext from 'imports/core/hooks/withUseFormContext';

const getBlockAddEvent = (type) => `add_${type ? type.toLowerCase() : 'block'}`;

@withTracking
@withIntl
@withRouter
@withConfirm
@withGeneralContext
@withResponsiveContext
@withUseFormContext
@compose(
  graphql(REORDER_BLOCK, { name: 'reorder' }),
  graphql(REMOVE_BLOCK, { name: 'remove' }),
  graphql(ADD_BLOCK_ITEM, { name: 'addBlockItem' }),
  graphql(ADD_COVER_LETTER_BLOCK_ITEM, { name: 'addCoverLetterBlockItem' }),
  graphql(UPDATE_BLOCK_FIELD, { name: 'updateBlockField' }),
)
class Block extends PureComponent {
  static propTypes = {
    isFormValid: PropTypes.func,
    resumeId: PropTypes.string,
    coverLetterId: PropTypes.string,
    blockId: PropTypes.string,
    reorder: PropTypes.func,
    remove: PropTypes.func,
    source: PropTypes.object,
    block: PropTypes.object,
    confirm: PropTypes.func,
    addBlockItem: PropTypes.func,
    updateBlockField: PropTypes.func,
    id: PropTypes.string,
    noTitle: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isCoverLetter: PropTypes.bool,
    context: PropTypes.object,
    updateImmue: PropTypes.func,
    t: PropTypes.func,
    breakpoint: PropTypes.string,
    addCoverLetterBlockItem: PropTypes.func,
    router: PropTypes.object,
    trackEvent: PropTypes.func,
    language: PropTypes.string,
  };

  state = {
    titleEditMode: false,
    addblockLoading: false,
  };

  toggleEditMode = () => {
    this.setState({ titleEditMode: !this.state.titleEditMode });
  };

  moveUp = () => this.move('UP');

  moveDown = () => this.move('DOWN');

  moveLeft = () => this.move('LEFT');

  moveRight = () => this.move('RIGHT');

  move = (direction) => {
    const {
      resumeId,
      blockId,
      reorder,
      source,
      context: { dispatch, state },
    } = this.props;
    reorder({
      variables: { resumeId, blockId, direction },
      optimisticResponse: blockReorderOptimistic(source, blockId, direction),
      update: updateBlocksAfterReorder(resumeId),
    });
    if (
      marvelEmitter.getActiveVariant('block_reposition_toggle_exp_v2') === 'with_modal' &&
      (!state.isClosed || state.toggle.active)
    ) {
      dispatch({ type: 'UPDATE_STATE', name: 'isOpen', payload: true });
    }
  };

  remove = () => {
    const {
      resumeId,
      blockId,
      remove,
      confirm,
      source,
      t,
      updateImmue,
      context: { state },
    } = this.props;
    let obj = { resumeId, blockId };
    const customBlocks =
      source && source.blocks && source.blocks.length
        ? source.blocks.map((b) => (b.type === 'CUSTOM' ? b.id : null)).filter((b) => b)
        : [];
    const customSkillsBlocks =
      source && source.blocks && source.blocks.length > 0
        ? source.blocks.map((b) => (b.type === 'CUSTOM_SKILLS_CATEGORY' ? b.id : null)).filter((b) => b)
        : [];
    const activeRepositionVariant = getActiveRepositingVar();
    const isMobile = ['xs', 'sm'].includes(this.props.breakpoint);
    if (activeRepositionVariant === 'with_complete_repositioning' && !isMobile) {
      obj = {
        ...obj,
        heights: getPreviewBlocksHeight(customBlocks, customSkillsBlocks),
        variant: 'with_complete_repositioning',
      };
    }
    if (state.toggle.deActive) {
      delete obj.heights;
      delete obj.variant;
    }
    confirm({
      title: t('generator.delete_block_confirm_title'),
      text: t('generator.delete_block_confirm'),
      confirmText: t('account.delete_account_confirm_text'),
      async onConfirm() {
        blockRemoveImmutable(updateImmue)(source, blockId);
        const res = await remove({
          variables: obj,
        });
        updateImmue(res.data.removeBlock);
      },
    });
  };

  updateTitle = (value) => {
    client.writeData({
      data: {
        savingStaus: 'SAVING',
      },
    });
    const { resumeId, blockId, updateBlockField, updateImmue } = this.props;
    blockImmutableUpdate(updateImmue, blockId, 'title', value);
    updateBlockField({
      variables: {
        docId: resumeId,
        blockId,
        field: 'title',
        value,
      },
    });
  };

  updateExpTagsByVariant = () => {
    const exp_tag_variant = getExpSkillsTagVars();
    const { block, resumeId, blockId, updateBlockField, updateImmue } = this.props;
    let value = block.showTagUI;
    if (block.type == 'SKILLS') {
      //check if user already defined showTagUI
      if (block.showTagUI == null) {
        switch (exp_tag_variant) {
          case 'with_tags_default_bar':
            value = false;
            break;
          case 'with_tags_default_tags':
            value = true;
            break;
        }
        if (!value) return;
        updateImmue && blockImmutableUpdate(updateImmue, blockId, 'showTagUI', value);
        updateBlockField({
          variables: {
            docId: resumeId,
            blockId,
            field: 'showTagUI',
            value,
          },
        });
      }
    }
  };
  addBlockItem = async (e) => {
    e.preventDefault();
    const {
      addBlockItem,
      addCoverLetterBlockItem,
      block: { type },
      router: { asPath },
      isFormValid,
      isCoverLetter,
      updateImmue,
      trackEvent,
      formMethods: { trigger, goToError },
    } = this.props;
    let { resumeId, coverLetterId, blockId } = this.props;
    this.setState({
      addblockLoading: true,
    });
    if (isFormValid === false) {
      trigger();
      this.setState({
        addblockLoading: false,
      });
      setTimeout(() => {
        goToError();
      }, 0);
      return;
    }

    const animationKey = shortid.generate();
    trackEvent(getBlockAddEvent(type), {
      context: last(asPath.split('/')),
    });

    if (isCoverLetter) {
      return addCoverLetterBlockItem({
        context: { client: 'coverLetter' },
        variables: { id: coverLetterId, blockId, animationKey },
        update: (cache, res) => {
          if (res.data && res.data.addBlockItem) {
            coverLetterId = res.data.addBlockItem.id;
            blockId = res.data.addBlockItem.blockId;
          }
        },
      }).then(async (res) => {
        if (res.data && res.data.addBlockItem) {
          this.setState({
            addblockLoading: false,
          });
          const {
            addBlockItem: { id: coverLetterId, blockId, items },
          } = res.data;
          await updateBlocksAfterItemAdd(updateImmue)(res);
          const selector = `#${type}`;

          client.writeData({
            data: {
              expandedItem: `${coverLetterId}.${blockId}.${last(items).animationKey}`,
            },
          });

          focusElement(selector);
        }
      });
    }
    addBlockItem({
      variables: {
        resumeId,
        blockId,
        animationKey,
      },
      update: (cache, res) => {
        if (res.data && res.data.addBlockItem) {
          resumeId = res.data.addBlockItem.resumeId;
          blockId = res.data.addBlockItem.blockId;
        }
      },
    }).then(async (res) => {
      if (res.data && res.data.addBlockItem) {
        this.setState({
          addblockLoading: false,
        });
        const {
          addBlockItem: { resumeId, blockId, items },
        } = res.data;
        await updateBlocksAfterItemAdd(updateImmue)(res);

        const selector = `#${type}`;

        client.writeData({
          data: {
            expandedItem: `${resumeId}.${blockId}.${last(items).animationKey}`,
          },
        });

        focusElement(selector);
        this.setState({ isLoading: false });
      }
    });
  };

  renderSwitch = (label, name) => {
    const { resumeId, blockId, block, updateImmue, language } = this.props;

    return (
      <SwitchAutosave
        language={language}
        key={`switch-${name}`}
        mutation={UPDATE_BLOCK_FIELD}
        variables={{
          docId: resumeId,
          blockId,
          field: name,
          needUpdate: true,
        }}
        label={label}
        value={block[name]}
        name={name}
        optimisticResponse={blockItemImmutableUpdateSwitch(updateImmue)(resumeId, blockId, name)}
      />
    );
  };

  renderBlockControls = (isMobile) => {
    const { block, source } = this.props;
    const hideControls = block.required && block.fixedPosition;
    if (hideControls) return null;

    if (isMobile) {
      return (
        <BlockControlsCont isMobile>
          <ControlsMobileButton
            block={block}
            resume={source}
            toggleEditMode={this.toggleEditMode}
            moveUp={this.moveUp}
            moveDown={this.moveDown}
            moveLeft={this.moveLeft}
            moveRight={this.moveRight}
            remove={this.remove}
            titleEditMode={this.state.titleEditMode}
          />
        </BlockControlsCont>
      );
    }
    return (
      <BlockControlsCont>
        <BlockControls
          block={block}
          resume={source}
          toggleEditMode={this.toggleEditMode}
          moveUp={this.moveUp}
          moveDown={this.moveDown}
          moveLeft={this.moveLeft}
          moveRight={this.moveRight}
          remove={this.remove}
          titleEditMode={this.state.titleEditMode}
        />
      </BlockControlsCont>
    );
  };

  render() {
    const {
      id,
      block: { title, type },
      noTitle,
      isCoverLetter,
      t,
      language,
      source,
    } = this.props;

    const { titleEditMode, addblockLoading } = this.state;
    const singleItemBlocks = SINGLE_ITEM_BLOCKS.includes(type);
    const CustomBlocks = ['CUSTOM', ...SPLIT_SKILLS_BLOCK, 'CUSTOM_SKILLS_CATEGORY', 'SKILLS'];
    const isCustom = CustomBlocks.includes(type);
    const isSummary = type === 'PROFESSIONAL_SUMMARY';
    const BLOCK = BLOCKS_MAP;
    const Icon = BLOCK[type]?.icon;
    const blockTitle = !isCustom ? getBlockTranslationSlug(title) : title;
    let customTitle = '';
    switch (type) {
      case 'CUSTOM':
      case 'CUSTOM_SKILLS_CATEGORY':
        customTitle = title === 'Untitled' ? t('untitled') : title;
        break;
      case 'SOFT_SKILLS':
        customTitle = title === 'Soft Skills' ? t('soft_skills') : title;
        break;
      case 'HARD_SKILLS':
        customTitle = title === 'Hard Skills' ? t('hard_skills') : title;
        break;
      case 'SKILLS':
        customTitle = title === 'Skills' ? t('skills') : title;
        break;
    }
    return (
      <ResponsiveConsumer>
        {({ isMobile }) => (
          <BlockCont id={id} data-add-block data-testid="block">
            {!noTitle && (
              <BlockTitle data-testid="block-title" language={language}>
                {Icon && (
                  <IconWrappper>
                    <Icon />
                  </IconWrappper>
                )}
                {!isCustom ? (
                  t(blockTitle)
                ) : (
                  <>
                    <EditableTitle
                      controlled
                      onSave={this.updateTitle}
                      toggleEditable={this.toggleEditMode}
                      editable={titleEditMode}
                      defaultvalue={customTitle}
                      isCustomSection={type === 'CUSTOM'}
                    />
                  </>
                )}{' '}
                {!isCoverLetter && this.renderBlockControls(isMobile)}
              </BlockTitle>
            )}
            <BlockBody isSummary={isSummary}>{this.props.children}</BlockBody>
            {!singleItemBlocks && (
              <AddBlockButton
                onClick={this.addBlockItem}
                data-testid="add-block-button"
                blockDisabled={addblockLoading}
              >
                <AddBlockInner>
                  <PlusIcon />
                  <AddBlockTitle language={language}>{t(BLOCK_ADD_ITEM_NAMES[type])}</AddBlockTitle>
                </AddBlockInner>
              </AddBlockButton>
            )}

            {BLOCK[type]?.afterText && (
              <AfterText language={language}>
                {t(BLOCK[type]?.slugAfterText) ? t(BLOCK[type]?.slugAfterText) : BLOCK[type]?.afterText}
              </AfterText>
            )}
            <Flex justifyContent="space-between">
              {(BLOCK[type]?.additionalFlags || []).map((f) => {
                if (
                  f?.condition &&
                  typeof f.condition === 'function' &&
                  f?.condition(this?.props?.block, this.props.source) === false
                ) {
                  return null;
                }
                if (f.template && !f.template.includes(source?.settings?.template)) return null;
                return this.renderSwitch(
                  f.translationSlugAdditionalFlags && t(f.translationSlugAdditionalFlags)
                    ? t(f.translationSlugAdditionalFlags)
                    : f.label,
                  f.name,
                );
              })}
            </Flex>
          </BlockCont>
        )}
      </ResponsiveConsumer>
    );
  }
}

Block.displayName = 'Block';
const BlockCont = styled.div`
  width: 100%;
  padding: 10px 0;
  ${({ theme }) =>
    theme.max('xs')`
    padding: 10px 5px;
  `}
`;

const afterTextCSS = css`
  margin-top: 12px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 13px;
  line-height: normal;
  color: #7171a6;
`;

const AfterText = styled(Label)`
  ${afterTextCSS}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const BlockControlsCont = styled.div`
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-left: 10px;
    `}

  > div {
    font-size: 1.15em;
    color: ${(p) => p.theme.colors.gray.light};
  }
`;

const BlockTitleCSS = css`
  padding: 10px 0;
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  span {
    margin-right: 8px;
  }
`;

const BlockTitle = styled(BlockTitleAtom)`
  display: flex;
  align-items: center;
  ${BlockTitleCSS};
  ${(props) => props.language === 'ar' && 'font-family: ' + TEMPLATES_FONTS_ARABIK.tajawal.fontName}
`;

const BlockBody = styled.div`
  position: relative;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const AddBlockInner = styled.span`
  display: flex;
  align-items: center;
`;

const AddBlockTitle = styled.span`
  margin-top: 2px;
`;

const IconWrappper = styled.div`
  color: #1688fe;
  margin-right: 10px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 0;
      margin-left: 10px;
    `}
`;

export default withBlockResposition(Block);
