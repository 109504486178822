import { isFontUpdate } from '/lib/helpers';
import { TEMPLATES } from '/imports/generator/api/constants';

export const getBasicSettings = (template, language) => {
  const { defaultColor } = TEMPLATES.find((t) => t.id === template) || {};
  let defaultSettings = { template: template || 'budapest', color: defaultColor || 'blue', status: 'start', language };
  const isUpdate = isFontUpdate(language);
  if (isUpdate) defaultSettings.headingFont = 'robotoMono';
  if (template === 'atsresume') {
    defaultSettings.headingFont = 'helvetica';
    defaultSettings.contentFont = 'openSans';
  }
  return {
    settings: defaultSettings,
    isSAResume: true,
  };
};
