import React from 'react';
import styled from 'styled-components';
import useIntl from 'imports/core/api/useIntl';
import { staticFile } from 'lib/helpers';

import BasicContainer from '/imports/homepageV4/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV4/ui/atoms/BasicSectionWrapper';
import StyledH2 from '/imports/homepageV4/ui/atoms/StyledH2';
import StyledImage from '/imports/homepageV4/ui/atoms/StyledImage';
import StyledParagraph from '/imports/homepageV4/ui/atoms/StyledParagraph';

const Section4 = () => {
  const { t } = useIntl();
  const QUALITIES = [
    {
      image: 'rocket.svg',
      headtext: t(`theresumeranger_landing_sec4_quality1_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality1_paragraph`),
    },
    {
      image: 'maker-tool.svg',
      headtext: t(`theresumeranger_landing_sec4_quality2_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality2_paragraph`),
    },
    {
      image: 'design.svg',
      headtext: t(`theresumeranger_landing_sec4_quality3_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality3_paragraph`),
    },
    {
      image: 'best.svg',
      headtext: t(`theresumeranger_landing_sec4_quality4_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality4_paragraph`),
    },
    {
      image: 'quickly.svg',
      headtext: t(`theresumeranger_landing_sec4_quality5_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality5_paragraph`),
    },
    {
      image: 'fillbox.svg',
      headtext: t(`theresumeranger_landing_sec4_quality6_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality6_paragraph`),
    },
    {
      image: 'cv.svg',
      headtext: t(`theresumeranger_landing_sec4_quality7_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality7_paragraph`),
    },
    {
      image: 'resumee.svg',
      headtext: t(`theresumeranger_landing_sec4_quality8_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality8_paragraph`),
    },
    {
      image: 'database.svg',
      headtext: t(`theresumeranger_landing_sec4_quality9_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality9_paragraph`),
    },
    {
      image: 'visible.svg',
      headtext: t(`theresumeranger_landing_sec4_quality10_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality10_paragraph`),
    },
    {
      image: 'shine.svg',
      headtext: t(`theresumeranger_landing_sec4_quality11_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality11_paragraph`),
    },
    {
      image: 'secure.svg',
      headtext: t(`theresumeranger_landing_sec4_quality12_headtext`),
      paragraph: t(`theresumeranger_landing_sec4_quality12_paragraph`),
    },
  ];
  return (
    <SectionContainer>
      <MyrContainer>
        <StandoutWrapper>
          <StandoutH2>{t('theresumeranger_landing_sec4_h3')}</StandoutH2>
          <StandoutParagraph>{t('theresumeranger_landing_sec4_paragraph')}</StandoutParagraph>
        </StandoutWrapper>
        <MyrBlocksWrapper>
          {QUALITIES.map((quality, i) => (
            <MyrBlock key={i}>
              <MyrImage src={staticFile(`img/landingv4/${quality.image}`)} loading="lazy" alt={quality.image} />
              <MyrBlockTextHead>{quality.headtext}</MyrBlockTextHead>
              <MyrParagraph>{quality.paragraph}</MyrParagraph>
            </MyrBlock>
          ))}
        </MyrBlocksWrapper>
      </MyrContainer>
    </SectionContainer>
  );
};
const SectionContainer = styled(BasicSectionWrapper)`
  padding-top: 0;
  padding-bottom: 0;
`;
const MyrContainer = styled(BasicContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-row-gap: 32px;
  @media (max-width: 767px) {
    grid-row-gap: 0;
    > div:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;
const StandoutWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
`;
const StandoutH2 = styled(StyledH2)`
  margin-bottom: 16px;
`;
const StandoutParagraph = styled(StyledParagraph)`
  margin-bottom: 0px;
  color: #3a3a43;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;
const MyrBlocksWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  @media (max-width: 991px) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
  }
  @media (max-width: 767px) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-row-gap: 0;
    grid-column-gap: 0;
    > div:not(:last-child){
        margin: 12px;
      }
    }
  }
  @media (max-width: 479px) {
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
  }
  }
`;
const MyrBlock = styled.div`
  position: relative;
  display: flex;
  padding: 32px 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e3e3e4;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 4px 4px 0 0 #eff5ff;
  text-align: center;
`;
const MyrImage = styled(StyledImage)`
  margin-bottom: 24px;
`;
const MyrBlockTextHead = styled.div`
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`;
const MyrParagraph = styled(StyledParagraph)`
  margin-bottom: 0px;
  color: #3a3a43;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;
export default Section4;
