import Input from './Input';
import PropTypes from 'prop-types';
import Autosave from '/imports/core/ui/components/Autosave';
import React, { useRef } from 'react';
import useIntl from '/imports/core/api/useIntl';

const InputAutosave = (props) => {
  const { t } = useIntl();
  const _input = useRef();
  const getInput = (node) => {
    _input.current = node;
  };
  const { className, type, placeholder, name, value, label, view, placeholderSlug, isCoverLetter, autoFocus } = props;

  return (
    <Autosave {...props}>
      {({ onChange, onBlur }) => (
        <Input
          className={className}
          type={type}
          name={name}
          label={label}
          onChange={onChange}
          onBlur={onBlur}
          defaultvalue={value || ''}
          ref={getInput}
          view={view}
          placeholder={placeholderSlug && t(placeholderSlug) ? t(placeholderSlug) : placeholder}
          isCoverLetter={isCoverLetter}
          autoFocus={autoFocus}
        />
      )}
    </Autosave>
  );
};

InputAutosave.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  isCity: PropTypes.bool,
  isState: PropTypes.bool,
  value: PropTypes.string,
  view: PropTypes.string,
  placeholderSlug: PropTypes.string,
  t: PropTypes.func,
  isCoverLetter: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default InputAutosave;
