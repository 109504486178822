import { isArabic } from 'styles/pdf-fonts/helpers';
import styled, { css } from 'styled-components';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Heading = styled(TextAtom)`
  ${({ theme: { headingFont, convertPx, arabicHeadingFont }, smallKerning, extraSmallKerning, children }) => css`
    font-family: ${isArabic(children) ? arabicHeadingFont('medium') : headingFont('medium')};
    font-weight: 500;
    font-size: ${convertPx(18)};
    line-height: 1em;
    color: var(--black);
    text-transform: uppercase;
    letter-spacing: ${isArabic(children) ? 'normal' : convertPx(3.6)};

    margin-bottom: ${convertPx(15)};
    word-break: break-word;
    page-break-inside: avoid;
    break-inside: avoid-page;
    ${smallKerning &&
    css`
      letter-spacing: ${convertPx(1)};
    `}
    ${extraSmallKerning &&
    css`
      letter-spacing: ${convertPx(0.5)};
    `}
  `};
`;

export default Heading;
