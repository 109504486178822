import React, { PureComponent } from 'react';
import Dropdown from './Dropdown';
import PropTypes from 'prop-types';
import { withTracking } from 'imports/core/hooks/useTracking';

import Autosave from '/imports/core/ui/components/Autosave';

@withTracking
class DropdownAutosave extends PureComponent {
  static propTypes = {
    options: PropTypes.array,
    dark: PropTypes.bool,
    stripe: PropTypes.bool,
    scrollOnKeyPress: PropTypes.bool,
    trackEvent: PropTypes.func,
    onChangeCallback: PropTypes.func,
    value: PropTypes.string,
    preview: PropTypes.any,
    customLabel: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    expandTitle: PropTypes.string,
  };

  onChangeCallback = (onChange) => (e) => {
    const { name, value } = e.target;

    const { trackEvent } = this.props;
    if (name === 'arabicHeadingFont') {
      trackEvent('arabic_heading_font');
    }
    if (name === 'arabicContentFont') {
      trackEvent('arabic_content_font');
    }
    if (name === 'gender') {
      trackEvent('gender', { gender: value });
    }
    onChange(e);
    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(e);
    }
  };

  render() {
    const { options, dark, stripe, value, preview, customLabel, placeholder, name, expandTitle } = this.props;
    return (
      <Autosave {...this.props}>
        {({ onChange }) => (
          <Dropdown
            {...this.props}
            onChange={this.onChangeCallback(onChange)}
            options={options}
            defaultSelected={value || ''}
            dark={dark}
            preview={preview}
            stripe={stripe}
            customLabel={customLabel}
            placeholder={placeholder}
            name={name}
            expandTitle={expandTitle}
          />
        )}
      </Autosave>
    );
  }
}

export default DropdownAutosave;
