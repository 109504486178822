import StyledLink from './StyledLink';
import styled, { css } from 'styled-components';

const PrimaryBtn = styled(StyledLink)`
  cursor: pointer;
  max-width: 100%;
  display: flex;
  max-width: 183px;
  padding: 16px 22px;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 16px;
  border-radius: 8px;
  background-color: #f90;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
  @media (max-width: 479px) {
    justify-content: center;
    max-width: 479px;
    width: 100%;
    height: 56px;
  }
  ${({ $isUpload }) =>
    $isUpload &&
    css`
      max-width: 400px !important;
    `}
  &:hover {
    background-color: #fa8900 !important;
  }
  &:active {
    background-color: #ea7e00 !important;
  }
`;
export default PrimaryBtn;
