import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isArabic } from 'styles/pdf-fonts/helpers';

import { parseDraftText } from '/imports/pdf/core/api/helpers';
import View from '/imports/pdf/core/ui/atoms/View';

const ProfessionalSummary = ({ content, left, font }) => {
  return (
    <SummaryContainer left={left}>
      <Summary>{parseDraftText(content, Summary, font)}</Summary>
    </SummaryContainer>
  );
};

ProfessionalSummary.propTypes = {
  content: PropTypes.string,
  left: PropTypes.bool,
  font: PropTypes.string,
};

const Summary = styled(View)`
  font-family: ${({ theme: { contentFont, arabicContentFont }, children }) =>
    isArabic(children) ? arabicContentFont() : contentFont()};
  word-break: break-word;
  font-size: ${({ theme }) => theme.convertPx(12, true)};
  line-height: 1.46;
  display: block;
`;

const SummaryContainer = styled(View)`
  color: ${(p) => (p.left ? '#e6e7e8' : '#808183')};
  ${({ theme: { color, setGenderStyle }, left }) =>
    color &&
    color === 'black' &&
    left &&
    setGenderStyle('female')`
    color: #bcbec0;
`}
`;

export default ProfessionalSummary;
