import ResumegptLogoV1 from '../assets/ResumegptLogoV1';
import SkipIcon from '../assets/SkipIcon';
import { updateUserExtensionOnboardingOption } from '../api/api.js';

import qs from 'qs';
import styled from 'styled-components';
import { Push } from 'imports/help/ui/components/Link';
import nookies from 'nookies';
import {
  INDIAN_COMPANYS,
  FRANCE_COMPANY,
  GLOBAL_COMPANY,
  UNITED_ARAB_EMIRATES_COMPANY,
  SPANISH_COMPANY,
} from '/imports/extensionOnboarding/api/constant';
import Flex from '/imports/core/ui/atoms/Flex';
import useIntl from '/imports/core/api/useIntl';
import LocaleSelect from 'imports/core/ui/components/LocaleSelect';
import useTracking from 'imports/core/hooks/useTracking';
import { useRouter } from 'next/router';
import { getCountry } from 'imports/checkout/api/utils';
import { useEffect, useState } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { getBrandName } from '/lib/helpers';
import { useResponsive } from '/imports/core/api/responsiveContext';

const HeaderV1 = () => {
  const { t, locale } = useIntl();
  const { trackEvent } = useTracking();
  const { host } = useResponsive();
  const router = useRouter();
  const { query } = router;
  const { token } = nookies.get({});
  const countryCode = getCountry();
  const [company, setCompany] = useState([]);
  const builderVar = marvelEmitter.getActiveVariant('exp_select_cv_newdesignV2_builder');
  useEffect(() => {
    renderCompany();
  }, [query]);

  const renderCompany = () => {
    switch (countryCode) {
      case 'FR':
        return setCompany(FRANCE_COMPANY);
      case 'IN':
        return setCompany(INDIAN_COMPANYS);
      case 'ES':
        return setCompany(SPANISH_COMPANY);
      case 'AE':
        return setCompany(UNITED_ARAB_EMIRATES_COMPANY);
      default:
        return setCompany(GLOBAL_COMPANY);
    }
  };

  const handleClickSkipButton = () => {
    const data = {
      view: 'plateforms',
    };
    if (query.view === 'plateforms') goToOtherWebSite();
    else {
      trackEvent('skip_button_clicked_preonb', { redirected_to: 'website_links' });
      Push(`/extension-onboarding?${qs.stringify(data)}`, locale, '/extension-onboarding/plateforms');
    }
  };

  const handleRedirect = (data) => () => {
    trackEvent('website_url_clicked', { website: data.name });
    window.open(data.url, '_blank');
    updateUserExtensionOnboardingOption(data?.savedOption || data.name, token);
  };

  const goToOtherWebSite = () => {
    trackEvent('skip_button_clicked');
    handleRedirect({ url: getSkipButtonUrlRedirection(), name: 'skipped', savedOption: 'linkedin' })();
  };
  const getSkipButtonUrlRedirection = () => {
    const item = company.find((c) => c.name === 'Linkedin');
    return !!item && item?.url ? item.url : 'https://www.linkedin.com/jobs/search/?position=1&pageNum=0';
  };

  return (
    <Main fullWidth alignItems="center" justifyContent="center">
      <Logo alignItems="center">
        <LogoWrapper>
          <ResumegptLogoV1 />
        </LogoWrapper>
        <BrandLogo> {getBrandName(true, host)}</BrandLogo>
      </Logo>

      <Nav>
        {!builderVar && (
          <TitleHeaderWrapper alignItems="center" justifyContent="center" onClick={handleClickSkipButton}>
            <SkipButton>{t('skip_title')}</SkipButton>
            <Icon>
              <SkipIcon />
            </Icon>
          </TitleHeaderWrapper>
        )}
        <LocaleWrapper>
          <LocaleSelect />
        </LocaleWrapper>
      </Nav>
    </Main>
  );
};

export default HeaderV1;

const Logo = styled(Flex)`
  gap: 12px;
`;
const BrandLogo = styled.span`
  color: #1d2c43;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
`;
const LogoWrapper = styled(Flex)`
  svg {
    height: 24px;
    width: 24px;
  }
  flex-shrink: 0;
`;

const Main = styled(Flex)`
  padding: 24px 0;
  gap: 12px;
  border-bottom: 1px solid var(--grayscale-n200);
  justify-content: space-between;
  height: 68px;
  padding: 12px 40px;
  background: #fff;
`;

const Nav = styled(Flex)`
  gap: 8px;
`;

const TitleHeaderWrapper = styled(Flex)`
  height: 32px;
  padding: 16px;
  gap: 8px;
  border-radius: 28px;
  border: 1px solid #e2e8f1;
  background: #f3f6fa;
  cursor: pointer;
`;

const SkipButton = styled.div`
  color: #4f5e75;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const LocaleWrapper = styled(Flex)`
  height: 32px;
  padding: 4px 12px 4px 16px;
  align-items: center;
  gap: 8px;
`;

const Icon = styled.div`
  margin-top: 5px;
`;
