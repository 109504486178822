import { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import Button from '/imports/core/ui/atoms/Button';
import CheckIcon from '/imports/core/ui/assets/new/CheckIcon';
import { Chevron } from '/imports/generator/ui/assets';
import { ERROR_MESSAGES } from '/imports/core/api/constants';
import InputStripe from '/imports/core/ui/atoms/InputStripe';
import { inputStyle, overrideInputStyleMixin } from '/imports/core/ui/mixins';
import useIntl from '/imports/core/api/useIntl';
import GeneratorInputAutosave from 'imports/generator/ui/atoms/GeneratorInputAutosave';
import { removeAccents } from 'lib/helpers';
import ValidatedInputIcon from '/imports/core/ui/atoms/ValidatedInputIcon';
import { isJobTrackEnable } from '/lib/helpers';
import { debounce } from 'lodash';
import useTracking from 'imports/core/hooks/useTracking';
import { useRouter } from 'next/router';

const DropdownCustom = (props) => {
  const {
    defaultSelected,
    options,
    preview,
    placeholder,
    placeholderSlug,
    lang,
    field,
    isOptionsDynamic,
    getOptions,
    name,
    onSelectedEventName,
    hideArrow,
  } = props;

  const expandedRef = useRef(false);
  const [expanded, setExpanded] = useState(false);
  const [customExpanded, setCustomExpanded] = useState(
    defaultSelected && !options.some((o) => o.value === defaultSelected),
  );
  const [custom, setCustom] = useState(customExpanded ? defaultSelected : '');
  const [hovered, setHovered] = useState(props.selected || '');
  const [selected, setSelected] = useState(
    customExpanded ? 'custom' : props.options.find((o) => o.value === props.defaultSelected) || {},
  );
  const [filteredOptions, setFilteredOptions] = useState(props.options || []);
  const [showInput, setShowInput] = useState(false);
  const [showValidatedIcon, setShowValidatedIcon] = useState(false);
  const [currentOptionsPage, setCurrentOptionsPage] = useState(1);

  const { t, locale } = useIntl();

  const dropdownRef = useRef();
  const dropdownMenuRef = useRef();
  const selectedRef = useRef();
  const titleRef = useRef();
  const { trackEvent } = useTracking();
  const {
    query: { step },
  } = useRouter();
  const jobTitleEvent = new Event('jobtitlechange');

  const chevron = expanded && filteredOptions.length > 0 ? <Chevron.Up /> : <Chevron.Down />;

  const windowClick = () => {
    setExpanded(false);
    document.removeEventListener('click', windowClick);
  };

  const handleSelect = (e, option) => {
    e.preventDefault();
    props.onChange({ target: { value: option.value } });
    setSelected(option);
    setExpanded(false);
    if (isOptionsDynamic) {
      setCustom(option.title);
      if (onSelectedEventName) {
        trackEvent(onSelectedEventName, { item: option.title, view: step });
      }
    } else {
      setCustom('');
    }
    setCustomExpanded(false);
    setShowInput(false);
    if (name === 'title') {
      setTimeout(() => {
        document.dispatchEvent(jobTitleEvent);
      }, 200);
    }
  };

  const handleSelectCustom = () => {
    setSelected('custom');
    setExpanded(false);
    setCustomExpanded(true);
  };

  const onCustomChange = (e) => {
    props.onChange(e);
    setCustom(e.target.value);
  };

  const handleHover = (hovered) => {
    setHovered(hovered);
  };

  const getCustomLabel = () => {
    const { customLabel, customLabelSlug } = props;
    return customLabelSlug && t(customLabelSlug) ? t(customLabelSlug) : customLabel || '+ Enter custom value';
  };

  const title = () => {
    const { lang } = props;
    const title = selected.titleSlug && t(selected.titleSlug, lang) ? t(selected.titleSlug, lang) : selected.title;
    if (custom) {
      return custom || title;
    }
    return (
      title ?? (
        <span ref={titleRef} data-ignoreoutsideclick="true">
          {placeholderSlug && t(placeholderSlug) ? t(placeholderSlug) : placeholder}
        </span>
      )
    );
  };

  const getValue = () => {
    if (custom) {
      return custom;
    }
    const { lang } = props;
    const title = selected.titleSlug && t(selected.titleSlug, lang) ? t(selected.titleSlug, lang) : selected.title;
    return title;
  };

  const handleInput = useCallback(
    debounce(async (e) => {
      onCustomChange(e);
      const query = e.target.value;

      // If options are dynamic, fetch them from the server
      if (isOptionsDynamic && query.length > 0) {
        setCurrentOptionsPage(1);

        //reset the scroll of the dropdown menu
        if (dropdownMenuRef.current) dropdownMenuRef.current.scrollTop = 0;

        const dynamicOptions = await getOptions(query, currentOptionsPage);

        if (dynamicOptions.length > 0) {
          setFilteredOptions(dynamicOptions);
        } else {
          setFilteredOptions([]);
          setSelected('custom');
        }
      } else {
        // If options are static, we use the options provided
        const options = filterOptions(e);
        if (options.length === 0) {
          setSelected('custom');
        }
      }

      setExpanded(true);
      setCustomExpanded(false);
      setShowInput(true);
      setShowValidatedIcon(false);
    }, 400),
    [],
  );
  const showDropdown = async () => {
    if (filteredOptions.length > 0) {
      setExpanded(true);
      return;
    }
    const query = getValue();
    if (isOptionsDynamic && query) {
      const dynamicOptions = await getOptions(query, currentOptionsPage);
      if (dynamicOptions.length > 0) {
        setExpanded(true);
        setFilteredOptions(dynamicOptions);
      }
    }
  };
  useEffect(() => {
    if (showInput) {
      setShowValidatedIcon(false);
      // display dropdown
      showDropdown();
    } else {
      if (custom || selected?.title) setShowValidatedIcon(true);
    }
  }, [showInput]);

  const handleInfiniteScroll = debounce(async (e) => {
    if (isOptionsDynamic) {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight && currentOptionsPage != -1) {
        const query = custom;
        const dynamicOptions = await getOptions(query, currentOptionsPage + 1);
        if (dynamicOptions.length === 0) {
          // No more options to fetch, set currentOptionsPage to -1
          setCurrentOptionsPage(-1);
          return;
        }
        setCurrentOptionsPage((prev) => prev + 1);
        setFilteredOptions([...filteredOptions, ...dynamicOptions]);
      }
    }
  }, 200);
  const handleBlur = (e) => {
    setShowInput(false);
    setExpanded(false);
    if (e.target.value === '') {
      setShowValidatedIcon(false);
    } else {
      setShowValidatedIcon(true);
    }
    if (name === 'title') {
      document.dispatchEvent(jobTitleEvent);
    }
  };

  const onClickSelected = () => {
    setShowInput(true);
  };

  const handleChevronClick = (e) => {
    e.preventDefault(); //prevent the onblur event on iput from firing
    setExpanded((prev) => !prev);
  };

  const dropdownMenu = () => (
    <DropdownMenu
      ref={dropdownMenuRef}
      onScroll={handleInfiniteScroll}
      isOptionsEmpty={!filteredOptions.length}
      expanded={expanded}
      onClick={(e) => e.stopPropagation()}
    >
      {renderOptions()}
    </DropdownMenu>
  );

  const renderOptions = () => {
    return (
      (filteredOptions?.length &&
        filteredOptions.map((option) => (
          <DropdownButton
            key={option.value}
            onMouseEnter={() => handleHover(option.value)}
            onMouseLeave={handleHover}
            onMouseDown={(e) => e.preventDefault()}
            onClick={(e) => handleSelect(e, option)}
            hovered={hovered === option.value}
            preview={preview}
          >
            <CheckIcon />
            {option.titleSlug && t(option.titleSlug, lang) ? t(option.titleSlug, lang) : option.title}
          </DropdownButton>
        ))) ||
      (typeof props.children === 'function' && props.children())
    );
  };

  const filterOptions = (e) => {
    // Here we filter the options according to input value
    if (e.target.value === '') {
      setFilteredOptions(props.options);
      return props.options;
    }
    let valueToCheck = removeAccents(e.target.value);
    valueToCheck = valueToCheck?.toLowerCase();
    let res = valueToCheck
      ? props.options?.filter((value) => {
          let text = (value.titleSlug && t(value.titleSlug, lang)) || value.title;
          text = removeAccents(text);
          text = text?.toLowerCase();
          return text?.includes(valueToCheck);
        })
      : props.options;
    setFilteredOptions(res);
    return res;
  };

  useEffect(() => {
    if (isOptionsDynamic) setCustomExpanded(false);
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.getAttribute('data-ignoreoutsideclick')
      ) {
        if (expandedRef.current === true) {
          closeInput();
        }
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    expandedRef.current = expanded;
  }, [expanded]);

  const closeInput = () => {
    setExpanded(false);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('click', windowClick);
    };
  }, []);
  return (
    <div>
      <DropdownContent ref={dropdownRef} $isOnTop={expanded}>
        {showInput && (
          <InputWrapper id="input-wrapper">
            <GeneratorInputAutosave
              {...props}
              fullWidth
              autoFocus
              hideIcon
              value={getValue()}
              onFocus={handleInput}
              onChange={handleInput}
              onBlur={handleBlur}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  closeInput();
                }
              }}
            />
            {showValidatedIcon ? (
              <ValidatedInputIcon isJobTrack={isJobTrackEnable()} inputValid={true} />
            ) : (
              !hideArrow && <ChevronContainer onMouseDown={handleChevronClick}>{chevron}</ChevronContainer>
            )}
          </InputWrapper>
        )}
        {!showInput && (
          <Selected
            data-ignoreoutsideclick="true"
            name={`interactive-element-clickable-${name}`}
            ref={selectedRef}
            contentLength={title()?.length}
            onClick={onClickSelected}
            huge={preview}
            expanded={expanded}
            $textCursor={hideArrow}
          >
            <StyledTitle>{title()}</StyledTitle>

            {showValidatedIcon ? (
              <ValidatedInputIcon isJobTrack={isJobTrackEnable()} inputValid={true} />
            ) : (
              !hideArrow && <ChevronContainer onMouseDown={handleChevronClick}>{chevron}</ChevronContainer>
            )}
          </Selected>
        )}
        {filteredOptions.length > 0 && dropdownMenu()}
      </DropdownContent>
    </div>
  );
};

const StyledTitle = styled.div`
  overflow: hidden;
`;

const InputWrapper = styled.div`
  width: 100%;
  input {
    padding-right: 40px;
  }
`;

const DropdownContent = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;

  > button {
    font-size: 32px;
  }
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: baseline;
  cursor: pointer;
  z-index: 101;
  ${({ $isOnTop }) =>
    $isOnTop &&
    css`
      z-index: 152;
    `}
`;

const customScrollbarCss = css`
  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.gray.regular};
    border: 4px solid ${(p) => p.theme.colors.gray.lighter};
    border-radius: 6px;
    &:hover {
      background-color: ${(p) => p.theme.colors.primary};
    }
  }
`;

const dropdownCSS = css`
  width: 100%;
  height: 154px;
  padding: 6px 0;
  background-color: var(--light-values-white);
  box-shadow:
    rgba(207, 214, 230, 0.7) 0px 14px 16px -10px,
    rgba(207, 214, 230, 0.12) 0px 20px 40px -8px;
  border: solid 2px #e6e6ff;
  border-top: none;
  border-radius: 0 0 3px 3px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  line-height: 18px;
  letter-spacing: normal;
  color: #3d4047;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  transform-origin: top;
  transform: scaleY(0);
  z-index: 150;
  opacity: 0;
  overflow: auto;

  ${customScrollbarCss}

  ${(p) =>
    p.expanded &&
    css`
      transform: scaleY(1);
      opacity: 1;
    `}
  ${({ expanded }) =>
    expanded &&
    css`
      box-shadow: 0 15px 25px -10px rgba(0, 0, 0, 0.1);
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const DropdownMenu = styled.div`
  ${dropdownCSS}
  ${({ theme }) =>
    theme.min('md') &&
    css`
      top: 48px;
    `}
  max-height: 154px;
  height: auto;
`;

const dropdownButtonCSS = css`
  text-align: left;
  width: 100%;
  display: block;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  color: #484870;
  letter-spacing: normal;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 400;
  padding: 6px 16px;
  background: transparent;
  border: 0;
  svg {
    display: none;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
      text-align: right;
    `}

  &:hover {
    font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
    box-shadow: none;
    color: #1688fe;
    background: #f7f7fc;
    border-radius: 0;
  }

  ${(p) =>
    p.selected &&
    css`
      color: ${p.theme.colors.gray.light};
      ${p.theme.max('xs')`
        color: #1688fe;
        background-color: #f7f7fc;
      `}
    `}
  ${(p) =>
    p.preview &&
    css`
      font-size: 19px;
    `}
  ${({ hovered, theme }) =>
    hovered &&
    css`
      color: ${theme.colors.primary};
      background: ${rgba(theme.colors.primary, 0.1)};
      border-radius: 0;
    `}
`;

const DropdownButton = styled((p) => <Button {...p} />)`
  ${dropdownButtonCSS}
`;

const Wrap = styled.div`
  margin-top: 10px;
  position: relative;
  border-radius: ${(p) => p.theme.general.borderRadius};
`;

const ChevronContainer = styled.span`
  position: absolute;
  right: 10px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: auto;
      left: 10px;
    `}
  svg > path {
    stroke: #1688fe;
  }
`;

const selectedStyle = css`
  ${inputStyle}
  min-height: 46px;
  box-shadow: none;
  ${overrideInputStyleMixin}
  color: #484870;
  border-radius: ${({ expanded, theme }) =>
    theme.designV2 ? (expanded ? '8px 8px 0 0' : '8px') : expanded ? '3px 3px 0 0' : '3px'};
  border: solid 2px #e6e6ff;
  padding: 13px 35px 9px 16px;
  box-shadow: none;
  ${({ theme }) =>
    theme.designV2 &&
    css`
      height: 48px;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding: 13px 16px 13px;
      border: solid 1px #e3e3e4;
      background-color: #ffffff;
      flex-direction: row;
    `}

  span {
    color: ${(p) => p.theme.colors.gray.regular};
  }
`;

const Selected = styled.p`
  ${selectedStyle}
  margin: 0;
  user-select: none;
  cursor: pointer;
  ${({ $textCursor }) =>
    $textCursor &&
    css`
      cursor: text;
    `}
  outline: none;
  white-space: nowrap;
  padding-right: 35px;
  overflow: inherit;
  ${({ contentLength }) =>
    contentLength > 60 &&
    css`
      font-size: 11px;
      line-height: 1;
      padding-bottom: 8px;
    `}
  ${(p) =>
    p.huge &&
    css`
      font-size: 19px;
      cursor: text;
    `}
`;

DropdownCustom.displayName = 'DropdownCustom';

export default DropdownCustom;

DropdownCustom.propTypes = {
  defaultSelected: PropTypes.bool,
  options: PropTypes.array,
  preview: PropTypes.any,
  placeholder: PropTypes.string,
  placeholderSlug: PropTypes.string,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  customLabel: PropTypes.string,
  customLabelSlug: PropTypes.string,
  children: PropTypes.node,
  field: PropTypes.object,
  isOptionsDynamic: PropTypes.bool,
  getOptions: PropTypes.func,
};
