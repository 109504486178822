import React from 'react';

const StarterIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.5735 18.3179L18.4571 24.9839C17.713 25.4298 16.8617 25.6653 15.9942 25.6653C15.1267 25.6653 14.2755 25.4298 13.5314 24.9839L2.42644 18.3179L1.13272 19.0845C0.990592 19.1696 0.872948 19.2901 0.791263 19.4342C0.709578 19.5783 0.666641 19.7411 0.666641 19.9068C0.666641 20.0724 0.709578 20.2352 0.791263 20.3793C0.872948 20.5234 0.990592 20.6439 1.13272 20.729L15.5074 29.3538C15.6562 29.443 15.8265 29.4901 16 29.4901C16.1735 29.4901 16.3437 29.443 16.4925 29.3538L30.8672 20.729C31.0094 20.6439 31.127 20.5234 31.2087 20.3793C31.2904 20.2352 31.3333 20.0724 31.3333 19.9068C31.3333 19.7411 31.2904 19.5783 31.2087 19.4342C31.127 19.2901 31.0094 19.1696 30.8672 19.0845L29.5735 18.3179Z"
        fill="#37B982"
      />
      <path
        d="M30.8672 11.4276L16.4925 2.80279C16.3437 2.71361 16.1735 2.6665 16 2.6665C15.8265 2.6665 15.6562 2.71361 15.5074 2.80279L1.13272 11.4276C0.990592 11.5127 0.872948 11.6332 0.791263 11.7773C0.709578 11.9214 0.666641 12.0842 0.666641 12.2498C0.666641 12.4155 0.709578 12.5783 0.791263 12.7224C0.872948 12.8665 0.990592 12.987 1.13272 13.0721L15.5074 21.6969C15.6562 21.7861 15.8265 21.8332 16 21.8332C16.1735 21.8332 16.3437 21.7861 16.4925 21.6969L30.8672 13.0721C31.0094 12.987 31.127 12.8665 31.2087 12.7224C31.2904 12.5783 31.3333 12.4155 31.3333 12.2498C31.3333 12.0842 31.2904 11.9214 31.2087 11.7773C31.127 11.6332 31.0094 11.5127 30.8672 11.4276Z"
        fill="#0866F5"
      />
    </svg>
  );
};
export default StarterIcon;
