import { useRouter } from 'next/router';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

const OnBoardingProgressV1 = ({ isBot }) => {
  const router = useRouter();
  const { query } = router;
  const [isChecked, setIsChecked] = useState(false);
  const builderAbtest = marvelEmitter.getActiveVariant('exp_select_cv_newdesignV2_builder') === 'variant_B';
  useEffect(() => {
    query.view === 'plateforms' ? setIsChecked(true) : setIsChecked(false);
  }, [query]);
  return (
    <Progress $isBot={isBot}>
      <Bar isCheck isCheckRTL />
      <Bar
        isCheck={builderAbtest ? true : isChecked || query.step === 'start'}
        isCheckRTL={builderAbtest ? true : isChecked || query.step === 'start'}
      />
      <Bar isCheck={isChecked && query.step !== 'start'} isCheckRTL={isChecked && query.step !== 'start'} />
    </Progress>
  );
};

export default OnBoardingProgressV1;

const Progress = styled.div`
  display: flex;
  width: 100vw;
  height: 2px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.gray.light};
  position: absolute;
  top: ${({ $isBot }) => ($isBot ? '40px' : '-22px')};

  margin: 22px 0;
  ${({ theme }) => theme.max('md')`
    display: none;
  `}
  background: transparent;
`;

const Bar = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isCheck', 'isCheckRTL'].includes(prop),
})`
  flex: 1 0 0;
  border-radius: 12px;
  background: #e2e8f1;

  &:first-child {
    margin-left: -15px;
  }

  ${({ isCheck, theme: { isRTL } }) =>
    isCheck &&
    !isRTL &&
    css`
      background: linear-gradient(248deg, #fac05e 5.11%, #ff6f5b 42.39%, #ff9cae 76.13%);
    `}

  ${({ theme: { isRTL }, isCheckRTL }) =>
    isRTL &&
    isCheckRTL &&
    css`
      background: linear-gradient(248deg, #fac05e 5.11%, #ff6f5b 42.39%, #ff9cae 76.13%);
    `}
`;
