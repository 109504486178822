import React from 'react';

const StackIcon = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31 22.2206L17 14.2206C16.8993 14.1631 16.7853 14.1328 16.6693 14.1328C16.5533 14.1328 16.4393 14.1631 16.3386 14.2206L2.33864 22.2206C2.26228 22.2641 2.19527 22.3223 2.14148 22.3917C2.08768 22.4612 2.04815 22.5407 2.02516 22.6255C2.00218 22.7103 1.99619 22.7988 2.00754 22.886C2.01889 22.9731 2.04736 23.0572 2.0913 23.1333C2.1499 23.2365 2.23537 23.322 2.33864 23.3806L16.3386 31.3806C16.4393 31.4381 16.5533 31.4684 16.6693 31.4684C16.7853 31.4684 16.8993 31.4381 17 31.3806L31 23.3806C31.1019 23.3223 31.1867 23.238 31.2456 23.1364C31.3046 23.0348 31.3356 22.9194 31.3356 22.8019C31.3356 22.6845 31.3046 22.5691 31.2456 22.4675C31.1867 22.3658 31.1019 22.2816 31 22.2233V22.2206Z"
        fill="#0050C7"
      />
      <path
        d="M30.9999 16.2211L16.9999 8.22109C16.8992 8.16356 16.7852 8.1333 16.6692 8.1333C16.5532 8.1333 16.4393 8.16356 16.3386 8.22109L2.33855 16.2211C2.23659 16.2794 2.15185 16.3637 2.09291 16.4653C2.03397 16.5669 2.00293 16.6823 2.00293 16.7998C2.00293 16.9172 2.03397 17.0326 2.09291 17.1342C2.15185 17.2358 2.23659 17.3201 2.33855 17.3784L16.3386 25.3784C16.4393 25.4359 16.5532 25.4662 16.6692 25.4662C16.7852 25.4662 16.8992 25.4359 16.9999 25.3784L30.9999 17.3784C31.0759 17.3349 31.1426 17.2769 31.1962 17.2076C31.2497 17.1383 31.2891 17.0592 31.3121 16.9747C31.335 16.8902 31.3411 16.8019 31.33 16.7151C31.3189 16.6282 31.2907 16.5444 31.2472 16.4684C31.1886 16.3651 31.1032 16.2797 30.9999 16.2211Z"
        fill="#378EFF"
      />
      <path
        d="M30.9999 10.2211L16.9999 2.22109C16.8992 2.16356 16.7852 2.1333 16.6692 2.1333C16.5532 2.1333 16.4393 2.16356 16.3386 2.22109L2.33855 10.2211C2.23659 10.2794 2.15185 10.3637 2.09291 10.4653C2.03397 10.5669 2.00293 10.6823 2.00293 10.7998C2.00293 10.9172 2.03397 11.0326 2.09291 11.1342C2.15185 11.2358 2.23659 11.3201 2.33855 11.3784L16.3386 19.3784C16.4393 19.4359 16.5532 19.4662 16.6692 19.4662C16.7852 19.4662 16.8992 19.4359 16.9999 19.3784L30.9999 11.3784C31.1019 11.3201 31.1866 11.2358 31.2455 11.1342C31.3045 11.0326 31.3355 10.9172 31.3355 10.7998C31.3355 10.6823 31.3045 10.5669 31.2455 10.4653C31.1866 10.3637 31.1019 10.2794 30.9999 10.2211Z"
        fill="#5DDFA9"
      />
    </svg>
  );
};
export default StackIcon;
