import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Switch from './Switch';

import Autosave from '/imports/core/ui/components/Autosave';

class SwitchAutosave extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isCoverLetter: PropTypes.bool,
    language: PropTypes.string,
    variables: PropTypes.object,
  };

  render() {
    const { className, name, label, value, isCoverLetter, variables } = this.props;
    return (
      <Autosave {...this.props}>
        {({ onChange }) => (
          <Switch
            language={this.props.language}
            className={className}
            name={name}
            onChange={onChange}
            checked={value}
            label={label}
            isCoverLetter={isCoverLetter}
            variables={variables}
          />
        )}
      </Autosave>
    );
  }
}

export default SwitchAutosave;
