import Link from 'next/link';
import styled from 'styled-components';

import Flex from 'imports/core/ui/atoms/Flex';
import { getRoute, isBlogDomain } from '/lib/helpers';
import intlHook from 'imports/core/api/useIntl';
import { useResponsive } from 'imports/core/api/responsiveContext';

const links = [
  { href: '/contact-us', title: 'Contact us', slug: 'header.contact_us' },
  { href: '/privacy-policy', title: 'Privacy', slug: 'footer.privacy_policy' },
  { href: '/terms-and-conditions', title: 'Terms of service', slug: 'footer.terms_conditions' },
];

const Footer = () => {
  const { t, locale } = intlHook();
  const { host } = useResponsive();
  return (
    <FooterWrapper justifyContent="center" alignItems="center">
      {links.map(({ title, href, slug }) => (
        <FooterLink key={title} href={getRoute(href, locale, false, host, isBlogDomain(host))}>
          {t(slug)}
        </FooterLink>
      ))}
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled(Flex)`
  position: absolute;
  gap: 32px;
  padding: 16px 40px;
  bottom: 0;
  right: 0;
  left: 0;

  @media only screen and (max-width: 1700px) {
    position: relative !important;
  }
`;

const FooterLink = styled((p) => <Link {...p} />)`
  color: #1d2c43;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
`;
