import Heading from './Heading';
import { isArabic } from 'styles/pdf-fonts/helpers';
import styled, { css } from 'styled-components';

const SubHeader = styled(Heading)`
  ${({ theme: { contentFont, arabicContentFont, convertPx, color, colors }, upper, small, children }) => css`
    font-family: ${isArabic(children) ? arabicContentFont('bold') : contentFont('bold')};
    font-size: ${convertPx(small ? 11 : 12, true)};
    line-height: ${convertPx(20)};
    margin-bottom: 0;
    text-transform: ${upper ? 'uppercase' : 'none'};
    color: #636466;
    z-index: 1;
    ${color &&
    color !== 'black' &&
    css`
      color: ${colors[color]};
    `}
  `}
`;

export default SubHeader;
