import React from 'react';

const GroupIcon = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0H1C0.447715 0 0 0.447715 0 1V45C0 45.5523 0.447715 46 1 46H11C11.5523 46 12 45.5523 12 45V1C12 0.447715 11.5523 0 11 0Z"
        fill="#0866F5"
      />
      <path
        opacity="0.8"
        d="M26 0H18C17.4477 0 17 0.447715 17 1V45C17 45.5523 17.4477 46 18 46H26C26.5523 46 27 45.5523 27 45V1C27 0.447715 26.5523 0 26 0Z"
        fill="#37B982"
      />
      <path
        opacity="0.6"
        d="M38 0H33C32.4477 0 32 0.447715 32 1V45C32 45.5523 32.4477 46 33 46H38C38.5523 46 39 45.5523 39 45V1C39 0.447715 38.5523 0 38 0Z"
        fill="#37B982"
      />
      <path
        opacity="0.4"
        d="M45 0C44.7348 0 44.4804 0.105357 44.2929 0.292893C44.1054 0.48043 44 0.734784 44 1V45C44 45.2652 44.1054 45.5196 44.2929 45.7071C44.4804 45.8946 44.7348 46 45 46C45.2652 46 45.5196 45.8946 45.7071 45.7071C45.8946 45.5196 46 45.2652 46 45V1C46 0.734784 45.8946 0.48043 45.7071 0.292893C45.5196 0.105357 45.2652 0 45 0Z"
        fill="#5DDFA9"
      />
    </svg>
  );
};
export default GroupIcon;
