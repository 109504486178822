import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

export const Input = styled.input`
  border-radius: 8px;
  width: 100%;
  border: 1px solid var(--grayscale-n300);
  background: var(--light-values-white);
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  padding: 12px;
  &::placeholder {
    color: var(--grayscale-n400);
  }
`;

export const FieldWrapper = styled(Flex)`
  position: relative;
  width: 100%;
`;

export const Option = styled(Flex)`
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  padding: 16px 12px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--grayscale-n200);
  background: var(--light-values-white);
  text-wrap: balance;
  ${({ label }) =>
    label &&
    css`
      position: absolute;
      top: -6px;
      left: 12px;
      background: var(--light-values-white);
      color: var(--dark-values-dark-regular);
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
    `}
  ${({ checked }) =>
    checked &&
    css`
      color: var(--light-values-white);
      background-color: #1688fe;
    `}
  cursor:pointer;
  ${({ checkedBox, checked }) =>
    checkedBox &&
    css`
      padding: 16px 24px;
      border-radius: 8px;
      border: 1px solid var(--grayscale-n200);
      background: var(--light-values-white);
      ${checked &&
      css`
        border-color: #1688fe;
        color: var(--dark-values-dark-regular);
      `}
    `}
`;

export const Question = styled.div`
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 19px;
  line-height: 28px;
`;

export const ContentWrapper = styled(Flex)`
  gap: 12px;
  margin-top: 24px;
`;

export const OptionWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 8px;
  input[type='radio'] {
    appearance: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(227, 227, 228, 1);
    border-radius: 50%;
    margin-right: 8px;
    background-color: rgba(255, 255, 255, 1);
    vertical-align: middle;
    flex-shrink: 0;
  }
  input[type='radio']:checked {
    border: 2px solid #1688fe;
    margin-right: 8px;
    background-color: #1688fe;
    vertical-align: middle;
    flex-shrink: 0;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 1);
    &::before {
      content: '';
      position: absolute;
      width: 9px;
      height: 9px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  ${({ smallGap, theme }) =>
    smallGap &&
    css`
      ${theme.min('sm')`
      gap: 12px;
      flex-direction: column;
  `}
    `}
`;
