import React from 'react';
import styled from 'styled-components';
import StyledH2 from '../atoms/StyledH2';
import StyledParagraph from '../atoms/StyledParagraph';
import useIntl from 'imports/core/api/useIntl';

import BasicContainer from '/imports/homepageV4/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV4/ui/atoms/BasicSectionWrapper';
import IconRight from '/imports/homepageV3/ui/atoms/svg/IconRight';
import PrimaryBtn from '/imports/homepageV4/ui/atoms/PrimaryBtn';

const Section5 = ({ userIdentified, onClickLandingCTA }) => {
  const { t } = useIntl();
  return (
    <SectionWrapper>
      <BasicContainer>
        <SubWrapper>
          <StandoutWrapper>
            <StandoutH2>{t('theresumeranger_landing_sec5_h4')}</StandoutH2>
            <StandoutParagraph>{t('theresumeranger_landing_sec5_paragraph')}</StandoutParagraph>
          </StandoutWrapper>
          <PrimaryBtn onClick={onClickLandingCTA}>
            <div>{!userIdentified ? t('landing.cta') : t('landing.my_resumes')}</div>
            <IconRight />
          </PrimaryBtn>
        </SubWrapper>
      </BasicContainer>
    </SectionWrapper>
  );
};
const SectionWrapper = styled(BasicSectionWrapper)`
  padding-top: 0;
  padding-bottom: 0;
`;
const SubWrapper = styled.div`
  display: flex;
  padding: 48px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
    > div:not(:last-child){
        margin-bottom: 24px;
      }
    }
  border-radius: 16px;
  background-color: #eff5ff;
`;
const StandoutWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
`;
const StandoutH2 = styled(StyledH2)`
  margin-bottom: 8px;
`;
const StandoutParagraph = styled(StyledParagraph)`
  margin-bottom: 0px;
  color: #3a3a43;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;
export default Section5;
