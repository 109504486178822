import React, { PureComponent, Fragment } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import qs from 'qs';
import styled, { css } from 'styled-components';

import Button from '/imports/core/ui/atoms/Button';
import { CloseIcon } from '/imports/generator/ui/assets';
import ColorDropdown from '/imports/generator/ui/components/ColorDropdown';
import DownloadButtonAtom from '/imports/pdf/core/ui/components/DownloadButton';
import DownloadOptionsDropdown from '/imports/generator/ui/components/DownloadOptionsDropdown';
import { freeAccessForYear, isPaidUser } from '/lib/helpers';
import Link, { Push } from '/components/Link';
import Logo from '/imports/core/ui/components/Logo';
import { ResponsiveConsumer, withResponsiveContext } from '/imports/core/api/responsiveContext';
import Separator from '/imports/core/ui/atoms/Separator';
import SlidersMobileButton from '/imports/generator/ui/atoms/SlidersMobileButton';
import { TEMPLATES, COVER_LETTER_TEMPLATES } from '/imports/generator/api/constants';
import TemplateSelectorDropdown from '/imports/generator/ui/atoms/TemplateSelectorDropdown';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from '/imports/core/hooks/useTracking';
import { withRouter } from 'next/router';

@withTracking
@withResponsiveContext
@withIntl
@withRouter
@withAccount
class PreviewHeader extends PureComponent {
  static propTypes = {
    source: PropTypes.object,
    loading: PropTypes.bool,
    isCoverLetter: PropTypes.bool,
    updateResumeDetail: PropTypes.func,
    updateCoverLetterDetail: PropTypes.func,
  };

  state = {
    fromFinalize: false,
    documentHeight: null,
    variant: marvelEmitter.getActiveVariant('website_resume_experiment'),
    numberOfBulletPointInsideResume: 0,
  };

  back = () => {
    const {
      source: { currentStep, id },
      router: { asPath },
      locale,
    } = this.props;

    localStorage.removeItem('resumedone:from-finalize');
    const queryString = {
      resumeId: id,
      step: currentStep,
    };
    const queryStringCoverLetter = {
      coverLetterId: id,
      view: 'edit',
      from: asPath,
    };
    return currentStep
      ? Push(`/resume?${qs.stringify(queryString)}`, locale, `/resume/${id}/${currentStep}`)
      : Push(`/cover-letter?${qs.stringify(queryStringCoverLetter)}`, locale, `/cover-letter/${id}/finish`);
  };

  componentDidMount(prevProps) {
    const { dotBulletCount, numberBulletCount } = this.getNumberOfBulletPoint();
    this.setState({
      fromFinalize: this.isFromFinalize(),
      unorderedBulletPoint: dotBulletCount,
      orderedBulletPoint: numberBulletCount,
    });

    setTimeout(() => {
      this.setDocumentHeight();
    });
  }

  getNumberOfBulletPoint = () => {
    let dotBulletCount = 0;
    let numberBulletCount = 0;

    this.props?.source?.blocks?.forEach((block) => {
      block?.items?.forEach((item) => {
        let description = item?.fields?.description;

        if (typeof description === 'string') {
          // Try to parse the string to see if it's JSON
          try {
            description = JSON.parse(description);
          } catch (e) {
            // If it's not JSON, continue with string bullet count
          }
        }

        if (typeof description === 'string') {
          // Count bullet points in plain string descriptions
          dotBulletCount += (description.match(/•/g) || []).length;
        } else if (typeof description === 'object' && description?.blocks) {
          // Count bullet points in draft-style descriptions
          description?.blocks.forEach((block) => {
            if (block.type === 'unordered-list-item') {
              dotBulletCount++;
            }
            if (block.type === 'ordered-list-item') {
              numberBulletCount++;
            }
          });
        }
      });
    });

    return { dotBulletCount, numberBulletCount };
  };

  setDocumentHeight = () => {
    const templateNode = document.getElementById('template-page');
    let documentHeight = null;
    if (templateNode) {
      const { offsetHeight } = templateNode;
      documentHeight = offsetHeight;
    }
    return this.setState({
      documentHeight,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { source } = this.props;
    const { source: prevSource } = prevProps;
    if (source && prevSource && source.settings.template !== prevSource.settings.template) this.setDocumentHeight();
  }

  isFromFinalize = () => {
    return localStorage.getItem('resumedone:from-finalize');
  };

  handleGenerateClick = () => {
    const { source: { hasWebsite } = {}, trackEvent } = this.props;
    if (hasWebsite) return;

    trackEvent('create_new_website', {
      pageView: 'preview_view',
    });
  };

  filterTemplateOptionsByRole = (options) => {
    return options.filter((t) => {
      if (t?.role) {
        return t?.role?.includes(this.props?.currentUser?.role);
      }
      if (t?.displayCondition && typeof t.displayCondition === 'function') {
        return t?.displayCondition();
      }
      return true;
    });
  };

  render() {
    const {
      loading,
      source,
      currentUser: { role, freeAccessUntil },
      isCoverLetter,
      updateImmue,
      t,
    } = this.props;
    const { fromFinalize, documentHeight } = this.state;
    const templates = isCoverLetter ? COVER_LETTER_TEMPLATES : TEMPLATES;
    const templateOptions = templates.map(({ name: title, id: value, role, displayCondition }) => ({
      title,
      value,
      role,
      displayCondition,
    }));

    const hasSubscription = isPaidUser(role) || freeAccessForYear(freeAccessUntil);

    const { id, currentStep, hasWebsite } = source || {};

    const qsWebsite = {
      source: id,
    };

    const editWebsite = {
      href: `/sites/${id}`,
    };

    const createWebsite = {
      href: `/onboardingweb?${qs.stringify(qsWebsite)}`,
      as: '/onboardingweb',
    };

    const filteredTemplateOptions = this.filterTemplateOptionsByRole(templateOptions);

    const websiteCTAProps = hasWebsite ? editWebsite : createWebsite;
    const showWebsiteFeature = false; /*variant === 'website'*/
    return (
      <ResponsiveConsumer>
        {({ isMobile }) => (
          <Header>
            {isMobile ? (
              !loading && (
                <SlidersMobileButton
                  source={source}
                  isCoverLetter={isCoverLetter}
                  updateImmue={updateImmue}
                  websiteCTAProps={websiteCTAProps}
                  showWebsiteFeature={showWebsiteFeature}
                />
              )
            ) : (
              <Fragment>
                <HeaderLogo>
                  <Logo path="/dashboard" light />
                </HeaderLogo>
                <HeaderContainer>
                  {!loading && (
                    <TemplateParams>
                      <ColorDropdown
                        selected={source.settings.color}
                        id={source.id}
                        template={source.settings.template}
                        isCoverLetter={isCoverLetter}
                        updateImmue={updateImmue}
                      />
                      <SeparatorHeader />
                      <TemplateSelectorDropdown
                        hideLabel
                        dark
                        needUpdate
                        preview
                        options={filteredTemplateOptions}
                        source={source}
                        isCoverLetter={isCoverLetter}
                        updateImmue={updateImmue}
                      />
                      {fromFinalize && (
                        <DownloadContainer subscribed={hasSubscription}>
                          <DownloadButton
                            source={source}
                            isCoverLetter={isCoverLetter}
                            preview
                            documentHeight={documentHeight}
                            unorderedBulletPoint={this.state.unorderedBulletPoint}
                            orderedBulletPoint={this.state.orderedBulletPoint}
                          />
                          {hasSubscription && <DownloadOptionsDropdown source={source} isCoverLetter={isCoverLetter} />}
                        </DownloadContainer>
                      )}
                    </TemplateParams>
                  )}
                </HeaderContainer>
              </Fragment>
            )}
            {isMobile && fromFinalize && (
              <DownloadContainer subscribed={hasSubscription}>
                <DownloadButton
                  source={source}
                  isCoverLetter={isCoverLetter}
                  unorderedBulletPoint={this.state.unorderedBulletPoint}
                  orderedBulletPoint={this.state.orderedBulletPoint}
                  preview
                  documentHeight={documentHeight}
                />
                {hasSubscription && <DownloadOptionsDropdown source={source} isCoverLetter={isCoverLetter} />}
              </DownloadContainer>
            )}

            <HeaderClose>
              <CloseButton onClick={this.back} data-testid="closeButton">
                <CloseIcon />
              </CloseButton>
            </HeaderClose>
          </Header>
        )}
      </ResponsiveConsumer>
    );
  }
}

const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  background-color: #0e121d;
  color: var(--light-values-white);
  height: 70px;
  flex: 0 0 70px;
  flex-flow: row nowrap;
  padding: 0px 32px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const WebsiteButton = styled.div`
  padding: 11px 24px;
  border-radius: 5px;
  border: solid 2px #1688fe;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.21px;
  text-align: center;
  color: var(--light-values-white);
  cursor: pointer;
  margin-right: 24px;
`;

const HeaderLogo = styled.div`
  flex: 1 1 0;
  align-items: center;
  display: flex;

  svg {
    fill: var(--light-values-white);
  }
`;

const HeaderClose = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
`;

const HeaderContainer = styled.div`
  width: 928px;
  display: flex;
  position: relative;
  z-index: 1;
  flex-flow: row nowrap;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 30px;
    `}
`;

const TemplateParams = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 0;
  flex-flow: row nowrap;

  ${(p) => p.theme.max('lg')`
    justify-content: center;
  `}

  p:hover {
    color: ${({ theme }) => theme.colors.primary};
    i {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  & > div:first-child {
    color: var(--light-values-white);

    p:hover {
      color: var(--light-values-white);
    }

    span {
      border: 2px solid var(--light-values-white);
    }
  }

  & > div:last-child > div {
    p:hover {
      color: var(--light-values-white);
    }

    svg {
      fill: transparent;
      & > path {
        stroke: var(--light-values-white);
      }
    }

    & > div {
      padding: 10px;
      padding-top: 0;
      width: 200px;

      &::-webkit-scrollbar {
        width: 13px;
      }

      &::-webkit-scrollbar-thumb {
        border: 5px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 7px;
        background-color: #35425a;
      }
    }
  }

  & > div:last-child button {
    font-family: ${({ theme }) => theme.font.family.websiteMedium};
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #f0efef;
    padding: 10px;
    border-radius: 3px;

    &:hover {
      background: #2296f3;
    }
  }
`;

const DownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 0;
  margin-left: 22px;
  @media (min-width: 1400px) {
    margin-right: 0;
    margin-left: 80px;
  }

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 22px !important;
      margin-left: 0 !important;
      @media (min-width: 1400px) {
        margin-right: 80px !important;
        margin-left: 0px !important;
      }
    `}

  @media (max-width: 979px) {
    position: absolute;
    left: 0;
    right: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
    display: flex;
    justify-content: center;
    z-index: 9;
  }

  & > div:first-child,
  & button {
    background: #2296f3;
    background-color: #2296f3;

    &:hover,
    &:active {
      background-color: #2296f3;
      border-color: #2296f3;
    }
  }
`;

const DownloadButton = styled(DownloadButtonAtom)`
  line-height: unset;
  font-weight: 600;
  text-align: center;
  z-index: 10;
  padding: 14px 24px;
  font-size: 18px;
  background-color: ${(p) => p.theme.colors.ctaPreview};
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:hover {
    background-color: ${(p) => p.theme.colors.ctaPreviewHover};
    border-color: ${(p) => p.theme.colors.ctaPreviewHover};
  }

  &:active {
    background-color: ${(p) => p.theme.colors.ctaPreviewClick};
    border-color: ${(p) => p.theme.colors.ctaPreviewClick};
  }

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-left: 10px;
    `}
`;

const CloseButton = styled((p) => <Button unstyled {...p} />)`
  color: var(--light-values-white);
  font-size: 24px;
  svg {
    transform: scale(1.3);
  }
  &:hover {
    color: ${(p) => p.theme.colors.primary};
    svg > g > path {
      fill: ${(p) => p.theme.colors.primary};
    }
  }
  ${({ theme }) => theme.max('sm')`
    z-index: 99;
  `}
`;

const SeparatorHeader = styled(Separator)`
  margin-right: 25px;
  margin-left: 0;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 0;
      margin-left: 25px;
    `}
`;

export default PreviewHeader;
