import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { ALL_LANGUAGES, LANGUAGES_CODES, supported } from '/imports/generator/api/constants';
import { getDeviceLanguage, getDefaultLanguage, isBlogDomain } from '/lib/helpers';
import { GlobeIcon, ClearCloseIcon } from '/imports/core/ui/assets';
import Modal from '/imports/core/ui/atoms/Modal';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';

export const modalStyles = {
  modalContainer: {
    display: 'block',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  modalBackdrop: {
    backgroundColor: 'transparent',
  },
  modalBody: {
    maxWidth: '500px',
    background: '#fff',
    margin: 'auto',
    width: '100%',
    height: 'auto',
    position: 'relative',
    overflowY: 'auto',
    marginTop: '5%',
    borderRadius: '10px',
    maxHeight: 'calc(100vh - 200px)',
  },
};

export const languages = [
  {
    label: 'English',
    language: LANGUAGES_CODES.ENGLISH,
    country: 'United States',
    languageLabel: 'English',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'French',
    language: LANGUAGES_CODES.FRENCH,
    country: 'France',
    languageLabel: 'French',
    supported: (host) =>
      supported(
        [
          'bestonlineresume',
          'resume-nation',
          'elegantcv',
          'modeles-cv',
          'cvtoolspro',
          'theresumeranger',
          'resumedone',
          'verycoolcv',
        ],
        host,
      ),
  },
  {
    label: 'Turkish',
    language: LANGUAGES_CODES.TURKISH,
    country: 'Turkey',
    languageLabel: 'Türkçe',
    supported: (host) => !supported(['cv-lite'], host),
  },
  {
    label: 'Spanish',
    language: LANGUAGES_CODES.SPANISH,
    country: 'Mexico',
    languageLabel: 'Español',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Portuguese (Brazil)',
    language: LANGUAGES_CODES.BRAZIL,
    country: 'Brazil',
    languageLabel: 'Português',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Italian',
    language: LANGUAGES_CODES.ITALIAN,
    country: 'Italy',
    languageLabel: 'Italiano',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Indonesian',
    language: LANGUAGES_CODES.INDONESIAN,
    country: 'Indonesia',
    languageLabel: 'Bahasa Indonesia',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Arabic',
    language: LANGUAGES_CODES.MODERN_STANDARD_ARAB,
    country: 'United Arab Emirates',
    languageLabel: 'العربية',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Hungarian',
    language: LANGUAGES_CODES.HUNGARIAN,
    country: 'Hungary',
    languageLabel: 'Magyar',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'German',
    language: LANGUAGES_CODES.GERMAN,
    country: 'Germany',
    languageLabel: 'Deutsch',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Vietnamese',
    language: LANGUAGES_CODES.VIETNAMESE,
    country: 'Vietnam',
    languageLabel: 'Tiếng Việt',
    supported: (host) => !supported(['cv-lite'], host),
  },
  {
    label: 'Polish',
    language: LANGUAGES_CODES.POLISH,
    country: 'Poland',
    languageLabel: 'Polski',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Danish',
    language: LANGUAGES_CODES.DANISH,
    country: 'Denmark',
    languageLabel: 'Dansk',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Swedish',
    language: LANGUAGES_CODES.SWEDISH,
    country: 'Sweden',
    languageLabel: 'Svenska',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Finnish',
    language: LANGUAGES_CODES.FINNISH,
    country: 'Finland',
    languageLabel: 'Suomi',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Norwegian',
    language: LANGUAGES_CODES.NORWEGIAN,
    country: 'Norway',
    languageLabel: 'Norsk',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Dutch',
    language: LANGUAGES_CODES.DUTCH,
    country: 'Netherland',
    languageLabel: 'Nederlands',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Georgian',
    language: LANGUAGES_CODES.GEORGIAN,
    country: 'Georgia',
    languageLabel: 'ქართული',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Czech',
    language: LANGUAGES_CODES.CZECH,
    country: 'Czech',
    languageLabel: 'čeština',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Russian',
    language: LANGUAGES_CODES.RUSSIAN,
    country: 'Russia',
    languageLabel: 'русский',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Greek',
    language: LANGUAGES_CODES.GREEK,
    country: 'Greece',
    languageLabel: 'Ελληνικά',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Thai',
    language: LANGUAGES_CODES.THAI,
    country: 'Thailand',
    languageLabel: 'ไทย',
    supported: (host) => !supported(['cv-lite'], host),
  },
  {
    label: 'Latvian',
    language: LANGUAGES_CODES.LATVIAN,
    country: 'Latvia',
    languageLabel: 'Latvija',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Portuguese (Portugal)',
    language: LANGUAGES_CODES.PORTUGUESE,
    country: 'Portugal',
    languageLabel: 'Português',
    supported: (host) => !supported(['cv-lite'], host),
  },
  {
    label: 'Romanian',
    language: LANGUAGES_CODES.ROMANIAN,
    country: 'Romania',
    languageLabel: 'Română',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Lithuanian',
    language: LANGUAGES_CODES.LITHUANIAN,
    country: 'Lithuania',
    languageLabel: 'lietuvių',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Hebrew',
    language: LANGUAGES_CODES.HEBREW,
    country: 'Israel',
    languageLabel: 'עִברִית',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Malaysian',
    language: LANGUAGES_CODES.MALAYSIAN,
    country: 'Malaysia',
    languageLabel: 'Bahasa Melayu',
    supported: (host) => supported(['all'], host),
  },
  {
    label: 'Estonian',
    language: LANGUAGES_CODES.ESTONIAN,
    country: 'Estonia',
    languageLabel: 'Eesti',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Croatian',
    language: LANGUAGES_CODES.CROATIAN,
    country: 'Croatia',
    languageLabel: 'Hrvatski',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Slovenian',
    language: LANGUAGES_CODES.SLOVENIAN,
    country: 'Slovenia',
    languageLabel: 'Slovenščina',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Serbian',
    language: LANGUAGES_CODES.SERBIAN,
    country: 'Serbia',
    languageLabel: 'Srpski',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Japanese',
    language: LANGUAGES_CODES.JAPANESE,
    country: 'Japan',
    languageLabel: '日本語',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Korean',
    language: LANGUAGES_CODES.KOREAN,
    country: 'Korea',
    languageLabel: '한국어',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Chinese',
    language: LANGUAGES_CODES.CHINESE,
    country: 'China',
    languageLabel: '中文',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Tajik',
    language: LANGUAGES_CODES.TAJIK,
    country: 'Tajikistan',
    languageLabel: 'тоҷикӣ',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Azerbaijani',
    language: LANGUAGES_CODES.AZERBAIJANI,
    country: 'Azerbaijan',
    languageLabel: 'Azərbaycan dili',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Catalan',
    language: LANGUAGES_CODES.CATALAN,
    country: 'Catalonia',
    languageLabel: 'Català',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Kazakh',
    language: LANGUAGES_CODES.KAZAKH,
    country: 'Kazakhstan',
    languageLabel: 'Қазақ тілі',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Hindi',
    language: LANGUAGES_CODES.HINDI,
    country: 'India',
    languageLabel: 'हिन्दी',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Bengali',
    language: LANGUAGES_CODES.BENGALI,
    country: 'Bangladesh',
    languageLabel: 'বাংলা',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Uzbek',
    language: LANGUAGES_CODES.UZBEK,
    country: 'Uzbekistan',
    languageLabel: 'O‘zbekcha',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Bulgarian',
    language: LANGUAGES_CODES.BULGARIAN,
    country: 'Bulgaria',
    languageLabel: 'Български',
    supported: (host) => supported(['all', host]),
  },
  {
    label: 'Filipino',
    language: LANGUAGES_CODES.FILIPINO,
    country: 'Philippines',
    languageLabel: 'filipino',
    supported: (host) => supported(['all', host]),
  },
];

export const prepareLanguageLabelBeforeTranslate = (label) => {
  return label?.toLowerCase()?.replace(/\(|\)/g, '')?.replace(/\s+/g, '.')?.trim();
};

export const LanguageRender = ({ selected, languageDetail, handleSelect }) => {
  const { t } = useIntl();
  return (
    <SuggestionWrapper>
      <LanguageWrapper selected={selected === languageDetail.language} onClick={handleSelect(languageDetail.language)}>
        <LanguageName>{t(`language.${prepareLanguageLabelBeforeTranslate(languageDetail?.label)}`)}</LanguageName>
        <LanguageLabel>{languageDetail.languageLabel}</LanguageLabel>
      </LanguageWrapper>
    </SuggestionWrapper>
  );
};

LanguageRender.propTypes = {
  selected: PropTypes.string,
  languageDetail: PropTypes.object,
  handleSelect: PropTypes.func,
};

const LocaleSelect = ({ isNewMobileDesign }) => {
  const { locale, setLocale, t, setResumeLocale } = useIntl();
  const { asPath, push, query } = useRouter();
  const { isMobile, host } = useResponsive();
  const { trackEvent } = useTracking();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(locale);

  useEffect(() => {
    setSelected(locale);
  }, [locale]);

  const toggleModal = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleSelect = (language) => () => {
    setOpen(!open);
    const defaultLocale = getDefaultLanguage(host);
    if (language === locale) return;

    setSelected(language);
    setLocale(language);
    setResumeLocale(language);

    trackEvent('website_language_change', { language });
    const newAsPath = asPath.replace('/builder', '');
    let newRoute = newAsPath.replace('/builder', '');
    if (locale !== defaultLocale || query.language === defaultLocale) {
      newRoute = newAsPath.substr(locale.length + 1, newAsPath.length);
    }
    if (language !== defaultLocale || query.language === defaultLocale) {
      newRoute = `/${language}${newRoute}`;
    }

    const isBlog = isBlogDomain(host);
    if (isBlog && !newRoute.includes('/builder')) {
      newRoute = `/builder${newRoute}`;
    }
    window.location.href = newRoute || '/';
  };

  const renderLanguage = () => {
    let data = languages
      .filter((l) => l.supported(host))
      .sort((a, b) => (a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0));
    if (getDeviceLanguage() !== 'en') {
      data = sortBy(data, ({ language }) => (language === 'en' ? 0 : 1));
    }
    data = sortBy(data, ({ language }) => (language === getDeviceLanguage() ? 0 : 1));
    return (
      <ChooseLanguage>
        {data.map((languageDetail, index) => (
          <LanguageRender languageDetail={languageDetail} key={index} handleSelect={handleSelect} selected={selected} />
        ))}
      </ChooseLanguage>
    );
  };

  const { title } = ALL_LANGUAGES.find((language) => language.value === selected);
  const { value } = ALL_LANGUAGES.find((language) => language.value === selected);
  const documentStatus = typeof window !== 'undefined';
  const defaultLanguageDomains = ['cvdeboss', 'curriculumlisto'];
  if (defaultLanguageDomains.includes(host)) return null;
  return (
    <Fragment>
      <CTA onClick={toggleModal} type="button" isNewMobileDesign={isNewMobileDesign}>
        <GlobeIcon isNewMobileDesign={isNewMobileDesign} />
        <span>{isMobile ? value.toLocaleUpperCase() : title}</span>
      </CTA>
      {documentStatus && (
        <Modal
          onClose={toggleModal}
          styles={modalStyles}
          isLanguage
          openStateBase
          open={open}
          animation="empty"
          timeout={200}
        >
          <CloseWrapper onClick={toggleModal}>
            <ClearCloseIcon />
          </CloseWrapper>
          <ContentWrapper>
            <ChooseLanguageTitle>{t('header.choose_language')}</ChooseLanguageTitle>
            {renderLanguage()}
          </ContentWrapper>
        </Modal>
      )}
    </Fragment>
  );
};

export const CTA = styled.button`
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 12px 0;
  appearance: none;
  background: transparent;
  border: 0;
  border-bottom: 2px solid transparent;
  ${({ isNew, theme: { isRTL } }) =>
    isNew &&
    isRTL &&
    css`
      direction: rtl;
    `}
  span {
    margin-left: 8px;
    ${({ theme: { isRTL } }) =>
      isRTL &&
      css`
        margin-left: 0;
        margin-right: 8px;
      `}
    font-family: ${({ theme }) => theme.font.family.websiteMedium};
    font-size: 14px;
    font-weight: 500;
    color: #282b32;
    margin-top: 2px;
    ${({ isNewMobileDesign, theme: { isRTL } }) =>
      isNewMobileDesign &&
      css`
        color: #4c4c55;
        font-family: Gilroy Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin: 0 0 0 8px;
        ${isRTL &&
        css`
          margin: 0 8px 0 0;
        `}
      `}
  }
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      padding: 16px;
    `}
  &:hover {
    border-bottom: 2px solid #1688fe;
  }
  ${({ isNew }) =>
    isNew &&
    css`
      padding: 17px 15px;
      justify-content: flex-start;
      margin-top: auto;
      border: none !important;
      span {
        font-family: Gilroy SemiBold;
        font-size: 14px;
        color: #9c9c9c;
      }
      svg {
        path {
          fill: #9c9c9c;
        }
      }
      &:hover {
        span {
          color: #252525;
        }
        svg {
          path {
            fill: #252525;
          }
        }
      }
    `}
`;

const SuggestionWrapper = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  flex: 50%;
  text-transform: capitalize;
  &:nth-child(even) {
    margin-bottom: 28px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${({ theme }) =>
    theme.max('sm')`
    max-width: 47%;
  `}
`;

const LanguageWrapper = styled.div`
  width: 180px;
  padding: 9px 0 9px 14px;
  border-radius: 5px;
  &:hover {
    background-color: #f7f7f7;
    cursor: pointer;
  }
  ${({ selected }) =>
    selected &&
    css`
      border: solid 1px #2a2d34;
    `}

  ${({ theme }) =>
    theme.max('sm')`
    width: auto;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      padding: 9px 14px 9px 0;
    `}
`;

export const ChooseLanguageTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 24px;
  font-weight: 600;
  color: #282b32;
  margin: 0 0 30px 0;
  ${({ theme }) =>
    theme.max('sm')`
    font-size: 16px;
    margin: 30px 0 15px;
  `}
`;

export const ChooseLanguage = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) =>
    theme.max('sm')`
    justify-content: space-between;
  `}
`;

const LanguageName = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #282b32;
`;

const LanguageLabel = styled.div`
  font-size: 13px;
  letter-spacing: 0.4px;
  color: #8d929d;
`;

export const CloseWrapper = styled.div`
  ${({ theme }) => theme.max('sm')`
    position:fixed;
    bottom:10px;
  `}
  display: flex;
  background-color: #f7f7f7;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  bottom: 17px;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;

  svg {
    margin: auto;
  }

  ${({ theme }) =>
    theme.min('sm')`
      padding: ${(p) => (p.theme.isRTL ? '15px 0 0 10px' : '15px 10px 0 0')};
      background-color: transparent;
      float: ${(p) => (p.theme.isRTL ? 'left' : 'right')};
      position: static;
      transform: none;
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      float: left;
    `}
`;

export const ContentWrapper = styled.div`
  padding: 40px;
  ${({ theme }) =>
    theme.max('sm')`
    padding: 30px 20px 75px;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export default LocaleSelect;

LocaleSelect.propTypes = {
  isNewMobileDesign: PropTypes.bool,
};
