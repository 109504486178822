import gql from 'graphql-tag';

import { resumeFull } from '/imports/generator/api/apollo/client/fragments';

export const LIST_RESUMES = gql`
  {
    listResumes {
      ...ResumeFull
    }
  }
  ${resumeFull}
`;

export const LIST_COVER_LETTERS = gql`
  {
    list {
      id
      updatedAt
      createdAt
      dupesCount
      name
      settings {
        template
        color
      }
    }
  }
`;

export const LIST_WEBSITES = gql`
  {
    listResumeSlugsForUser {
      slug
      sourceId
      color
      template
      isPrivate
      updatedAt
      createdAt
    }
  }
`;

export const GET_RESUME_SLUG = gql`
  query GetResumeSlug($sourceId: ID!) {
    getResumeSlug(sourceId: $sourceId) {
      slug
      template
    }
  }
`;
