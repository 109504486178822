import styled from 'styled-components';

import Container from '/imports/homepageV2/ui/atmos/Container';

const BasicSectionWrapper = styled(Container)`
  display: block;
  padding: 48px 32px;
  @media (max-width: 767px) {
    padding: 32px 32px;
  }
  @media (max-width: 479px) {
    padding: 32px 16px;
  }
`;
export default BasicSectionWrapper;
