import { isArabic } from 'styles/pdf-fonts/helpers';
import styled, { css } from 'styled-components';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Heading = styled(TextAtom)`
  ${({ theme: { convertPx, headingFont, arabicHeadingFont, isRTL }, children }) => css`
    font-family: ${isArabic(children) ? arabicHeadingFont('bold') : headingFont('bold')};
    font-weight: 600;
    font-size: ${convertPx(16)};
    color: #333e50;
    text-transform: uppercase;
    margin-bottom: ${convertPx(30)};
    word-break: break-word;
    padding-top: ${convertPx(13)};
    padding-bottom: ${convertPx(12)};
    border-bottom: 1px solid #333e50;
    border-top: 1px solid #333e50;
    text-align: ${isRTL ? 'right' : 'left'};
    ${({ children, isContentArabic }) =>
      (!isContentArabic || !isArabic(children)) &&
      css`
        letter-spacing: ${convertPx(3)};
      `}
    page-break-inside: avoid;
    break-inside: avoid-page;
  `}
`;

export default Heading;
