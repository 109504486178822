import React, { PureComponent } from 'react';
import InputGoogleAutocomplete from './InputGoogleAutocomplete';
import PropTypes from 'prop-types';

import Autosave from '/imports/core/ui/components/Autosave';

class InputGoogleAutocompleteAutosave extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    types: PropTypes.array,
    value: PropTypes.string,
    variables: PropTypes.object,
    isCity: PropTypes.bool,
    view: PropTypes.string,
  };

  state = {
    forcedValue: '',
  };

  componentDidMount() {
    const { isCity, value } = this.props;

    if (isCity && !value) {
      fetch('http://ip-api.com/json/').then((res) => {
        res.json().then((json) => {
          this.setState({ forcedValue: json.city });
        });
      });
    }
  }

  render() {
    const { className, type, placeholder, name, value, types, variables, label, view } = this.props;
    const { forcedValue } = this.state;
    return (
      <Autosave {...this.props}>
        {({ onChange }) => (
          <InputGoogleAutocomplete
            className={className}
            type={type}
            name={name}
            label={label}
            onChange={onChange}
            placeholder={placeholder}
            forcedvalue={forcedValue}
            types={types}
            variables={variables}
            defaultvalue={value || ''}
            view={view}
            {...this.props}
          />
        )}
      </Autosave>
    );
  }
}

export default InputGoogleAutocompleteAutosave;
