import React from 'react';
import useIntl from 'imports/core/api/useIntl';
import styled, { css } from 'styled-components';

import BasicContainer from '/imports/homepageV4/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV4/ui/atoms/BasicSectionWrapper';
import Flex from '/imports/core/ui/atoms/Flex';
import Link from '/components/Link';
import LocaleSelect from '/imports/core/ui/components/LocaleSelect';
import LoginIcon from '/imports/homepageV2/ui/atmos/LoginIcon';
import Logo from '/imports/homepageV4/ui/atoms/svg/Logo';

const Header = ({ userIdentified, onClickLandingCTA }) => {
  const { t } = useIntl();
  return (
    <Nav>
      <NavWrapper>
        <BasicContainer>
          <StyledFlex $justifyContent="space-between" $alignItems="center" $fullWidth>
            <Logo />
            <NavLinksWrapper>
              <LocaleWrapper>
                <LocaleSelect />
              </LocaleWrapper>
              {!userIdentified && (
                <Link href="/login" passHref>
                  <LoginLink>{t('landing.login')}</LoginLink>
                </Link>
              )}
              <GetStartedLink onClick={onClickLandingCTA}>{t('resume_nation_landing_asset_1')}</GetStartedLink>
              {!userIdentified && (
                <LoginWrapper $alignItems="center" $justifyContent="center">
                  <Link href="/login" passHref>
                    <div>
                      <LoginIcon />
                    </div>
                  </Link>
                </LoginWrapper>
              )}
            </NavLinksWrapper>
          </StyledFlex>
        </BasicContainer>
      </NavWrapper>
    </Nav>
  );
};
const LoginWrapper = styled(Flex)`
  width: 40px;
  height: 40px;
  display: none;
  margin-top: 7px;
  @media (max-width: 479px) {
    display: flex;
  }
`;
const Nav = styled.nav`
  display: block;
`;
const StyledFlex = styled(Flex)`
  ${({ theme }) => theme.max('md')`
    align-items: center;
  `}
`;
const NavWrapper = styled(BasicSectionWrapper)`
  padding-top: 16px;
  padding-bottom: 16px;
`;
const NavLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: 80px;
      right: unset;
    `}
  @media (max-width: 479px) {
    justify-content: flex-end;
    gap: 12px;
  }
`;

const LoginLink = styled.div`
  max-width: 100%;
  display: inline-block;
  color: #0050c7;
  text-decoration: none;
  @media (max-width: 479px) {
    display: none;
  }
`;
const GetStartedLink = styled.a`
  cursor: pointer;
  max-width: 100%;
  display: flex;
  max-width: 183px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 16px;
  border-radius: 8px;
  background-color: #f90;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    background-color: #fa8900 !important;
  }
  &:active {
    background-color: #ea7e00 !important;
  }
  @media (max-width: 479px) {
    display: none;
  }
`;
const LocaleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
  @media (max-width: 767px) {
    margin-right: 0px;
  }
`;
export default Header;
