import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import BreakAvoidWrapper from '/imports/pdf/core/ui/atoms/BreakAvoidWrapper';
import TextAtom from '/imports/pdf/budapest/ui/atoms/Text';
import View from '/imports/pdf/core/ui/atoms/View';
import EditRedirectionWrapper from 'imports/pdf/core/ui/components/EditRedirectionWrapper';
import { getClickToEditProps } from 'imports/pdf/core/api/helpers';

const PercentageDisplay = ({ title, percentage, hideStripe, left, fieldsName, itemId }) => (
  <BreakAvoidWrapper>
    <EditRedirectionWrapper {...getClickToEditProps(itemId, { title }, fieldsName)}>
      <Text left={left}>{title}</Text>
      {!hideStripe && (
        <LevelStripe left={left}>
          <Stripe percentage={percentage} left={left} />
        </LevelStripe>
      )}
    </EditRedirectionWrapper>
  </BreakAvoidWrapper>
);

PercentageDisplay.propTypes = {
  title: PropTypes.string,
  percentage: PropTypes.number,
  hideStripe: PropTypes.bool,
  left: PropTypes.bool,
};

export const Text = styled(TextAtom)`
  ${({ theme, left }) => css`
    font-size: ${theme.convertPx(10, true)};
    text-transform: uppercase;
    margin-bottom: ${theme.convertPx(2)};
    ${left &&
    css`
      color: var(--light-values-white);
      ${theme.color &&
      theme.color === 'black' &&
      theme.setGenderStyle('female')`
            color: #bcbec0;
        `}
    `}
  `}
`;

const LevelStripe = styled(View)`
  position: relative;
  ${({ theme, left }) => css`
    width: 100%;
    border-bottom: ${({ theme }) => theme.convertPx(4)} solid ${left ? '#ffffff4d' : '#0000001a'};
    margin-top: ${theme.convertPx(left ? 0 : 3)};
  `}
`;

const Stripe = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(p) => p.percentage}%;
  height: 100%;
  border-bottom: ${({ theme }) => theme.convertPx(4)} solid #636466;
  ${({ theme: { setGenderStyle, color } }) =>
    color &&
    color === 'black' &&
    setGenderStyle('female')`
  border-bottom: ${({ theme }) => theme.convertPx(4)} solid #a28848;
  `}
  ${({ theme: { color }, theme }) =>
    color &&
    color !== 'black' &&
    css`
      border-bottom: ${({ theme }) => theme.convertPx(4)} solid ${theme.colors[color]};
    `}

  ${({ left, theme: { setGenderStyle, color } }) =>
    left &&
    css`
      border-bottom: ${({ theme }) => theme.convertPx(4)} solid var(--light-values-white);
      ${color === 'black' &&
      setGenderStyle('female')`
      border-bottom: ${({ theme }) => theme.convertPx(4)} solid #a28848;
      `}
    `}
    ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 0;
    `}
`;

export default PercentageDisplay;
