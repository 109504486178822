import PencilIcon from '../assets/PencilIcon';
import { useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import styled, { css } from 'styled-components';
import AutoSuggest from 'react-autosuggest';
import { listAgentUser } from 'imports/job-tracking/api/api';
import Flex from '/imports/core/ui/atoms/Flex';
import { setCookie, parseCookies } from 'nookies';

const AgentUsers = () => {
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [defaultSuggestion, setDefaultSuggestion] = useState([]);
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(false);
  const searchRef = useRef();
  const cookies = parseCookies();
  const [toggle, setToggle] = useState(false);
  const agent_client_id = cookies.agent_client_id;

  const fetchSearchData = async (searchData) => {
    try {
      const resp = await listAgentUser({ searchText: searchData });
      const { users = [] } = resp;
      const data = users.map((item) => {
        return { ...item, value: item?.email, name: item?.name };
      });
      setDefaultSuggestion(data);
      setUserSuggestions(data);
    } catch (error) {
      console.error('Error fetching search data:', error);
    }
  };

  const setUserInfo = () => {
    const agentClientName = cookies?.agent_client_name;
    if (agentClientName) {
      setSearch(agentClientName);
    }
  };

  useEffect(() => {
    fetchSearchData(null);
    setUserInfo();
  }, []);

  const debounceSearch = useRef(debounce(fetchSearchData, 200)).current;

  const getSuggestionValue = (suggestion) => suggestion?.name;

  const handleSearchSuggestion = (event, { suggestion }) => {
    setSearch(suggestion?.name);
    setToggle(true);
    setCookie({}, 'agent_client_name', suggestion?.name, {
      maxAge: 24 * 60 * 60 * 1000,
      path: '/',
    });
    setCookie({}, 'agent_client_id', suggestion?.agentClientUserId, {
      maxAge: 24 * 60 * 60 * 1000,
      path: '/',
    });
    setFocus(false);
    window.location.reload();
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      setFocus(false);
    }
  };

  const handleInputChange = (e, { newValue }) => {
    setSearch(newValue);
  };

  const handleSuggestionsClearRequested = () => {
    setUserSuggestions([]);
  };

  const handleClick = () => {
    setToggle(false);
    fetchSearchData(null);
    setSearch('');
    setFocus(true);
    searchRef.current.focus();
  };

  return (
    <StyledFlex direction="column">
      <SearchInputFlex>
        <FieldLabel>{agent_client_id ? 'You are viewing as' : 'Select User'}</FieldLabel>
        <SearchInputWrapper>
          <AutoSuggest
            suggestions={focus ? userSuggestions : []}
            alwaysRenderSuggestions={focus}
            onSuggestionsFetchRequested={({ value }) => {
              if (value) {
                setSearch(value);
                debounceSearch(value);
              } else setUserSuggestions(defaultSuggestion);
            }}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSearchSuggestion}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={(suggestion) => <div>{suggestion?.name}</div>}
            inputProps={{
              placeholder: 'Type Here',
              value: search,
              ref: searchRef,
              onChange: handleInputChange,
              onKeyDown: handleSearchKeyDown,
              readOnly: toggle,
              onFocus: () => {
                setFocus(true);
              },
            }}
          />
          {agent_client_id && (
            <Clicker onClick={handleClick}>
              <PencilIcon />
            </Clicker>
          )}
        </SearchInputWrapper>
      </SearchInputFlex>
    </StyledFlex>
  );
};

export default AgentUsers;

const Clicker = styled.div`
  cursor: pointer;
`;

const StyledFlex = styled(Flex)`
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #1688fe;
`;

const FieldLabel = styled.p`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  color: var(--light-values-white);
  line-height: 1.54%;
  margin: 0;
  font-size: 20px;
  ${({ $padded }) =>
    $padded &&
    css`
      padding: 12px;
    `}
`;

const SearchInputFlex = styled(Flex)`
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 22px;
  width: 100%;
  background-color: #1688fe;
`;

const SearchInputWrapper = styled(Flex)`
  height: fit-content;
  padding: 7px 12px;
  border-radius: 4px;
  background-color: var(--light-values-white);
  background-color: #1688fe;
  &:focus-within {
    background-color: #1688fe;
  }
  .react-autosuggest__input {
    font-family: ${({ theme }) => theme.font.family.websiteMedium};
    font-size: 15px;
    color: var(---light-values-white);
    line-height: 1.47%;
    background-color: #1688fe;
    border: none;
    &::placeholder {
      color: var(--grayscale-n300);
    }
    color: #fff;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: block;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 12.22%;
    margin: 0 auto;
    top: 45px;
    left: 60px;
    right: 0px;
    border: 1px solid #1688fe;
    color: #1688fe;
    border-top-color: var(--grayscale-n300);
    background-color: #fff;
    box-shadow: 0px 1px 4px 0px black;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
    z-index: 999;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 6px 12px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #fff;
    border: none;
  }
`;
