import styled from 'styled-components';

const StyledH2 = styled.h2`
  margin-bottom: 10px;
  margin-top: 0px;
  font-family: 'Gilroy Medium';
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  @media (max-width: 479px) {
    font-size: 24px;
    line-height: 32px;
  }
`;
export default StyledH2;
