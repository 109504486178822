import React from 'react';

const GuideIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44 2H18C16.8954 2 16 2.89543 16 4V30C16 31.1046 16.8954 32 18 32H44C45.1046 32 46 31.1046 46 30V4C46 2.89543 45.1046 2 44 2Z"
        fill="#37B982"
      />
      <path
        d="M30 16H4C2.89543 16 2 16.8954 2 18V44C2 45.1046 2.89543 46 4 46H30C31.1046 46 32 45.1046 32 44V18C32 16.8954 31.1046 16 30 16Z"
        fill="#0866F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 32V18C32 16.8954 31.1046 16 30 16H16V30C16 31.1046 16.8954 32 18 32H32Z"
        fill="white"
      />
    </svg>
  );
};
export default GuideIcon;
