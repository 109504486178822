import DragDrop from './DragDrop';
import { Fragment } from 'react';
import PropTypes from 'prop-types';

import sortBy from 'lodash/sortBy';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

import Block from '/imports/generator/ui/components/Block';
import BlockItem from '/imports/generator/ui/components/BlockItem';
import RelatedSkills from '/imports/generator/ui/components/RelatedSkills';
import { useRouter } from 'next/router';
import { useResponsive } from 'imports/core/api/responsiveContext';

const GenericBlock = (props) => {
  const {
    block = {},
    source,
    resumeId,
    coverLetterId,
    isCoverLetter,
    isFormValid,
    noTitle,
    getForm,
    isOnboarding,
    startExpanded,
    updateImmue,
    referenceResume,
    refernceBlock,
    language,
  } = props;
  const { trigger } = useFormContext();

  const { isMobile } = useResponsive();
  const afterRemove = () => {
    trigger();
  };
  const {
    query: { step },
  } = useRouter();

  const activeVarAutoFetch = marvelEmitter.getActiveVariant('exp_auto_fetch_related_skills_new');

  const showSkillsAutoFetch = () => {
    if (step === 'finish' && activeVarAutoFetch !== 'with_cloud_design') return false;
    const variants = ['with_big_screen_popup', 'with_cloud_design'];
    return variants.includes(activeVarAutoFetch) && block.type === 'SKILLS' && source.settings.language === 'en';
  };

  const isDragAndDrop = marvelEmitter.getActiveVariant('exp_skills_DD_V2') !== 'with_up_and_down';
  const isNewDragAndDrop = marvelEmitter.getActiveVariant('exp_other_section_DD_V2') !== 'with_up_and_down';

  const BLOCK_WITH_DRAG = ['SOCIAL_LINKS', 'LANGUAGES', 'REFERENCES'];

  return (
    <Block
      language={language}
      block={block}
      blockId={block.id}
      id={block.type}
      resumeId={resumeId}
      coverLetterId={coverLetterId}
      isCoverLetter={isCoverLetter}
      source={source}
      noTitle={noTitle}
      isFormValid={isFormValid}
      isOnboarding={isOnboarding}
      updateImmue={updateImmue}
      referenceResume={referenceResume}
    >
      {showSkillsAutoFetch() && (
        <RelatedSkills resume={source} updateImmue={updateImmue} variant={activeVarAutoFetch} />
      )}
      {block.items && (
        <Fragment>
          {isDragAndDrop && isMobile && block.type === 'SKILLS' ? (
            <DragDrop {...props} afterRemove={afterRemove} />
          ) : !isMobile && (block.type === 'SKILLS' || BLOCK_WITH_DRAG.includes(block.type)) ? (
            <DragDrop {...props} afterRemove={afterRemove} />
          ) : isMobile && isNewDragAndDrop ? (
            <DragDrop {...props} afterRemove={afterRemove} />
          ) : (
            <Fragment>
              {sortBy(block.items, 'order').map((item, key) => {
                return (
                  <BlockItem
                    language={language}
                    key={item.animationKey}
                    type={block.type}
                    block={block}
                    items={block.items}
                    docType={block.type}
                    source={source}
                    afterRemove={afterRemove}
                    isCoverLetter={isCoverLetter}
                    item={item}
                    refernceBlockItem={refernceBlock && refernceBlock.items !== null ? refernceBlock.items[key] : null}
                    referenceResume={referenceResume}
                    getForm={getForm}
                    isFormValid={isFormValid}
                    startExpanded={startExpanded}
                    updateImmue={updateImmue}
                  />
                );
              })}
            </Fragment>
          )}
        </Fragment>
      )}
    </Block>
  );
};

const List = styled.div`
  width: 100%;
  position: relative;
`;
GenericBlock.propTypes = {
  block: PropTypes.object,
  source: PropTypes.object,
  resumeId: PropTypes.string,
  coverLetterId: PropTypes.string,
  isCoverLetter: PropTypes.bool,
  noTitle: PropTypes.bool,
  getForm: PropTypes.func,
  isFormValid: PropTypes.func,
  isOnboarding: PropTypes.bool,
  startExpanded: PropTypes.bool,
};

export default GenericBlock;
