import React from 'react';

const WritingIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45 35H15C13.8954 35 13 34.1046 13 33V3C13 1.89543 13.8954 1 15 1H45C46.1046 1 47 1.89543 47 3V33C47 34.1046 46.1046 35 45 35Z"
        fill="#C8E0FF"
      />
      <path
        d="M39 41H9C7.89543 41 7 40.1046 7 39V9C7 7.89543 7.89543 7 9 7H39C40.1046 7 41 7.89543 41 9V39C41 40.1046 40.1046 41 39 41Z"
        fill="#378EFF"
      />
      <path
        d="M33 47H3C1.89543 47 1 46.1046 1 45V15C1 13.8954 1.89543 13 3 13H33C34.1046 13 35 13.8954 35 15V45C35 46.1046 34.1046 47 33 47Z"
        fill="#0866F5"
      />
      <path
        d="M26 23H10C9.448 23 9 22.552 9 22C9 21.448 9.448 21 10 21H26C26.552 21 27 21.448 27 22C27 22.552 26.552 23 26 23Z"
        fill="white"
      />
      <path
        d="M26 31H10C9.448 31 9 30.552 9 30C9 29.448 9.448 29 10 29H26C26.552 29 27 29.448 27 30C27 30.552 26.552 31 26 31Z"
        fill="white"
      />
      <path
        d="M18 39H10C9.448 39 9 38.552 9 38C9 37.448 9.448 37 10 37H18C18.552 37 19 37.448 19 38C19 38.552 18.552 39 18 39Z"
        fill="white"
      />
    </svg>
  );
};
export default WritingIcon;
