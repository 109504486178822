import PlacekitInputAutoSuggestion from './PlacekitInputAutoSuggestion';
import Autosave from '/imports/core/ui/components/Autosave';
import withUseFormContext from 'imports/core/hooks/withUseFormContext';
import React from 'react';

const PlacekitInputAutoSave = (props) => {
  return (
    <Autosave {...props}>
      {({ onChange }) => (
        <PlacekitInputAutoSuggestion onChange={onChange} defaultvalue={props?.value || ''} {...props} />
      )}
    </Autosave>
  );
};

export default withUseFormContext(PlacekitInputAutoSave);
