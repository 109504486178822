import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import useIntl from 'imports/core/api/useIntl';
import { replaceString } from 'imports/core/ui/helpers';

import BasicContainer from '/imports/homepageV4/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV4/ui/atoms/BasicSectionWrapper';
import { EXPLICITY_SET_ZAR_CURRENCY, getCurrency, getLocation } from '/lib/helpers';
import PlanetIcon from '/imports/homepageV4/ui/atoms/svg/PlanetIcon';
import StackIcon from '/imports/homepageV4/ui/atoms/svg/StackIcon';
import StarterIcon from '/imports/homepageV4/ui/atoms/svg/StarterIcon';
import StyledH2 from '/imports/homepageV4/ui/atoms/StyledH2';
import StyledH4 from '/imports/homepageV4/ui/atoms/StyledH4';
import StyledParagraph from '/imports/homepageV4/ui/atoms/StyledParagraph';
import { useResponsive } from '/imports/core/api/responsiveContext';
import publicIp from 'public-ip';

const Section6 = () => {
  const { t } = useIntl();
  const { host } = useResponsive();
  let currency = getCurrency(host);

  const timeZone = moment.tz.guess(true);
  const [cont] = timeZone.split('/');
  const [slug, setSlug] = useState([]);

  const fetchData = async (IP) => {
    const countryCode = await getLocation(IP || '127.0.0.1');
    const respCurrency = getCurrency(host, countryCode);
    currency = respCurrency;
  };

  useEffect(() => {
    if (EXPLICITY_SET_ZAR_CURRENCY.includes(host)) {
      const IP = publicIp.v4();
      fetchData(IP);
    }
    if (cont === 'Europe' && currency === 'EUR') {
      const startPlan = replaceString(t('resumizeme_landing_sec7_adv1_paragraph').replace(/\$/g, ''), {
        1.45: '1,45€',
        29.99: '€29,99',
        '29,99': '€29,99',
      });
      const monthlyPlan = replaceString(t('resumizeme_landing_sec7_adv2_paragraph').replace(/\$/g, ''), {
        1.95: '1,95€',
        29.99: '€29,99',
        '29,99': '€29,99',
      });
      setSlug([startPlan, monthlyPlan]);
    } else
      setSlug([
        t('resumizeme_landing_sec7_adv1_paragraph'),
        t('resumizeme_landing_sec7_adv2_paragraph'),
        t('resumizeme_landing_sec7_adv3_paragraph'),
      ]);
  }, []);

  return (
    <SectionWrapper>
      <BasicContainer>
        <AdvanceH2>{t('resumizeme_landing_sec7_title_part1')}</AdvanceH2>
        <AdvanceBlocksWrapper>
          <AdvanceBlock>
            <AdvanceBlockHeader>
              <StarterIcon />
              <StyledH4>{t('resumizeme_landing_sec7_adv1_title')}</StyledH4>
            </AdvanceBlockHeader>
            <AdvanceBlockParagraph>{slug[0]}</AdvanceBlockParagraph>
          </AdvanceBlock>
          <AdvanceBlock>
            <AdvanceBlockHeader>
              <StackIcon />
              <StyledH4>{t('resumizeme_landing_sec7_adv2_title')}</StyledH4>
            </AdvanceBlockHeader>
            <AdvanceBlockParagraph>{slug[1]}</AdvanceBlockParagraph>
          </AdvanceBlock>
        </AdvanceBlocksWrapper>
      </BasicContainer>
    </SectionWrapper>
  );
};
const SectionWrapper = styled(BasicSectionWrapper)`
  padding-top: 0;
  padding-bottom: 0;
`;
const AdvanceH2 = styled(StyledH2)`
  max-width: 640px;
  margin-right: auto;
  margin-bottom: 32px;
  margin-left: auto;
  text-align: center;
`;
const AdvanceBlocksWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  @media (max-width: 479px) {
    flex-direction: column;
    > div:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;
const AdvanceBlock = styled.div`
  max-width: 31.5%;
  @media (max-width: 479px) {
    max-width: none;
  }
`;
const AdvanceBlockHeader = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
  grid-column-gap: 16px;
  @media (max-width: 1003px) {
    min-height: 65px;
  }
  @media (max-width: 666px) {
    min-height: 100px;
  }
  @media (max-width: 479px) {
    min-height: 1px;
  }
`;
const AdvanceBlockParagraph = styled(StyledParagraph)`
  margin-bottom: 0px;
  color: #3a3a43;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
`;
export default Section6;
