import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';
import styled, { css } from 'styled-components';

import Checkbox from '/imports/core/ui/atoms/Checkbox';
import { Chevron } from '/imports/generator/ui/assets';
import Flex from '/imports/core/ui/atoms/Flex';
import { getRoute, isBlogDomain, websiteFontLoad } from '/lib/helpers';
import Modal from '/imports/core/ui/atoms/Modal';
import useIntl from '/imports/core/api/useIntl';

const modalStyles = {
  modalContainer: {
    display: 'flex',
    backgroundColor: 'transparent',
  },
  modalBackdrop: {
    backgroundColor: '#8c98aa99',
  },
  modalBody: {
    maxWidth: '660px',
    background: '#fff',
    boxShadow: '0 16px 48px 0 rgba(29, 44, 67, 0.2)',
    margin: '5% auto auto',
    width: '100%',
    height: 'auto',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '16px',
    flexGrow: '0',
  },
};

const CHECKBOX_CONTENT = [
  {
    label: 'analytics',
    text: 'cookie_modal_d1',
    detail: 'cookie_modal_d1_text',
    disable: false,
  },
  {
    label: 'performance',
    text: 'cookie_modal_d2',
    detail: 'cookie_modal_d2_text',
    disable: false,
  },
  {
    label: 'advertise',
    text: 'cookie_modal_d3',
    detail: 'cookie_modal_d3_text',
    disable: false,
  },
  {
    label: 'necessary',
    text: 'cookie_modal_d4',
    detail: 'cookie_modal_d4_text',
    disable: true,
  },
];

const CookieConsentComponent = ({ host }) => {
  const { t, locale } = useIntl();
  const [open, setOpen] = useState(false);
  const buttonEl = useRef(null);
  const [check, setCheck] = useState({
    analytics: false,
    performance: false,
    advertise: false,
    necessary: true,
  });
  const [show, setShow] = useState({
    analytics: false,
    performance: false,
    advertise: false,
    necessary: false,
  });

  const checkboxHandle = (e) => {
    e.stopPropagation();
    setCheck({ ...check, [e.target.name]: !check[e.target.name] });
  };

  const handleShow = (name) => () => {
    setShow({ ...show, [name]: !show[name] });
  };

  const ButtonComponent = (prop) => {
    return (
      <ButtonWrapper alignItems="center">
        <ButtonText onClick={() => setOpen(true)}>{t('cookie_settings')}</ButtonText>
        <Button ref={buttonEl} onClick={prop.onClick}>
          {t('cookie_accept')}
        </Button>
      </ButtonWrapper>
    );
  };

  const handleSave = () => {
    buttonEl.current.click();
    setOpen(false);
  };

  return (
    <>
      <CookieConsent
        location="bottom"
        contentStyle={{ width: 'auto', flex: 'unset', margin: '30px 15px' }}
        style={{
          justifyContent: 'center',
          fontFamily: websiteFontLoad(locale).websiteMedium,
          flexWrap: 'no-wrap',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
        ButtonComponent={ButtonComponent}
      >
        <Wrapper direction="column">
          <Title>{t('cookie_title')}</Title>
          <Text>
            {t('cookie_description')}{' '}
            <a
              target="_blank"
              href={getRoute('/privacy-policy', locale, false, host, isBlogDomain(host))}
              style={{ color: '#f37112' }}
              rel="noreferrer"
            >
              {t('cookie_privacy_policy')}
            </a>
            .
          </Text>
        </Wrapper>
      </CookieConsent>
      {typeof window !== 'undefined' && (
        <Modal
          onClose={() => setOpen(false)}
          styles={modalStyles}
          openStateBase
          open={open}
          animation="empty"
          timeout={0}
        >
          <ContentWrapper direction="column">
            <Modaltitle>{t('cookie_settings')}</Modaltitle>
            <ModalDescription>{t('cookie_modal_description')}</ModalDescription>
            <Modaltitle small>{t('cookie_modal_t1')} </Modaltitle>
            <CheckBoxWrapper fullWidth direction="column">
              <Flex direction="column">
                {CHECKBOX_CONTENT.map((el, i) => (
                  <CheckBoxFieldWrapper direction="column" fullWidth onClick={handleShow(el.label)} key={i}>
                    <CheckBoxInner fullWidth alignItems="center">
                      <Checkbox
                        onClick={el.disable ? (e) => e.stopPropagation : checkboxHandle}
                        name={el.label}
                        checked={check[el.label]}
                        color="#f37112"
                        customCss={checkBoxStyle}
                      />
                      <ModalDescription bold black={!el.disable ? true : false}>
                        {t(el.text)}
                      </ModalDescription>
                      {show[el.label] ? <Chevron.Up /> : <Chevron.Down />}
                    </CheckBoxInner>
                    <CheckBoxDetail show={show[el.label]}>{t(el.detail)}</CheckBoxDetail>
                  </CheckBoxFieldWrapper>
                ))}
              </Flex>
            </CheckBoxWrapper>
            <ModalButtonWrapper justifyContent="flex-end" fullWidth>
              <Button onClick={handleSave}>{t('cookie_modal_button')}</Button>
            </ModalButtonWrapper>
          </ContentWrapper>
        </Modal>
      )}
    </>
  );
};

CookieConsentComponent.propTypes = {
  host: PropTypes.string,
};

export default CookieConsentComponent;

const checkBoxStyle = css`
  ${({ theme }) => theme.max('md')`
    width: 18px;
    height: 18px;
  `}
`;

const ModalButtonWrapper = styled(Flex)`
  margin-top: auto;
`;

const CheckBoxWrapper = styled(Flex)`
  margin: 8px 0;
  max-height: 200px;
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(229, 228, 234);
    border-radius: 4px;
  }
  padding-right: 10px;
`;

const CheckBoxInner = styled(Flex)`
  cursor: pointer;
  padding: 8px 0;
  > div {
    margin-right: 10px;
  }
  svg {
    margin-left: auto;
  }
`;

const CheckBoxFieldWrapper = styled(Flex)`
  overflow: hidden;
  transition: max-height 0.2s ease-out;
`;

const ButtonWrapper = styled(Flex)`
  margin: 30px 15px;
  ${({ theme }) => theme.max('md')`
    margin: 0 0 30px;
    width: 100%;
  `}
`;

const Wrapper = styled(Flex)`
  width: 50vw;
  ${({ theme }) => theme.max('md')`
    width: 100%;
  `}
`;

const Text = styled(Flex)`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  line-height: 1.47;
  display: inline;
`;

const ButtonText = styled(Text)`
  cursor: pointer;
  color: #f37112;
  margin-right: 10px;
`;

const Title = styled(Flex)`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 22px;
  line-height: 1.36;
  margin-bottom: 10px;
`;

const Modaltitle = styled(Title)`
  color: var(--text);
  margin-bottom: 16px;
  ${({ small }) =>
    small &&
    css`
      font-size: 17px;
      line-height: 1.41;
      margin-top: 16px;
    `}
`;

const ModalDescription = styled(Flex)`
  font-family: ${({ bold, theme }) => (bold ? theme.font.family.websiteSemiBold : theme.font.family.websiteMedium)};
  font-size: 13px;
  line-height: 1.54;
  color: ${({ black }) => (black ? 'var(--text)' : 'var(--neutral-values-gray-dark)')};
`;

const CheckBoxDetail = styled.div`
  max-height: ${({ show }) => (show ? '1000px' : '0px')};
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 13px;
  line-height: 1.54;
  color: var(--neutral-values-gray-dark);
  padding: 0 28px;
  -webkit-transition:
    max-height 0.3s ease-in-out,
    box-shadow 0.6s linear;
  -moz-transition:
    max-height 0.3s ease-in-out,
    box-shadow 0.6s linear;
  -o-transition:
    max-height 0.3s ease-in-out,
    box-shadow 0.6s linear;
  -ms-transition:
    max-height 0.3s ease-in-out,
    box-shadow 0.6s linear;
  transition:
    max-height 0.3s ease-in-out,
    box-shadow 0.6s linear;
`;

const ContentWrapper = styled(Flex)`
  background-color: #fff;
  padding: 32px;
  height: 520px;
  ${({ theme }) => theme.max('xs')`
      height: 600px;
  `}
`;

const Button = styled.button`
  background-color: #f37112;
  color: #fff;
  font-size: 13px;
  border: none;
  border-radius: 5px;
  padding: 10px 10px 9px;
  &:hover {
    background-color: #fb8333;
  }
  cursor: pointer;
  &:first-child {
    margin-right: 10px;
  }
`;
